export const getEnumValues = (_enum = {}) => Object.values(_enum);

export const solicitantesEnum = Object.freeze({
  CIUDADANO: 1,
  CONTRIBUYENTE: 15,
});

export const padronesEnum = Object.freeze({
  CIUDADANO: 1,
  EMPRESA: 2,
  PREDIO: 3,
  VEHICULO: 4,
  HOSPEDAJE: 6,
  ARRENDAMIENTO: 7,
  NOMINA: 8,
  ALCOHOL: 9,
  CEDULAR: 10,
  JUEGO_DE_AZAR: 11,
  NOTARIO: 12,
  CASA_DE_EMPENIO: 13,
  AGENCIA: 14,
  CONTRIBUYENTE: 15,
  MOTOCICLETA: 16,
  REMOLQUE: 17,
  POLICIA_ESPECIAL: 18,
  INFRACCIONES: 19,
  COMERCIO_INFORMAL: 20,
  MERCADO: 21,
  EXPEDIENTE_LICENCIA_FUNCIONAMIENTO: 22,
  ZONA_EXCLUSIVA: 23,
  EXPEDIENTE_DE_ANUNCIO: 24,
  MULTAS: 25,
  VENTA_DE_ALCOHOL: 26, // BEBIDAS ALCOHOLICAS
  RETENEDOR_DE_CEDULAR: 27,
  RETENEDOR_DE_NOMINA: 28,
  SERVICIO_PUBLICO: 29,
  ESTACIONAMIENTO: 30,
});

export const stepsEnum = Object.freeze({
  PADRON_SOLICITANTE: 'PADRON_SOLICITANTE',
  PADRON_AFECTADO: 'PADRON_AFECTADO',
  REQUISITOS_GENERALES: 'REQUISITOS_GENERALES',
  CASOS_DE_REQUISITOS: 'CASOS_DE_REQUISITOS',
  REQUISITOS_DEL_CASO: 'REQUISITOS_DEL_CASO',
  VARIABLES: 'VARIABLES',
  GENERAR_CARGOS: 'GENERAR_CARGOS',
});

export const saveLabelEnum = Object.freeze({
  CONTINUE: 'Continuar',
  SAVE: 'Guardar',
  GENERATE_CHARGES: 'Generar',
  START_TRAMITE: 'Iniciar trámite',
  SIGN: 'Firmar',
  NOT_SUPPORTED: null,
});

export const afectacionesEnum = Object.freeze({
  ALTA: 1,
  MODIFICACION: 2,
  BAJA: 3,
  CONSULTA: 4,
});

export const aplicacionesCargosEnum = Object.freeze({
  CAPTURABLE: 1,
  FIJO: 2,
  TARIFA: 3,
  FORMULA: 4,
  MULTIPLO: 5,
  PORCENTAJE: 6,
  SQL_FUNCTION: 7,
});

export const tiposDatosFormulaEnum = Object.freeze({
  ENTERO: 1,
  DECIMAL: 2,
  RANGO_DE_FECHAS: 3,
});

export const tiposDatosEnum = Object.freeze({
  ENTERO: 4,
  DECIMAL: 5,
  RANGO_DE_FECHAS: 6,
  FECHA: 7,
  FECHA_HORA: 8,
  BOOLEAN: 9,
  TEXTO_CORTO: 10,
  TEXTO_LARGO: 11,
  SELECCION_UNICA: 12,
  SELECCION_MULTIPLE: 13,
});

export const statusRequisitosEnum = Object.freeze({
  NEED_UPDATE: 'NEED_UPDATE',
  INCOMPLETE: 'INCOMPLETE',
  INCOMPLETE_SAVE: 'INCOMPLETE_SAVE',
  COMPLETE: 'COMPLETE',
  CONTINUE: 'CONTINUE',
});

export const tiposDeRequisitosEnum = Object.freeze({
  OBLIGATORIO: 1,
  OPCIONAL: 2,
  EXCLUSIVO: 3,
  INCLUSIVO: 4,
  DINAMICO: 5,
});

export const tiposDePasosEnum = Object.freeze({
  VALIDACION: 1,
  EMISION_DE_DOCUMENTO: 2,
  FIRMA_ELECTRONICA: 3,
  GIS: 4,
  VARIABLES: 5,
  MODIFICACION_SOLICITANTE: 6,
  MODIFICACION_AFECTADO: 7,
  VALIDACION_REQUISITOS: 8,
  CAMBIO_DE_PROPIETARIO: 9,
  REQUISITOS: 10,
  CARGOS: 11,
  ALTA_AFECTADO: 12,
  DIRECCION_PRINCIPAL: 13,
  DIRECCION_SECUNDARIA: 14,
  OBLIGACIONES: 15,
  EMPRESAS: 16,
  ACTIVIDADES_ECONOMICAS: 20,
  ACTIVIDADES_ECONOMICAS_SAT: 21,
  CARGA_DE_DOCUMENTO: 23,
  VISTA_PADRON: 24,
  CITA: 25,
});

export const dataTypesEnum = Object.freeze({
  CHAR: 'CharField',
  TEXT: 'TextField',
  DECIMAL: 'DecimalField',
  FLOAT: 'FloatField',
  DATE: 'DateField',
  DATE_TIME: 'DateTimeField',
  INTEGER: 'IntegerField',
  POSITIVE_INTEGER: 'PositiveIntegerField',
  POSITIVE_BIG_INTEGER: 'PositiveBigIntegerField',
  POSITIVE_SMALL_INTEGER: 'PositiveSmallIntegerField',
  SMALL_INTEGER: 'SmallIntegerField',
  BIG_INTEGER: 'BigIntegerField',
  BOOLEAN: 'BooleanField',
  SLUG: 'SlugField',
  FOREIGN_KEY: 'related.ForeignKey',
  MANY_TO_MANY: 'related.ManyToManyField',
  URL: 'URLField',
  EMAIL: 'EmailField',
  IMAGE: 'files.ImageField',
});

export const procedenciasEnum = Object.freeze({
  GPM: 7,
});

export const tipoDeDocumentoExpedienteEnum = Object.freeze({
  FOTOGRAFIA: 1,
  GIS: 2,
  DOCUMENTO: 3,
  RECIBO: 4,
});

export const direccionPrincipalEnum = Object.freeze({
  [padronesEnum.EMPRESA]: 'direccion_de_ubicacion',
  [padronesEnum.CONTRIBUYENTE]: 'direcciones',
  [padronesEnum.INFRACCIONES]: 'direccion_del_infractor',
  [padronesEnum.COMERCIO_INFORMAL]: 'expedientedecomercioinformal',
  [padronesEnum.EXPEDIENTE_LICENCIA_FUNCIONAMIENTO]: 'expedientedelicenciafuncionamiento',
  [padronesEnum.MULTAS]: 'direccion_multa',
});

export const direccionSecundariaEnum = Object.freeze({
  [padronesEnum.PREDIO]: 'direccion_notificacion',
  [padronesEnum.CONTRIBUYENTE]: 'direcciones_de_notificacion',
  [padronesEnum.INFRACCIONES]: 'direccion_de_infraccion',
  [padronesEnum.MULTAS]: 'direccion_notificacion_multa',
});
