/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable global-require */
import React, { useState } from 'react';
import {
  Row,
  Divider,
  Modal,
  Col,
} from 'antd';
import { LabelValue, Button, Text } from 'components';
import { listToString } from 'utils/formatters';
import { ENV_NAME, ENV_NAMES_ENUM } from 'utils/env';
import address from 'utils/address';
import { propietarioToString } from 'utils/padronesToString';
import { useSelector } from 'react-redux';
import { selectLadas } from 'store/catalogos';
import Select from 'components/Select';

const getNombre = (padron) => padron?.nombre_completo || padron?.razon_social || '';
const _ENV_NAME = process.env.REACT_APP_ENV_NAME;

const PadronInfo = ({
  padron,
  padrones,
  tipoDePadron,
  periodosFiscales,
  periodoFiscal,
  callback,
  allowChangePeriodo = false,
}) => {
  const [showInstructivo, setShowInstructivo] = useState(false);

  const ladas = useSelector(selectLadas);

  // useEffect(() => {
  //   if (padron) {
  //     console.log('padron', padron);
  //     console.log('tipoDePadron', tipoDePadron);
  //   }
  // }, [padron]);

  if (!padron) return null;
  const nameTemp = localStorage.getItem('nameTemp');

  const showNamePadron = (_padron) => {
    if (_padron.empresa?.contribuyente?.rfc?.length === 13) {
      return getNombre(_padron?.empresa?.contribuyente)
        || getNombre(_padron?.contribuyente)
        || _padron?.empresa?.nombre_comercial
        || getNombre(_padron?.empresa?.contribuyente);
    }
    return _padron?.empresa?.contribuyente?.razon_social || _padron?.empresa?.nombre_comercial;
  };

  const descriptorLabelMap = {
    1: 'Nombre completo',
    2: 'Nombre comercial',
    3: 'Clave catastral',
    4: 'Número de placa vigente',
    6: padron?.empresa?.contribuyente?.rfc?.length === 13 ? 'Nombre completo' : 'Razón Social',
    7: padron?.empresa?.contribuyente?.rfc?.length === 13 ? 'Nombre completo' : 'Razón Social',
    8: padron?.empresa?.contribuyente?.rfc?.length === 13 ? 'Nombre completo' : 'Razón Social',
    10: padron?.empresa?.contribuyente?.rfc?.length === 13 ? 'Nombre completo' : 'Razón Social',
    11: padron?.empresa?.contribuyente?.rfc?.length === 13 ? 'Nombre completo' : 'Razón Social',
    12: padron?.empresa?.contribuyente?.rfc?.length === 13 ? 'Nombre completo' : 'Razón Social',
    14: padron?.empresa?.contribuyente?.rfc?.length === 13 ? 'Nombre completo' : 'Razón Social',
    15: 'Razón social',
    16: 'Número de placa vigente',
    17: 'Número de placa vigente',
    22: 'Propietario',
    26: padron?.empresa?.contribuyente?.rfc?.length === 13 ? 'Nombre completo' : 'Razón Social',
    27: padron?.empresa?.contribuyente?.rfc?.length === 13 ? 'Nombre completo' : 'Razón Social',
    28: padron?.empresa?.contribuyente?.rfc?.length === 13 ? 'Nombre completo' : 'Razón Social',
  };

  const descriptorValueMap = {
    1: padron.nombre_completo,
    2: padron.nombre_comercial,
    3: padron.clave_catastral_municipal,
    4: padron.numero_de_placa_vigente,
    6: showNamePadron(padron),
    7: showNamePadron(padron),
    8: showNamePadron(padron),
    10: showNamePadron(padron),
    11: showNamePadron(padron),
    12: showNamePadron(padron),
    14: showNamePadron(padron),
    15: getNombre(padron.razon_social),
    16: padron.numero_de_placa_vigente,
    17: padron.placa,
    22: propietarioToString(padron?.empresa || padron),
    26: showNamePadron(padron),
    27: showNamePadron(padron),
    28: showNamePadron(padron),
  };
  const keyLabelMap = {
    1: 'Clave ciudadana',
    8: 'Clave estatal',
    3: 'Propietario',
    22: 'Ubicación del negocio',
    27: 'Clave estatal',
  };

  const keyValueMap = {
    1: padron.clave_ciudadana,
    3: listToString(padron.nombres_de_participes),
    22: address(padron.empresa?.direccion_de_ubicacion) || '',
  };

  const key = (
    <LabelValue
      label={keyLabelMap[tipoDePadron] || 'Clave'}
      value={keyValueMap[tipoDePadron] ?? padron.clave ?? padron.clave_de_control}
      hidden={tipoDePadron === 3 && ENV_NAME === ENV_NAMES_ENUM.JUAREZ}
    />
  );

  const key2LabelMap = {
    1: 'CURP',
    3: 'Dirección',
    7: 'RFC',
    8: 'RFC',
    4: 'Serie',
    6: 'RFC',
    10: 'RFC',
    11: 'RFC',
    12: 'RFC',
    14: 'RFC',
    15: 'RFC',
    16: 'Serie',
    17: 'Serie',
    26: 'RFC',
    27: 'RFC',
    28: 'RFC',
  };

  const key2ValueMap = {
    1: padron.CURP,
    3: padron.direccion,
    4: padron.numero_de_identificacion_vehicular, // Serie
    8: padron.empresa?.contribuyente?.rfc,
    7: padron.empresa?.contribuyente?.rfc,
    6: padron.empresa?.contribuyente?.rfc,
    10: padron.empresa?.contribuyente?.rfc,
    11: padron.empresa?.contribuyente?.rfc,
    12: padron.empresa?.contribuyente?.rfc,
    14: padron.empresa?.contribuyente?.rfc,
    15: padron.rfc,
    16: padron.serie,
    17: padron.serie,
    26: padron.empresa?.contribuyente?.rfc,
    27: padron.empresa?.contribuyente?.rfc,
    28: padron.empresa?.contribuyente?.rfc,
  };

  const key2 = (
    <LabelValue
      md={tipoDePadron === 3 ? 24 : 12}
      label={key2LabelMap[tipoDePadron] || 'Empresa'}
      value={key2ValueMap[tipoDePadron]
        || `${padron.empresa?.clave || ''} ${padron.empresa?.nombre_comercial || ''}`}
    />
  );

  const padronString = (
    <LabelValue label="Padrón" value={padrones.find((p) => p.id === tipoDePadron)?.descripcion} />
  );

  const descriptor = (
    <LabelValue
      label={descriptorLabelMap[tipoDePadron] || 'Descripción'}
      value={/publico en general/i.test(descriptorValueMap[tipoDePadron]) && nameTemp
        ? nameTemp
        : descriptorValueMap[tipoDePadron] || padron.descripcion}
    />
  );

  const email = (
    <LabelValue label="Correo electrónico" value={padron.email || padron.correo_electronico} />
  );

  const getLada = (lada) => ladas.find((l) => l.id === lada)?.lada || '';

  const phone = (
    <LabelValue
      label={padron.lada_celular ? 'Celular' : 'Teléfono'}
      value={padron.lada_fijo ? `${getLada(padron.lada_fijo)} ${padron.telefono_fijo}`
        : `${getLada(padron.lada_celular)} ${padron.numero_de_celular || padron.telefono_celular || ''}`}
    />
  );

  if ([1, 15].includes(tipoDePadron)) {
    return (
      <Row>
        {padronString}
        {descriptor}
        <Divider />
        {key2}
        {key}
        <Divider />
        {email}
        {phone}
        <Divider />
      </Row>
    );
  }

  if (tipoDePadron === 2) {
    return (
      <Row>
        {padronString}
        {descriptor}
        <Divider />
        <LabelValue
          label="Actividad empresarial específica"
          value={padron.actividad_empresarial_especifica}
        />
        {key}
        {ENV_NAME !== ENV_NAMES_ENUM.JUAREZ && (
          <>
            <Divider />
            {email}
            {phone}
            <Divider />
          </>
        )}
      </Row>
    );
  }

  if (tipoDePadron === 3) {
    return (
      <Row>
        {padronString}
        {descriptor}
        <Divider />
        {ENV_NAME !== ENV_NAMES_ENUM.JUAREZ && (
          <>
            {key2}
            <Divider />
          </>
        )}
        {key}
        <LabelValue
          label={padron.ciudadano?.RFC ? 'RFC' : 'CURP'}
          value={padron.ciudadano?.RFC || padron.ciudadano?.CURP}
          hidden={ENV_NAME === ENV_NAMES_ENUM.JUAREZ}
          hiddenConfig={{
            firstChar: false,
            lastChar: 5,
          }}
        />
        <Divider />
        {(padron.direccion || padron.direccion_notificacion) && (
          <LabelValue label="Dirección" value={padron.direccion || padron.direccion_notificacion} md={24} />
        )}
      </Row>
    );
  }

  if ([4, 16, 17].includes(tipoDePadron)) {
    const marca = (
      <LabelValue
        label="Marca"
        value={padron?.linea?.marca?.nombre || padron?.marca?.descripcion || padron?.marca}
      />
    );
    const getColoresVehiculo = () => {
      let output = '';
      if (padron?.color_del_vehiculo?.descripcion) {
        output = padron?.color_del_vehiculo?.descripcion
        || padron?.color_del_remolque?.descripcion || 'Sin color principal';
      } else {
        output = 'Sin color principal';
      }
      if (padron?.color_secundario) {
        output = `${output} / ${padron?.color_secundario}`;
      }
      return output;
    };

    const color = (
      <LabelValue
        tip="Color principal / Color secundario"
        label="Color"
        value={getColoresVehiculo()}
      />
    );

    const cilindros = (
      <LabelValue
        label="Cilindros"
        value={padron.cilindraje}
      />
    );

    const modelo = (
      <LabelValue
        label="Modelo"
        value={padron.modelo}
      />
    );

    return (
      <Row>
        {padronString}
        {descriptor}
        <Divider />
        {key2}
        {key}
        <Divider />
        {marca}
        {color}
        <Divider />
        {cilindros}
        {modelo}
        <Divider />
      </Row>
    );
  }

  if (tipoDePadron === 26) {
    // eslint-disable-next-line import/no-dynamic-require
    const doc = require(`../../../assets/${_ENV_NAME}/instructivos/26.pdf`);
    const instructivo = (
      <Row justify="end" style={{ width: '100%' }}>
        <Button onClick={() => setShowInstructivo(true)} $accent>
          <Text>Guía para generar declaración</Text>
        </Button>
        {
          showInstructivo && (
            <Modal
              title="GUÍA VENTA DE BEBIDAS CON CONTENIDO ALCOHÓLICO"
              visible={showInstructivo}
              onCancel={() => setShowInstructivo(false)}
              footer={null}
              width="80%"
            >
              <Col span={24}>
                <iframe
                  title="Instructivo"
                  src={doc.default}
                  width="100%"
                  height="800px"
                />
              </Col>
            </Modal>
          )
        }
      </Row>
    );

    return (
      <Row>
        {padronString}
        {descriptor}
        <Divider />
        {key2}
        {key}
        <Divider />
        {instructivo}
        {
        periodosFiscales && allowChangePeriodo && (
          <>
            <Text>Periodo fiscal</Text>
            <Select
              defaultValue={periodoFiscal || periodosFiscales[0]?.id}
              placeholder="Selecciona un periodo fiscal"
              style={{ width: '100%' }}
              label="Periodo fiscal"
              options={periodosFiscales}
              getLabel={(e) => e.periodo}
              onChange={(e) => callback(e)}
            />
          </>
        )
      }
      </Row>
    );
  }
  if (tipoDePadron === 11) {
    // eslint-disable-next-line import/no-dynamic-require
    const doc = require('../../../assets/nayarit/instructivos/juegos2024.pdf');
    const instructivo = (
      <Row justify="end" style={{ width: '100%' }}>
        <Button onClick={() => setShowInstructivo(true)} $accent>
          <Text>Guía para generar declaración</Text>
        </Button>
        {
          showInstructivo && (
            <Modal
              title="GUÍA"
              visible={showInstructivo}
              onCancel={() => setShowInstructivo(false)}
              footer={null}
              width="80%"
            >
              <Col span={24}>
                <iframe
                  title="Instructivo"
                  src={doc.default}
                  width="100%"
                  height="800px"
                />
              </Col>
            </Modal>
          )
        }
      </Row>
    );

    return (
      <Row>
        {padronString}
        {descriptor}
        <Divider />
        {key2}
        {key}
        <Divider />
        {instructivo}
        {
        periodosFiscales && allowChangePeriodo && (
          <>
            <Text>Periodo fiscal</Text>
            <Select
              defaultValue={periodoFiscal || periodosFiscales[0]?.id}
              placeholder="Selecciona un periodo fiscal"
              style={{ width: '100%' }}
              label="Periodo fiscal"
              options={periodosFiscales}
              getLabel={(e) => e.periodo}
              onChange={(e) => callback(e)}
            />
          </>
        )
      }
      </Row>
    );
  }
  if (tipoDePadron === 8) {
    // eslint-disable-next-line import/no-dynamic-require
    const doc = require(`../../../assets/${_ENV_NAME}/instructivos/nomina2024.pdf`);
    const instructivo = (
      <Row justify="end" style={{ width: '100%' }}>
        <Button onClick={() => setShowInstructivo(true)} $accent>
          <Text>Guía para generar declaración</Text>
        </Button>
        {
          showInstructivo && (
            <Modal
              title="GUÍA PARA LA GENERACIÓN DE NÓMINAS 2024"
              visible={showInstructivo}
              onCancel={() => setShowInstructivo(false)}
              footer={null}
              width="80%"
            >
              <Col span={24}>
                <iframe
                  title="Instructivo"
                  src={doc.default}
                  width="100%"
                  height="800px"
                />
              </Col>
            </Modal>
          )
        }
      </Row>
    );

    return (
      <Row>
        {padronString}
        {descriptor}
        <Divider />
        {key2}
        {key}
        <Divider />
        {instructivo}
        {
        periodosFiscales && allowChangePeriodo && (
          <>
            <Text>Periodo fiscal</Text>
            <Select
              defaultValue={periodoFiscal || periodosFiscales[0]?.id}
              placeholder="Selecciona un periodo fiscal"
              style={{ width: '100%' }}
              label="Periodo fiscal"
              options={periodosFiscales}
              getLabel={(e) => e.periodo}
              onChange={(e) => callback(e)}
            />
          </>
        )
      }
      </Row>
    );
  }
  if (tipoDePadron === 6) {
    // eslint-disable-next-line import/no-dynamic-require
    const doc = require(`../../../assets/${_ENV_NAME}/instructivos/hospedaje2024.pdf`);
    const instructivo = (
      <Row justify="end" style={{ width: '100%' }}>
        <Button onClick={() => setShowInstructivo(true)} $accent>
          <Text>Guía para generar declaración</Text>
        </Button>
        {
          showInstructivo && (
            <Modal
              title="GUÍA PARA LA GENERACIÓN DE HOSPAJE 2024"
              visible={showInstructivo}
              onCancel={() => setShowInstructivo(false)}
              footer={null}
              width="80%"
            >
              <Col span={24}>
                <iframe
                  title="Instructivo"
                  src={doc.default}
                  width="100%"
                  height="800px"
                />
              </Col>
            </Modal>
          )
        }
      </Row>
    );

    return (
      <Row>
        {padronString}
        {descriptor}
        <Divider />
        {key2}
        {key}
        <Divider />
        {instructivo}
        {
        periodosFiscales && allowChangePeriodo && (
          <>
            <Text>Periodo fiscal</Text>
            <Select
              defaultValue={periodoFiscal || periodosFiscales[0]?.id}
              placeholder="Selecciona un periodo fiscal"
              style={{ width: '100%' }}
              label="Periodo fiscal"
              options={periodosFiscales}
              getLabel={(e) => e.periodo}
              onChange={(e) => callback(e)}
            />
          </>
        )
      }
      </Row>
    );
  }
  if (tipoDePadron === 7) {
    // eslint-disable-next-line import/no-dynamic-require
    const doc = require(`../../../assets/${_ENV_NAME}/instructivos/ARRENDAM2024.pdf`);
    const instructivo = (
      <Row justify="end" style={{ width: '100%' }}>
        <Button onClick={() => setShowInstructivo(true)} $accent>
          <Text>Guía para generar declaración</Text>
        </Button>
        {
          showInstructivo && (
            <Modal
              title="GUÍA PARA LA GENERACIÓN DE NÓMINAS 2024"
              visible={showInstructivo}
              onCancel={() => setShowInstructivo(false)}
              footer={null}
              width="80%"
            >
              <Col span={24}>
                <iframe
                  title="Instructivo"
                  src={doc.default}
                  width="100%"
                  height="800px"
                />
              </Col>
            </Modal>
          )
        }
      </Row>
    );

    return (
      <Row>
        {padronString}
        {descriptor}
        <Divider />
        {key2}
        {key}
        <Divider />
        {instructivo}
        {
        periodosFiscales && allowChangePeriodo && (
          <>
            <Text>Periodo fiscal</Text>
            <Select
              defaultValue={periodoFiscal || periodosFiscales[0]?.id}
              placeholder="Selecciona un periodo fiscal"
              style={{ width: '100%' }}
              label="Periodo fiscal"
              options={periodosFiscales}
              getLabel={(e) => e.periodo}
              onChange={(e) => callback(e)}
            />
          </>
        )
      }
      </Row>
    );
  }
  if (tipoDePadron === 10) {
    // eslint-disable-next-line import/no-dynamic-require
    const doc = require(`../../../assets/${_ENV_NAME}/instructivos/PROF2024.pdf`);
    const instructivo = (
      <Row justify="end" style={{ width: '100%' }}>
        <Button onClick={() => setShowInstructivo(true)} $accent>
          <Text>Guía para generar declaración</Text>
        </Button>
        {
          showInstructivo && (
            <Modal
              title="GUÍA PARA LA GENERACIÓN DE NÓMINAS 2024"
              visible={showInstructivo}
              onCancel={() => setShowInstructivo(false)}
              footer={null}
              width="80%"
            >
              <Col span={24}>
                <iframe
                  title="Instructivo"
                  src={doc.default}
                  width="100%"
                  height="800px"
                />
              </Col>
            </Modal>
          )
        }
      </Row>
    );

    return (
      <Row>
        {padronString}
        {descriptor}
        <Divider />
        {key2}
        {key}
        <Divider />
        {instructivo}
        {
        periodosFiscales && allowChangePeriodo && (
          <>
            <Text>Periodo fiscal</Text>
            <Select
              defaultValue={periodoFiscal || periodosFiscales[0]?.id}
              placeholder="Selecciona un periodo fiscal"
              style={{ width: '100%' }}
              label="Periodo fiscal"
              options={periodosFiscales}
              getLabel={(e) => e.periodo}
              onChange={(e) => callback(e)}
            />
          </>
        )
      }
      </Row>
    );
  }
  if (tipoDePadron === 14) {
    // eslint-disable-next-line import/no-dynamic-require
    const doc = require(`../../../assets/${_ENV_NAME}/instructivos/ISAN2024.pdf`);
    const instructivo = (
      <Row justify="end" style={{ width: '100%' }}>
        <Button onClick={() => setShowInstructivo(true)} $accent>
          <Text>Guía para generar declaración</Text>
        </Button>
        {
          showInstructivo && (
            <Modal
              title="GUÍA PARA LA GENERACIÓN DE ISAN"
              visible={showInstructivo}
              onCancel={() => setShowInstructivo(false)}
              footer={null}
              width="80%"
            >
              <Col span={24}>
                <iframe
                  title="Instructivo"
                  src={doc.default}
                  width="100%"
                  height="800px"
                />
              </Col>
            </Modal>
          )
        }
      </Row>
    );

    return (
      <Row>
        {padronString}
        {descriptor}
        <Divider />
        {key2}
        {key}
        <Divider />
        {instructivo}
        {
        periodosFiscales && allowChangePeriodo && (
          <>
            <Text>Periodo fiscal</Text>
            <Select
              defaultValue={periodoFiscal || periodosFiscales[0]?.id}
              placeholder="Selecciona un periodo fiscal"
              style={{ width: '100%' }}
              label="Periodo fiscal"
              options={periodosFiscales}
              getLabel={(e) => e.periodo}
              onChange={(e) => callback(e)}
            />
          </>
        )
      }
      </Row>
    );
  }
  if (tipoDePadron === 22) {
    const giro = <LabelValue label="Giro" value={padron.giro?.descripcion || padron.giro} />;
    const categoriaGiro = <LabelValue label="Categoría Giro" value={padron.categoria_de_giro?.descripcion || padron.categoria_de_giro} />;
    const fechaRegistro = <LabelValue label="Fecha Registro" value={padron.fecha_de_registro_de_licencia} />;
    const fechaRenovacion = <LabelValue label="Fecha Renovación" value={padron.fecha_de_renovacion_de_licencia} />;

    return (
      <Row>
        {padronString}
        {descriptor}
        <Divider />
        {key2}
        {key}
        <Divider />
        {giro}
        {categoriaGiro}
        <Divider />
        {fechaRegistro}
        {fechaRenovacion}
        <Divider />
      </Row>
    );
  }

  return (
    <Row>
      {padronString}
      {descriptor}
      <Divider />
      {key2}
      {key}
      <Divider />
      {
        periodosFiscales && allowChangePeriodo && (
          <>
            <Text>Periodo fiscal</Text>
            <Select
              defaultValue={periodoFiscal || periodosFiscales[0]?.id}
              placeholder="Selecciona un periodo fiscal"
              style={{ width: '100%' }}
              label="Periodo fiscal"
              options={periodosFiscales}
              getLabel={(e) => e.periodo}
              onChange={(e) => callback(e)}
            />
          </>
        )
      }
    </Row>
  );
};

export default PadronInfo;
