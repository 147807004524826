/* eslint-disable react/prop-types */
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  Row,
  Col,
  Tooltip as AntTooltip,
  Spin,
  Form,
  message,
  Steps,
  Result,
  Grid,
  Modal,
  notification,
} from 'antd';
import { useSelector } from 'react-redux';
import {
  LeftOutlined,
  SaveOutlined,
  RightOutlined,
  CheckOutlined,
  SyncOutlined,
  ReloadOutlined,
  InfoCircleOutlined,
  StopOutlined,
  ArrowRightOutlined,
  FieldTimeOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { getPlantillaTramite } from 'api/tramites/plantillasDeTramites';
import padronToString, { getPadronKey } from 'utils/padronesToString';
import { listToString, toMoment } from 'utils/normalizers';
import gettersMap, {
  getContenTypePorTipoDePadron,
  getTipoDePadronPorContenType,
  patchPadronesPrivateMap,
  postPadronesMap,
} from 'api/padrones';
import { getTramites, patchTramite, postTramite } from 'api/tramites/tramites';
import { getComplementosDeTramites, patchComplementoDeTramite, postComplementoDeTramite } from 'api/tramites/complementosDeTramites';
import { getCargosPaginados, postGenerarCargos } from 'api/cargos';
import {
  getVariables,
  getValoresPorVariablesDeSeleccion,
  getCategoriasDeVariables,
} from 'api/configuracion';
import { DATE_FORMAT } from 'utils/formatValues';
import Previewer, { isPDFBase64 } from 'components/Previewer';
import {
  solicitantesEnum,
  stepsEnum,
  afectacionesEnum,
  aplicacionesCargosEnum,
  statusRequisitosEnum,
  saveLabelEnum,
  tiposDePasosEnum,
  tipoDeDocumentoExpedienteEnum,
  padronesEnum,
  direccionPrincipalEnum,
  direccionSecundariaEnum,
} from 'views/Tramites/GPM/enums';
import { selectPadrones } from 'store/catalogos';
import { postGenererReferenciasNetpay, getReciboPDFDeTramite } from 'api/recaudacion';
import { postObtenerDocumento } from 'api/reporteador';
import { useLocation, useNavigate, useParams } from 'react-router';
import { selectIsAuthenticated, selectUser } from 'store/auth';
import {
  RECEIPT_LABEL,
  PROD,
  DEV,
  CATALOGOS_DE_CANAL_DE_PAGO,
  ID_ENTIDAD,
  AUTH_CONTRIBUYENTE,
} from 'utils/env';
import {
  COLORS,
  Text,
  Title,
  Button,
} from 'components';
import PadronAfectado from 'views/Tramites/GPM/components/PadronAfectado';
import PadronSolicitante from 'views/Tramites/GPM/components/PadronSolicitante';
import RequisitosDelTramite from 'views/Tramites/GPM/components/RequisitosDelTramite';
import Variables, { valuesToVariablesComplementos, valuesToVariables } from 'views/Tramites/GPM/components/Variables';
import Cargos, { getMandatoryKeys, tiposDeAplicacionesAdmitidas } from 'views/Tramites/GPM/components/Cargos';
import round from 'utils/round';
import formatReference from 'utils/formatReference';
import { getNetPayBtn, paymentLocationsEnum, validateLastTransaction } from 'views/Payments/components/NetPayButton';
import Instrucciones from 'views/Tramites/GPM/components/Instrucciones';
import Documento from 'views/Tramites/GPM/components/Documento';
import ArcGisMap from 'views/Tramites/GPM/components/ArcGisMap';
import {
  generateLogValues,
  getArchivosDeComentariosDeTramitesPorIdsComentario,
  getHistorialDeTramite,
  postHistorialDeTramite,
} from 'api/tramites/historial';
import { getComentariosDeTramites } from 'api/tramites/comentariosDeTramites';
import { scrollToTop } from 'components/ScrollToTop';
import ModalCancelarTramite from 'views/Tramites/GPM/components/ModalCancelarTramite';
import { getAdeudoPadronAsync } from 'hooks/useAdeudoPadron';
import debtsFormatter from 'utils/debtsFormatter';
import _logger, { closableMessageWarn } from 'utils/logger';
import LinkWithTooltip from 'components/LinkWithTooltip';
import toSlug from 'utils/toSlug';
import API from 'api/index';
import { patchFirmarTramite } from 'api/tramites/index';
import { getExpedientesDeTramites, postExpedienteDeTramite, expedienteToAttrs } from 'api/tramites/expediente';
import ModalHistorial from 'views/Tramites/GPM/components/ModalHistorial';
import { getCitas, postCita } from 'api/tramites/citas';
import DatosCita from 'views/Appointments/components/DatosCita';
import ConsultaCita, { estadosCitasMap } from 'views/Appointments/components/ConsultaCita';
import { DATE_FRONTEND_FORMAT, formatReceived, formatSent } from 'utils/formatters';
import { ESTADOS_GLOBALES_MAP } from 'utils/estadosGlobales';
import Direccion from 'components/Direccion';
import EmpresasDeContribuyente from './components/EmpresasDeContribuyente';
import ActividadesEconomicas from './components/ActividadesEconomicas';
import ObligacionesFiscalesContribuyente from './components/ObligacionesFiscalesContribuyente';

const RenderFieldsByConfig = React.lazy(() => import('./components/RenderFieldsByConfig'));

const dataUrlToFile = async (dataUrl, fileName, type = 'image/png') => {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], `${fileName}.${type.split('/')[1]}`, { type });
};

const mapFirmas = (firma) => {
  const {
    nombre,
    puesto,
    rfc,
    serie,
    fecha,
    sello,
  } = firma;
  const asString = `FIRMANTE ${nombre} RFC ${rfc} CARGO ${puesto} NUM. CERTIFICADO ${serie}`
  + ` FECHA Y HORA ${fecha} |n SELLO ${sello}`;
  return { ...firma, asString };
};

const PasoFirma = ({ firmado }) => {
  if (firmado) {
    return 'Trámite ha sido firmado por el responsable, puede continuar a siguiente(s) paso(s)';
  }

  return null;
};

const nextOrSaveIconsMap = {
  [saveLabelEnum.SAVE]: SaveOutlined,
  [saveLabelEnum.START_TRAMITE]: SaveOutlined,
  [saveLabelEnum.GENERATE_CHARGES]: SyncOutlined,
  [saveLabelEnum.NOT_SUPPORTED]: RightOutlined,
  [saveLabelEnum.CONTINUE]: RightOutlined,
  [saveLabelEnum.SIGN]: SaveOutlined,
};

const getTitle = (step, currentStepTimeline, pasoActivo, pasoAMandarLastLog, historial) => {
  const textDecoration = step.orden && pasoAMandarLastLog?.orden > step.orden
    && !historial.some((h) => h.paso === step.key) ? 'line-through' : null;
  if (step.key === pasoActivo || (!pasoActivo && step.key === currentStepTimeline)) {
    return <b style={{ color: COLORS.accent, textDecoration }}>{step.title}</b>;
  }
  if (step.key === currentStepTimeline) {
    return <b style={{ textDecoration }}>{step.title}</b>;
  }
  return <span style={{ textDecoration }}>{step.title}</span>;
};

const getStatus = (currIdx, idx) => {
  if (!currIdx) return null;
  if (currIdx > idx) return 'finish';
  if (currIdx < idx) return 'wait';
  return 'process';
};

const StepsTimeline = ({
  currentStepTimeline,
  stepsTimelineItems,
  pasoActivo,
  stepToInLastLog,
  setCurrentStep,
  historial,
}) => {
  const currIdx = stepsTimelineItems.findIndex((e) => e.key === pasoActivo);
  return (
    <Steps
      size="small"
      progressDot
      labelPlacement="vertical"
      current={stepsTimelineItems.findIndex((s) => s.key === currentStepTimeline)}
      style={{ overflowX: 'scroll', paddingTop: 10 }}
    >
      {stepsTimelineItems.map((step, idx) => (
        <Steps.Step
          key={step.key}
          title={getTitle(step, currentStepTimeline, pasoActivo, stepToInLastLog, historial)}
          status={getStatus(currIdx, idx)}
          onClick={DEV ? (() => setCurrentStep(step.key)) : null}
        />
      ))}
    </Steps>
  );
};

const CasosDeRequisitos = ({
  casos = [],
  tramite,
  setCasoDeRequisitos,
  casoDeRequisitos,
}) => casos.map((caso) => (
  <Col span={8} key={caso.id}>
    <StyledButton
      block
      onClick={() => {
        if (!tramite?.caso) {
          setCasoDeRequisitos(caso);
        }
      }}
      style={{ cursor: tramite?.caso && 'not-allowed' }}
      $selected={caso.id === casoDeRequisitos?.id}
    >
      {caso.nombre}
      {caso.id === casoDeRequisitos?.id && (<CheckOutlined />)}
    </StyledButton>
  </Col>
));

const solicitanteDefault = AUTH_CONTRIBUYENTE
  ? solicitantesEnum.CONTRIBUYENTE : solicitantesEnum.CIUDADANO;

const GPM = () => {
  const screens = Grid.useBreakpoint();
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectUser);
  const { idTramite, idPlantilla } = useParams();
  const padrones = useSelector(selectPadrones).filter((p) => p.id !== 5);
  const [form] = Form.useForm();
  const base64 = Form.useWatch('base64', form);
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [plantillaDeTramite, setPlantillaDeTramite] = useState();
  const currentStepState = location.state?.currentStep;
  const [currentStep, setCurrentStep] = useState(currentStepState || stepsEnum.PADRON_SOLICITANTE);
  const [casoDeRequisitos, setCasoDeRequisitos] = useState();
  const [tramite, setTramite] = useState();
  const [statusRequisitos, setStatusRequisitos] = useState();
  const [referenciaPagoNetpay, setReferenciaPagoNetpay] = useState();
  const [catalogoDeVariables, setCatalogoDeVariables] = useState([]);
  const [valoresDeVariablesDeSelecion, setValoresDeVariables] = useState([]);
  const [base64Src, setBase64] = useState({});
  const [visiblePreview, setVisiblePreview] = useState();
  const [visiblePreviewRecibo, setVisiblePreviewRecibo] = useState(false);
  const [recibo, setRecibo] = useState();
  const [visibleCancelar, setVisibleCancelar] = useState(false);
  const [cargosTramite, setCargosTramite] = useState([]);
  const [cargosSaldados, setCargosSaldados] = useState(false);
  const [padronSolicitante, setPadronSolicitante] = useState(user);
  const [padronAfectado, setPadronAfectado] = useState();
  const [tipoPadronSolicitante, setTipoPadronSolicitante] = useState(solicitanteDefault);
  const [showMessagesRequisitos, setShowMessagesRequisitos] = useState(false);
  const [historial, setHistorial] = useState([]);
  const [categoriasDeVariables, setCategoriasDeVariables] = useState([]);
  const [complementos, setComplementos] = useState([]);
  const [loadingAdeudo, setLoadingAdeudo] = useState('');
  const [tarifas, setTarifas] = useState([]);
  const [expediente, setExpediente] = useState([]);
  const [visibleSeguimiento, setVisibleSeguimiento] = useState(false);
  const [hasLogsToSee, setHasLogsToSee] = useState(false);
  const [hasGISInfo, setHasGISInfo] = useState(false);
  const [citas, setCitas] = useState([]);
  const [hasEmpresas, setHasEmpresas] = useState(false);
  const [hasActividades, setHasActividades] = useState(false);
  const [hasObligaciones, setHasObligaciones] = useState(false);

  const tramiteHasAllVariables = useMemo(() => {
    if (!(tramite && plantillaDeTramite)) {
      return false;
    }
    return plantillaDeTramite.variables
      .every((v) => complementos.some((vt) => vt.variable === v));
  }, [tramite, plantillaDeTramite, complementos]);

  const getFileExpediente = (tipoDeDocumento, tipoDePaso = null) => expediente.findLast((e) => {
    if (e.tipo_de_documento_para_expediente !== tipoDeDocumento) return false;
    if (tipoDeDocumento === tipoDeDocumentoExpedienteEnum.GIS) return true;
    return e.paso_para_tramite === tipoDePaso;
  });

  const tipoPadronAfectado = plantillaDeTramite?.tipo_de_padron;
  const tipoDeAfectacion = plantillaDeTramite?.configuracion_de_padron?.tipo_de_afectacion.id;
  const alertasSolicitante = padronSolicitante?.alertas || [];
  const restrictivasSolicitante = alertasSolicitante.some((a) => a.es_restrictiva);
  const alertasAfectado = padronAfectado?.alertas || [];
  const restrictivasAfectado = alertasAfectado.some((a) => a.es_restrictiva);
  const hasAlertasRestrictivas = restrictivasSolicitante || restrictivasAfectado;

  const firmasElectronicas = useMemo(
    () => JSON.parse(tramite?.firma_electronica || '[]').filter((f) => !f.eliminado).map(mapFirmas),
    [tramite],
  );

  const _pasosDelTramite = (plantillaDeTramite?.pasos_para_tramites || []).filter((paso) => {
    if (paso.tipo_de_paso === tiposDePasosEnum.MODIFICACION_SOLICITANTE) {
      return paso.configuracion_de_padron.padron?.id === tipoPadronSolicitante;
    }
    return true;
  });
  const pasosDelTramite = _pasosDelTramite.filter((paso) => paso.paso_visible_solicitante);
  const altaEnPaso = _pasosDelTramite
    .some((p) => p.tipo_de_paso === tiposDePasosEnum.ALTA_AFECTADO);

  const pasoActual = useMemo(() => {
    const _pasoActual = pasosDelTramite.find((p) => p.id === currentStep);
    if (_pasoActual?.tipo_de_paso === tiposDePasosEnum.VARIABLES) {
      _pasoActual.hasAllVariables = !!_pasoActual.variables.length
        && _pasoActual.variables.filter((v) => v.es_requerido)
          .every((v) => complementos.some((vt) => vt.variable === v));
    }
    return _pasoActual;
  }, [currentStep, complementos]);
  const validateGIS = tipoPadronAfectado === padronesEnum.PREDIO
    && plantillaDeTramite?.requiere_gis;

  const tramiteEnProceso = ![4, 5].includes(tramite?.estados_globales);
  const lastLog = historial.at(-1);
  const lastLogForStep = historial.findLast((e) => e.paso === currentStep);
  const hasComentarioDeCapturaFinalizada = /finalizada captura/i.test(lastLogForStep?.comentario?.comentario);
  const esConsultaDeAdeudo = !!plantillaDeTramite?.es_consulta_de_adeudo;
  const permiteGenerarSinAdeudo = plantillaDeTramite?.permite_generar_sin_adeudo;
  const noIniciarConAdeudo = plantillaDeTramite?.no_iniciar_con_adeudo;
  const tiposDeCargosConsulta = plantillaDeTramite?.tipos_de_cargos_consulta;
  const citaPaso = citas.find((c) => c.paso === pasoActual?.id
    && ![estadosCitasMap.CANCELADA, estadosCitasMap.NO_ASISTIO].includes(c.estado_de_cita));
  const citaEnProcesoOFinalizada = [estadosCitasMap.EN_PROCESO, estadosCitasMap.FINALIZADA]
    .includes(citaPaso?.estado_de_cita);
  const stepToInLastLog = plantillaDeTramite?.pasos_para_tramites
    .find((p) => p.id === lastLog?.paso_a_mandar);

  const getHistorial = async (_tramite = tramite) => {
    setLoading(true);
    const [_historial, _comentarios] = await Promise.all([
      getHistorialDeTramite({ tramite: _tramite.id }),
      getComentariosDeTramites({ tramite: _tramite.id }),
    ]);
    const archivos = await getArchivosDeComentariosDeTramitesPorIdsComentario(_comentarios
      .map((e) => e.id));
    const indexed = _historial.map((h) => {
      const comentario = _comentarios.find((c) => c.id === h.comentario);
      if (!comentario) return h;
      comentario.archivos = archivos.filter((e) => e.comentario === comentario.id);
      return { ...h, comentario };
    });
    setHistorial(indexed);
    setLoading(false);
  };

  const getAdeudo = async (
    _tipoDePadron,
    _padronId,
    setterPadron,
    _plantilla = plantillaDeTramite,
  ) => {
    if (_tipoDePadron && _padronId) {
      setLoading(true);
      const [_cargos, err] = await getAdeudoPadronAsync(_tipoDePadron, _padronId);
      setLoading(false);
      if (err) {
        if (setterPadron) {
          setterPadron();
        }
        return;
      }
      const filtered = _cargos.filter((c) => !c.tramite);
      const mandatoryTypes = _plantilla.tipos_de_cargos_consulta;
      const mandatoryKeys = getMandatoryKeys(filtered, mandatoryTypes);
      if (_plantilla.es_consulta_de_adeudo && !mandatoryKeys.length
        && !_plantilla.permite_generar_sin_adeudo) {
        message.warn('El padrón no tiene adeudos por pagar en este trámite', 5);
      }
      const _tipoPadron = padrones
        .find((p) => p.id === (tipoPadronAfectado || tipoPadronSolicitante));
      if (_plantilla.no_iniciar_con_adeudo && mandatoryKeys.length) {
        closableMessageWarn(
          (
            <>
              El padrón tiene adeudos por pagar que impiden se inicie el trámite.
              <br />
              {_tipoPadron.available && (
                <>
                  Revise al apartado de
                  {' '}
                  <Button type="link" style={{ fontSize: 'inherit' }}>
                    <LinkWithTooltip
                      to={`/pagos/consulta-de-adeudos/${toSlug(`${_tipoPadron.descripcion} ${_tipoPadron.id}`)}`}
                      text="Consulta de Adeudo"
                      icon={ArrowRightOutlined}
                      textProps={{ $accent: true, strong: true }}
                      linkStyle={{ fontSize: 'inherit' }}
                      asA
                    />
                  </Button>
                  {' '}
                  para ver el detalle.
                  <br />
                </>
              )}
              {_tipoPadron.available ? 'O acuda a' : 'Acuda a'}
              {' '}
              <Text style={{ fontSize: 'inherit' }} strong>Ventanilla</Text>
              {' '}
              responsable
            </>
          ), 0,
        );
      }
      const mandatoryCharges = mandatoryKeys.map((k) => filtered.find((c) => c.id === k));
      setCargosTramite(mandatoryCharges);
    } else {
      setCargosTramite([]);
    }
  };

  const postLog = async (comentario, puede_continuar) => {
    if (!pasoActual) return null;
    const [_logValues] = generateLogValues(pasoActual, _pasosDelTramite, puede_continuar);
    const logValues = {
      ..._logValues,
      tramite: tramite.id,
      comentario: `[AUTOGENERADO] ${comentario}`,
    };
    const log = await postHistorialDeTramite(logValues);
    if (log) {
      await getHistorial();
    }
    return log;
  };

  const existingValidLogForStep = (step) => {
    if (!lastLog) {
      return false;
    }
    // ultimo registro de historial del paso actual
    const lastHistoryForStep = historial.findLast((l) => l.paso === step);
    if (!lastHistoryForStep) {
      // no existe log para paso actual
      return false;
    }

    const currStep = plantillaDeTramite.pasos_para_tramites.find((p) => p.id === step);

    const isLastStep = plantillaDeTramite.pasos_para_tramites.at(-1).id === step;
    // avanzó si el orden creció, el paso a mandar si fue el siguiente
    return stepToInLastLog?.orden > currStep.orden || isLastStep;
  };

  const pasoActualHasFirma = firmasElectronicas.some((f) => f.paso === pasoActual?.id)
    && existingValidLogForStep(pasoActual?.id);

  const searchPadron = async (qNameOrId, tipoDePadron, callback, extraParams = {}) => {
    setLoading(true);
    const q = typeof qNameOrId === 'string' && form.getFieldValue(qNameOrId);
    const id = typeof qNameOrId === 'number' && qNameOrId;
    if (q || id) {
      const _params = { ...extraParams };
      if (q) {
        _params.q = q;
      } else {
        _params.id = id;
      }
      const match = await gettersMap[tipoDePadron](_params, false);
      if (match) {
        if (callback) {
          setLoading(false);
          callback(match);
        }
        return match;
      }
    }
    setLoading(false);
    return null;
  };

  const getRequirements = (plantilla) => {
    // const _altaEnPaso = plantilla.pasos_para_tramites
    //   .some((p) => p.tipo_de_paso === tiposDePasosEnum.ALTA_AFECTADO);
    const padron = plantilla.tipo_de_padron;
    const configPadron = plantilla.configuracion_de_padron;
    const hasTiposDeCargos = plantilla.tipos_de_cargos.length
      || plantilla.pasos_para_tramites.some((p) => p.tipos_de_cargos.length);
    const _tiposDeCargosConsulta = plantilla.tipos_de_cargos_consulta;
    // const _tipoDeAfectacion = plantilla.configuracion_de_padron?.tipo_de_afectacion;
    const cargosAplicacionesNoAdmitidas = plantilla.tipos_de_cargos
      .filter((tc) => !tiposDeAplicacionesAdmitidas.includes(tc.tipo_de_aplicacion));
    const conditions = [
      {
        condition: !plantilla.formato_para_folio,
        text: 'formato de folio',
      },
      {
        condition: (padron || configPadron) && !plantilla.requiere_padron,
        text: 'requiere padron',
      },
      {
        condition: (plantilla.requiere_padron || configPadron) && !padron,
        text: 'tipo de padrón',
      },
      {
        condition: (plantilla.requiere_padron || padron) && !configPadron,
        text: 'configuración de padrón',
      },
      {
        condition: plantilla.es_tramite_gratuito && hasTiposDeCargos,
        text: 'es trámite gratuito',
      },
      {
        condition: !plantilla.es_tramite_gratuito && !hasTiposDeCargos,
        text: 'tipos de cargos',
      },
      {
        condition: plantilla.es_consulta_de_adeudo && !_tiposDeCargosConsulta.length,
        text: 'tipos de cargos consulta',
      },
      {
        condition: _tiposDeCargosConsulta.length
          && !plantilla.es_consulta_de_adeudo && !plantilla.no_iniciar_con_adeudo,
        text: 'es consulta de adeudo o no iniciar con adeudo',
      },
      {
        condition: !plantilla.catalogo_de_canales_de_pago.includes(2),
        text: 'canal de pago',
      },
      // {
      //   condition: _tipoDeAfectacion && _tipoDeAfectacion?.id !== afectacionesEnum.CONSULTA
      //     && !_altaEnPaso,
      //   text: `afectación "${_tipoDeAfectacion?.descripcion}" no admitida`,
      // },
      {
        condition: (plantilla.requiere_padron || padron) && !gettersMap[padron],
        text: `tipo de tramite de padron "${padron}" no soportado`,
      },
      {
        condition: !DEV && cargosAplicacionesNoAdmitidas.length,
        text: `tipos de cargos (${listToString(cargosAplicacionesNoAdmitidas
          .map((c) => c.clave))}) con tipos de aplicaciones no admitidas para canal actual`,
      },
    ];
    const conditionsNotPassed = conditions.filter((e) => e.condition).map((e) => e.text);
    if (conditionsNotPassed.length) {
      const str = listToString(conditionsNotPassed);
      return `Revisar: ${str}`;
    }
    return '';
  };

  const getTarifasPaginados = async (params = null, all = true) => {
    try {
      const response = await API.get('configuracion/tarifas/', { params });
      if (all) {
        if (!response.data.next) return response.data.results;
        const page = new URL(response.data.next).searchParams.get('page');
        const _cargos = await getTarifasPaginados({ ...params, page }, all);
        return response.data.results.concat(_cargos);
      }
      return response.data;
    } catch (err) {
      _logger(err);
      return all ? [] : { results: [] };
    }
  };

  const fetchTarifas = async (_plantilla) => {
    const _tiposDeCargos = _plantilla.tipos_de_cargos.concat(..._plantilla.pasos_para_tramites
      .filter((p) => p.tipo_de_paso === tiposDePasosEnum.CARGOS).map((p) => p.tipos_de_cargos));
    const catalogos_de_tarifas = [...new Set([..._tiposDeCargos.map((tc) => tc.catalogo_de_tarifa)
      .filter((tc) => tc)])];
    if (catalogos_de_tarifas.length) {
      const promises = await Promise.all(catalogos_de_tarifas
        .map((c) => getTarifasPaginados({ catalogo_de_tarifa: c })));
      setTarifas(promises.flat());
    } else {
      setTarifas([]);
    }
  };

  const fetchPlantilla = async (id) => {
    setLoading(true);
    const _plantillaDeTramite = await getPlantillaTramite(id);
    if (_plantillaDeTramite) {
      const requirements = getRequirements(_plantillaDeTramite);
      if (requirements) {
        // se intenta iniciar un trámite no configurado correctamente
        setErrorMessages(['La configuración para el trámite es incorrecta', requirements.toUpperCase()]);
      } else {
        setErrorMessages([]);
        setPlantillaDeTramite(_plantillaDeTramite);
        await fetchTarifas(_plantillaDeTramite);
        const _altaEnPaso = _plantillaDeTramite.pasos_para_tramites
          .some((p) => p.tipo_de_paso === tiposDePasosEnum.ALTA_AFECTADO);
        if (_plantillaDeTramite.tipos_de_cargos_consulta.length
            && (!_plantillaDeTramite.tipo_de_padron || _altaEnPaso)) {
          setTimeout(() => getAdeudo(
            tipoPadronSolicitante,
            padronSolicitante.id,
            null,
            _plantillaDeTramite,
          ));
        }
      }
    } else {
      setErrorMessages(['Ocurrió un error al obtener la configuración para iniciar el trámite']);
    }
    setLoading(false);
  };

  const getCargosTramite = async (_tramite = tramite, _plantillaDeTramite = plantillaDeTramite) => {
    if (_plantillaDeTramite.tipos_de_cargos.length
      || _plantillaDeTramite.tipos_de_cargos_consulta.length) {
      const _tipoDePadronSolicitante = _tramite.solicitante_ciudadano
        ? solicitantesEnum.CIUDADANO : solicitantesEnum.CONTRIBUYENTE;
      const _idSolicitante = _tipoDePadronSolicitante === solicitantesEnum.CIUDADANO
        ? _tramite.ciudadano : _tramite.contribuyente;
      const _tipoDePadronAfectado = getTipoDePadronPorContenType(
        _tramite.content_type_a_padron,
      );
      const [, err] = await getAdeudoPadronAsync(
        _tipoDePadronAfectado && !altaEnPaso ? _tipoDePadronAfectado : _tipoDePadronSolicitante,
        _tipoDePadronAfectado && !altaEnPaso ? _tramite.object_id_padron : _idSolicitante,
        (_message) => setLoadingAdeudo(_message),
      );
      setLoadingAdeudo(null);
      if (err) {
        setErrorMessages([err, 'searchTramite']);
        return [err, []];
      }
    }
    const cargos = await getCargosPaginados({
      tramite: _tramite.id,
      eliminado: false,
      cancelado: false,
    });
    const formatted = debtsFormatter(cargos);
    setCargosTramite(formatted);
    return [null, formatted];
  };

  const getExpediente = async (_tramite = tramite) => {
    setLoading(true);
    const _expediente = await getExpedientesDeTramites({ tramite: _tramite.id });
    setExpediente(_expediente);
    setLoading(false);
  };

  const fetchCitas = async (_tramite = tramite) => {
    const _citas = await getCitas({ tramite: _tramite.id });
    setCitas(_citas);
  };

  const searchTramite = async () => {
    setLoading(true);
    const [_tramite] = await getTramites({ id: idTramite });
    if (!_tramite || !_tramite.plantilla.catalogo_de_canales_de_pago
      .includes(CATALOGOS_DE_CANAL_DE_PAGO.PAGO_EN_LINEA)) {
      return setErrorMessages([`No se encontró trámite con el id ${idTramite}`, 'searchTramite']);
    }
    setErrorMessages([]);
    const _plantillaDeTramite = _tramite.plantilla;
    if (!_plantillaDeTramite) {
      return setLoading(false);
    }
    const _tipoDePadronSolicitante = _tramite.solicitante_ciudadano
      ? solicitantesEnum.CIUDADANO : solicitantesEnum.CONTRIBUYENTE;
    const _idSolicitante = _tipoDePadronSolicitante === solicitantesEnum.CIUDADANO
      ? _tramite.ciudadano : _tramite.contribuyente;
    const _padronSolicitante = await searchPadron(_idSolicitante, _tipoDePadronSolicitante);
    if (!_padronSolicitante) {
      return null;
    }
    const _tipoDePadronAfectado = getTipoDePadronPorContenType(
      _tramite.content_type_a_padron,
    );
    if (_tramite.object_id_padron) {
      if (!_tipoDePadronAfectado) {
        return null;
      }
      const _padronAfectado = await searchPadron(
        _tramite.object_id_padron,
        _tipoDePadronAfectado,
      );
      if (!_padronAfectado) return null;
      setPadronAfectado(formatReceived(_padronAfectado));
    }
    await getHistorial(_tramite);
    await getExpediente(_tramite);
    const [err, _cargos] = await getCargosTramite(_tramite, _plantillaDeTramite);
    if (err) {
      return setLoading(false);
    }
    const _complementos = await getComplementosDeTramites({ tramite: _tramite.id });
    await fetchTarifas(_plantillaDeTramite);
    await fetchCitas(_tramite);
    setComplementos(_complementos);
    setCargosTramite(_cargos);
    setTipoPadronSolicitante(_tipoDePadronSolicitante);
    setPadronSolicitante(formatReceived(_padronSolicitante));
    setCasoDeRequisitos(_plantillaDeTramite.casos.find((c) => c.id === _tramite.caso));
    setPlantillaDeTramite(_plantillaDeTramite);
    setTramite(_tramite);
    return setLoading(false);
  };

  const fetchTramiteOPlantilla = () => {
    if (idPlantilla) {
      fetchPlantilla(idPlantilla);
    } else if (idTramite) {
      searchTramite(idTramite);
    }
  };

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      setLoading(true);
      const promises = [
        getVariables(),
        getValoresPorVariablesDeSeleccion(),
        getCategoriasDeVariables(),
      ];
      const [
        _catalogoDeVariables,
        _valoresDeVariablesDeSeleccion,
        _categoriasDeVariables,
      ] = await Promise.all(promises);
      if (mounted) {
        setCatalogoDeVariables(_catalogoDeVariables);
        setValoresDeVariables(_valoresDeVariablesDeSeleccion);
        setCategoriasDeVariables(_categoriasDeVariables);
        setLoading(false);
      }
    };
    if (isAuthenticated) {
      fetchTramiteOPlantilla();
      fetchData();
    } else {
      navigate(`/mi-cuenta?redirect=${location.pathname}`, { replace: true });
    }
    return () => {
      mounted = false;
      notification.destroy();
      window.history.replaceState({}, document.title);
    };
  }, []);

  const onCancelPreviewRecibo = () => {
    setVisiblePreviewRecibo(false);
    setRecibo();
  };

  useEffect(() => {
    validateLastTransaction(false, async (_recibo) => {
      if (_recibo) {
        await searchTramite();
        setTimeout(() => {
          setRecibo(_recibo);
          setVisiblePreviewRecibo(true);
        }, 1000);
      }
    }, setLoading, paymentLocationsEnum.GPM);
  }, []);

  const stepsTitles = {
    [stepsEnum.PADRON_SOLICITANTE]: 'Padrón solicitante',
    [stepsEnum.PADRON_AFECTADO]: 'Padrón secundario',
    [stepsEnum.REQUISITOS_GENERALES]: 'Requisitos generales',
    [stepsEnum.CASOS_DE_REQUISITOS]: 'Selección de caso de requisitos',
    [stepsEnum.REQUISITOS_DEL_CASO]: casoDeRequisitos?.descripcion,
    [stepsEnum.VARIABLES]: 'Complementar información',
    [stepsEnum.GENERAR_CARGOS]: 'Adeudo',
  };

  const currentStepTitle = useMemo(
    () => {
      if (typeof currentStep === 'string') {
        return stepsTitles[currentStep];
      }
      return pasoActual?.nombre;
    },
    [currentStep, plantillaDeTramite, padronSolicitante, padronAfectado],
  );

  const stepsTimelineItems = useMemo(() => {
    if (!plantillaDeTramite) {
      return [];
    }
    const steps = [
      {
        key: stepsEnum.PADRON_SOLICITANTE,
        title: stepsTitles[stepsEnum.PADRON_SOLICITANTE],
        visible: true,
      },
      {
        key: stepsEnum.PADRON_AFECTADO,
        title: stepsTitles[stepsEnum.PADRON_AFECTADO],
        visible: tipoPadronAfectado && !altaEnPaso,
      },
      {
        title: stepsTitles[stepsEnum.REQUISITOS_GENERALES],
        key: stepsEnum.REQUISITOS_GENERALES,
        visible: plantillaDeTramite.requisitos_generales.length,
      },
      {
        key: stepsEnum.CASOS_DE_REQUISITOS,
        title: stepsTitles[stepsEnum.CASOS_DE_REQUISITOS],
        visible: plantillaDeTramite.casos.length,
      },
      {
        key: stepsEnum.REQUISITOS_DEL_CASO,
        title: stepsTitles[stepsEnum.REQUISITOS_DEL_CASO],
        visible: plantillaDeTramite.casos.length,
      },
      {
        key: stepsEnum.VARIABLES,
        title: stepsTitles[stepsEnum.VARIABLES],
        visible: plantillaDeTramite.variables.length,
      },
      {
        key: stepsEnum.GENERAR_CARGOS,
        title: stepsTitles[stepsEnum.GENERAR_CARGOS],
        visible: plantillaDeTramite.tipos_de_cargos.length
          || getMandatoryKeys(cargosTramite, tiposDeCargosConsulta).length,
      },
    ].filter((step) => step.visible);
    return steps.concat(pasosDelTramite
      .map(({ nombre: title, id: key, orden }) => ({ title, key, orden })));
  }, [plantillaDeTramite, currentStep, tramite, padronSolicitante, padronAfectado,
    statusRequisitos, tramiteHasAllVariables]);

  const currentStepIsLastStep = currentStep === stepsTimelineItems.at(-1)?.key;

  const VISIBLE_PREVIEW_ENUM = {
    RECEIPT: 'RECEIPT',
    PAYMENT_ORDER: 'PAYMENT_ORDER',
    FORMATO_DE_TRAMITE: 'FORMATO_DE_TRAMITE',
    ACUSE_VARIABLES: 'ACUSE_VARIABLES',
  };

  const getAcuseTramite = async () => {
    setLoading(true);
    if (!base64Src[VISIBLE_PREVIEW_ENUM.FORMATO_DE_TRAMITE]) {
      const [total] = round(cargosTramite.reduce((acum, curr) => acum + curr.importe, 0));
      const values = {
        ID: plantillaDeTramite.id_reporte_acuse,
        Parametros: [
          {
            Nombre: 'clave',
            TipoParametro: 'Texto',
            Valor: tramite.id,
          },
          {
            Nombre: 'tramite',
            TipoParametro: 'Texto',
            Valor: tramite.id,
          },
          {
            Nombre: 'total',
            TipoParametro: 'Texto',
            Valor: total,
          },
        ],
      };
      const [err, formato] = await postObtenerDocumento(values);
      if (formato) {
        setBase64({
          ...base64Src,
          [VISIBLE_PREVIEW_ENUM.FORMATO_DE_TRAMITE]: formato,
        });
        setVisiblePreview(VISIBLE_PREVIEW_ENUM.FORMATO_DE_TRAMITE);
      } else {
        message.warn(err);
      }
    } else {
      setVisiblePreview(VISIBLE_PREVIEW_ENUM.FORMATO_DE_TRAMITE);
    }
    setLoading(false);
  };

  const imprimirOrdenDePago = async (cargos, _preview = true, clickNetPay = false) => {
    setLoading(true);
    const ciudadano = tipoPadronSolicitante === solicitantesEnum.CIUDADANO
      ? padronSolicitante.id : padronSolicitante.ciudadano;
    const result = await postGenererReferenciasNetpay(
      padronAfectado ? tipoPadronAfectado : tipoPadronSolicitante,
      padronAfectado?.id || padronSolicitante.id,
      cargos.map((c) => ({ ...c, importe: c.adeudo_total })),
      padrones,
      ciudadano,
    );
    if (result) {
      setBase64({
        ...base64Src,
        [VISIBLE_PREVIEW_ENUM.PAYMENT_ORDER]: result.base64,
      });
      setReferenciaPagoNetpay(result.folio);
      if (_preview) {
        setVisiblePreview(VISIBLE_PREVIEW_ENUM.PAYMENT_ORDER);
      }
      if (clickNetPay) {
        setTimeout(() => getNetPayBtn()?.click(), 1500);
      }
    }
    setTimeout(() => setLoading(false));
  };

  const saveFile = async (logComment, _values) => {
    setLoading(true);
    const values = _values || form.getFieldsValue();
    values.tramite = tramite.id;
    const tipoDocByTipoPaso = {
      [tiposDePasosEnum.FOTOGRAFIA]: tipoDeDocumentoExpedienteEnum.FOTOGRAFIA,
      [tiposDePasosEnum.EMISION_DE_DOCUMENTO]: tipoDeDocumentoExpedienteEnum.DOCUMENTO,
      [tiposDePasosEnum.CARGA_DE_DOCUMENTO]: tipoDeDocumentoExpedienteEnum.DOCUMENTO,
      [tiposDePasosEnum.CARGOS]: tipoDeDocumentoExpedienteEnum.RECIBO,
      [stepsEnum.GENERAR_CARGOS]: tipoDeDocumentoExpedienteEnum.RECIBO,
    };
    const tipoDePaso = pasoActual?.tipo_de_paso || currentStep;
    values.tipo_de_documento_para_expediente = values.tipo_de_documento_para_expediente
      || tipoDocByTipoPaso[tipoDePaso];
    values.paso_para_tramite = pasoActual?.id;
    values.archivo = values.archivo.file;
    const saved = await postExpedienteDeTramite(values);
    if (saved) {
      await getExpediente();
      if (logComment) {
        const _log = await postLog(logComment, true);
        setLoading(false);
        return [saved, _log];
      }
    }
    setLoading(false);
    return [saved];
  };

  const getRecibo = async (cargoId = cargosTramite[0]?.id, show = true) => {
    const srcReceipt = `${VISIBLE_PREVIEW_ENUM.RECEIPT}_${currentStep}`;
    const _reciboExp = getFileExpediente(
      tipoDeDocumentoExpedienteEnum.RECIBO,
      typeof currentStep === 'number' ? currentStep : null,
    );
    if (_reciboExp) {
      if (show) {
        setBase64({
          ...base64Src,
          [srcReceipt]: _reciboExp.archivo,
        });
        setVisiblePreview(srcReceipt);
      }
      return;
    }
    setLoading(true);
    const _recibo = await getReciboPDFDeTramite(cargoId);
    if (!_recibo) {
      message.warn(`No fue posible consultar ${RECEIPT_LABEL}`);
      setLoading(false);
      return;
    }
    const reciboFormatted = await formatReference(_recibo, false);
    if (!reciboFormatted) {
      message.warn(`No fue posible consultar ${RECEIPT_LABEL}`);
      setLoading(false);
      return;
    }
    const name = `${RECEIPT_LABEL}${pasoActual ? `__${pasoActual.nombre}` : ''}`;
    const file = await dataUrlToFile(reciboFormatted.base64, name, 'application/pdf');
    const [savedFile] = await saveFile(null, { archivo: { file, name: `${name}.pdf` } });
    setLoading(false);
    if (!savedFile) {
      message.info('Intentelo de nuevo');
      return;
    }
    if (show) getRecibo();
  };

  const resetCargosSaldados = () => (currentStep === stepsEnum.GENERAR_CARGOS
    || pasoActual?.tipo_de_paso === tiposDePasosEnum.CARGOS) && setCargosSaldados(false);

  const nextStep = () => {
    if (currentStep === stepsEnum.PADRON_SOLICITANTE) {
      notification.destroy();
    }
    let idx = stepsTimelineItems.findIndex((e) => e.key === currentStep);
    let next = stepsTimelineItems[idx + 1]?.key;
    if (typeof next === 'number' && typeof currentStep === 'number') {
      // eslint-disable-next-line no-loop-func
      while (!next && !historial.find((h) => h.paso_a_mandar === next)) {
        idx += 1;
        next = stepsTimelineItems[idx]?.key;
      }
    }
    if (lastLog && currentStep === lastLog.paso && lastLog.paso_a_mandar !== currentStep) {
      setCurrentStep(lastLog?.paso_a_mandar);
      resetCargosSaldados();
      return;
    }
    if (next) {
      setCurrentStep(next);
      resetCargosSaldados();
    }
  };

  const prevStep = (_currentStep = currentStep) => {
    const currIdx = stepsTimelineItems.findIndex((e) => e.key === _currentStep);
    const prevInTimeline = stepsTimelineItems[currIdx - 1];
    const prev = prevInTimeline?.key;
    if (prev) {
      if (typeof prev === 'number' && !historial.some((h) => h.paso === prev)) {
        prevStep(prev);
      } else {
        setCurrentStep(prev);
        resetCargosSaldados();
      }
    }
  };

  const backButton = (
    <Button
      disabled={!currentStep}
      onClick={() => prevStep()}
      $accent
      shape="round"
      style={{ height: 32, marginBottom: 25 }}
    >
      <LeftOutlined />
      {' '}
      Anterior
    </Button>
  );

  const canContinue = () => {
    if (typeof currentStep === 'string') {
      if (currentStep === stepsEnum.PADRON_SOLICITANTE) {
        if (tipoPadronAfectado && !altaEnPaso) {
          return !!padronSolicitante;
        }
        return !!tramite;
      }
      if (currentStep === stepsEnum.PADRON_AFECTADO) {
        if (!tramite) return false;
        return !validateGIS || getFileExpediente(tipoDeDocumentoExpedienteEnum.GIS);
      }
      if (currentStep === stepsEnum.REQUISITOS_GENERALES) {
        return statusRequisitos === statusRequisitosEnum.CONTINUE;
      }
      if (currentStep === stepsEnum.CASOS_DE_REQUISITOS) {
        return !!casoDeRequisitos;
      }
      if (currentStep === stepsEnum.REQUISITOS_DEL_CASO) {
        return statusRequisitos === statusRequisitosEnum.CONTINUE;
      }
      if (currentStep === stepsEnum.VARIABLES) {
        return tramiteHasAllVariables;
      }
      if (currentStep === stepsEnum.GENERAR_CARGOS) {
        return cargosSaldados && !!pasosDelTramite.length;
      }
    }
    if (pasoActual) {
      if (pasoActual.tipo_de_paso === tiposDePasosEnum.CITA) return citaEnProcesoOFinalizada;
      if (!existingValidLogForStep(pasoActual.id)) return false;
      const canContinueMap = {
        [tiposDePasosEnum.EMISION_DE_DOCUMENTO]: getFileExpediente(
          tipoDeDocumentoExpedienteEnum.DOCUMENTO,
          pasoActual.id,
        ),
        [tiposDePasosEnum.VARIABLES]: pasoActual.hasAllVariables,
        [tiposDePasosEnum.FIRMA_ELECTRONICA]: pasoActualHasFirma,
        [tiposDePasosEnum.GIS]: getFileExpediente(tipoDeDocumentoExpedienteEnum.GIS),
        // eslint-disable-next-line max-len
        [tiposDePasosEnum.VALIDACION_REQUISITOS]: statusRequisitos === statusRequisitosEnum.CONTINUE,
        [tiposDePasosEnum.CARGOS]: cargosSaldados,
        [tiposDePasosEnum.ACTIVIDADES_ECONOMICAS]: hasComentarioDeCapturaFinalizada,
        [tiposDePasosEnum.ACTIVIDADES_ECONOMICAS_SAT]: hasComentarioDeCapturaFinalizada,
        [tiposDePasosEnum.OBLIGACIONES]: hasComentarioDeCapturaFinalizada,
      };
      return pasoActual.tipo_de_paso in canContinueMap
        ? !!canContinueMap[pasoActual.tipo_de_paso] : true;
    }
    return false;
  };

  const refPatch = useRef(false);

  const patchFlujoInicialConcluido = async () => {
    setLoading(true);
    refPatch.current = true;
    const _tramite = await patchTramite(tramite.id, { flujo_inicial_concluido: true });
    if (_tramite) {
      message.destroy();
      setTramite(_tramite);
    }
    refPatch.current = false;
    setLoading(false);
  };

  useEffect(() => {
    const idx = stepsTimelineItems.findIndex((e) => e.key === currentStep);
    const _nextStep = stepsTimelineItems[idx + 1]?.key;
    if (!loading && canContinue() && typeof _nextStep === 'number'
      && !tramite.flujo_inicial_concluido && !refPatch.current) {
      patchFlujoInicialConcluido();
    }
  }, [canContinue(), loading]);

  const getButtonLabel = () => {
    if (!plantillaDeTramite) return saveLabelEnum.NOT_SUPPORTED;

    if (typeof currentStep === 'string') {
      if (stepsEnum.PADRON_SOLICITANTE === tramite) {
        return saveLabelEnum.CONTINUE;
      }
      if (currentStep === stepsEnum.PADRON_SOLICITANTE) {
        if (padronSolicitante && (!tipoPadronAfectado || altaEnPaso)) {
          if (!esConsultaDeAdeudo) {
            return saveLabelEnum.START_TRAMITE;
          }
          if ((permiteGenerarSinAdeudo && !noIniciarConAdeudo && !cargosTramite.length)
            || (!permiteGenerarSinAdeudo && !noIniciarConAdeudo && cargosTramite.length)
            || (noIniciarConAdeudo && !cargosTramite.length)) {
            return saveLabelEnum.START_TRAMITE;
          }
        }
        return saveLabelEnum.NOT_SUPPORTED;
      }
      if (currentStep === stepsEnum.PADRON_AFECTADO) {
        if (tipoDeAfectacion === afectacionesEnum.ALTA) {
          if (padronAfectado) return saveLabelEnum.CONTINUE;
        }
        if (padronAfectado) {
          if (validateGIS && !hasGISInfo) return saveLabelEnum.NOT_SUPPORTED;
          if (!esConsultaDeAdeudo) return saveLabelEnum.START_TRAMITE;
          if ((permiteGenerarSinAdeudo && !noIniciarConAdeudo && !cargosTramite.length)
            || (!permiteGenerarSinAdeudo && !noIniciarConAdeudo && cargosTramite.length)
            || (noIniciarConAdeudo && !cargosTramite.length)) {
            return saveLabelEnum.START_TRAMITE;
          }
        }
        return saveLabelEnum.NOT_SUPPORTED;
      }
      if (currentStep === stepsEnum.REQUISITOS_GENERALES) {
        if (statusRequisitos === statusRequisitosEnum.CONTINUE) {
          return saveLabelEnum.CONTINUE;
        }
        if ([statusRequisitosEnum.COMPLETE, statusRequisitosEnum.INCOMPLETE_SAVE]
          .includes(statusRequisitos)) {
          return saveLabelEnum.SAVE;
        }
      }
      if (currentStep === stepsEnum.CASOS_DE_REQUISITOS) {
        return casoDeRequisitos ? saveLabelEnum.CONTINUE : saveLabelEnum.NOT_SUPPORTED;
      }
      if (currentStep === stepsEnum.REQUISITOS_DEL_CASO) {
        if (statusRequisitos === statusRequisitosEnum.CONTINUE) {
          return saveLabelEnum.CONTINUE;
        }
        if (statusRequisitos === statusRequisitosEnum.COMPLETE) {
          return saveLabelEnum.SAVE;
        }
        return saveLabelEnum.NOT_SUPPORTED;
      }
      if (currentStep === stepsEnum.VARIABLES) {
        return tramiteHasAllVariables ? saveLabelEnum.CONTINUE : saveLabelEnum.SAVE;
      }
      if (currentStep === stepsEnum.GENERAR_CARGOS) {
        if (cargosSaldados) {
          return saveLabelEnum.CONTINUE;
        }
        const tiposDeCargosNoGenerados = plantillaDeTramite.tipos_de_cargos
          .filter((t) => !cargosTramite.some((c) => c.tipo_de_cargo.id === t.id));
        if (tiposDeCargosNoGenerados.length) {
          return saveLabelEnum.GENERATE_CHARGES;
        }
      }
    }
    if (!pasoActual) return saveLabelEnum.NOT_SUPPORTED;
    const _log = existingValidLogForStep(pasoActual.id);
    const pasosSoloLog = [
      tiposDePasosEnum.VALIDACION,
      tiposDePasosEnum.VARIABLES,
      tiposDePasosEnum.MODIFICACION_AFECTADO,
      tiposDePasosEnum.MODIFICACION_SOLICITANTE,
      tiposDePasosEnum.CAMBIO_DE_PROPIETARIO,
      tiposDePasosEnum.DIRECCION_PRINCIPAL,
      tiposDePasosEnum.DIRECCION_SECUNDARIA,
      tiposDePasosEnum.FOTOGRAFIA,
      tiposDePasosEnum.VISTA_PADRON,
    ];
    if (pasosSoloLog.includes(pasoActual.tipo_de_paso)) {
      return _log ? saveLabelEnum.CONTINUE : saveLabelEnum.SAVE;
    }
    if (pasoActual.tipo_de_paso === tiposDePasosEnum.FIRMA_ELECTRONICA) {
      if (pasoActual.firma_automatica && pasoActual.atiende_solicitante && !_log) {
        return saveLabelEnum.SIGN;
      }
      if (pasoActualHasFirma) return saveLabelEnum.CONTINUE;
    }
    if (pasoActual.tipo_de_paso === tiposDePasosEnum.REQUISITOS) {
      if ([statusRequisitosEnum.COMPLETE, statusRequisitosEnum.INCOMPLETE_SAVE]
        .includes(statusRequisitos)) {
        return saveLabelEnum.SAVE;
      }
    }
    if (pasoActual.tipo_de_paso === tiposDePasosEnum.VARIABLES) {
      if (_log) return saveLabelEnum.CONTINUE;
      if (pasoActual.atiende_solicitante) return saveLabelEnum.SAVE;
    }
    if (pasoActual.tipo_de_paso === tiposDePasosEnum.CARGOS) {
      if (cargosSaldados) return saveLabelEnum.CONTINUE;
      const tiposDeCargosNoGenerados = pasoActual.tipos_de_cargos
        .filter((t) => !cargosTramite.some((c) => c.tipo_de_cargo.id === t.id));
      if (tiposDeCargosNoGenerados.length && pasoActual.atiende_solicitante) {
        return saveLabelEnum.GENERATE_CHARGES;
      }
    }
    if (pasoActual.tipo_de_paso === tiposDePasosEnum.GIS) {
      if (tramite.attrs) {
        return _log && getFileExpediente(tipoDeDocumentoExpedienteEnum.GIS)
          ? saveLabelEnum.CONTINUE : saveLabelEnum.SAVE;
      }
      return saveLabelEnum.NOT_SUPPORTED;
    }
    if (pasoActual.tipo_de_paso === tiposDePasosEnum.CITA) {
      if (!citaPaso) return saveLabelEnum.SAVE;
      if ([estadosCitasMap.EN_PROCESO, estadosCitasMap.FINALIZADA]
        .includes(citaPaso.estado_de_cita)) {
        return saveLabelEnum.CONTINUE;
      }
      return saveLabelEnum.NOT_SUPPORTED;
    }
    if (pasoActual.tipo_de_paso === tiposDePasosEnum.VALIDACION_REQUISITOS) {
      return statusRequisitos === statusRequisitosEnum.CONTINUE
        ? saveLabelEnum.CONTINUE : saveLabelEnum.NOT_SUPPORTED;
    }
    if (pasoActual.tipo_de_paso === tiposDePasosEnum.ALTA_AFECTADO) {
      return padronAfectado ? saveLabelEnum.CONTINUE : saveLabelEnum.SAVE;
    }
    if (pasoActual.tipo_de_paso === tiposDePasosEnum.MODIFICACION_SOLICITANTE) {
      return padronAfectado ? saveLabelEnum.CONTINUE : saveLabelEnum.SAVE;
    }
    if (pasoActual.tipo_de_paso === tiposDePasosEnum.EMPRESAS) {
      if (existingValidLogForStep(pasoActual.id) && hasComentarioDeCapturaFinalizada) {
        return saveLabelEnum.CONTINUE;
      }
      if (hasEmpresas) return saveLabelEnum.SAVE;
    }
    if ([tiposDePasosEnum.ACTIVIDADES_ECONOMICAS, tiposDePasosEnum.ACTIVIDADES_ECONOMICAS_SAT]
      .includes(pasoActual.tipo_de_paso)) {
      if (_log && hasComentarioDeCapturaFinalizada) return saveLabelEnum.CONTINUE;
      if (hasActividades) return saveLabelEnum.SAVE;
    }
    if (pasoActual.tipo_de_paso === tiposDePasosEnum.OBLIGACIONES) {
      if (existingValidLogForStep(pasoActual.id) && hasComentarioDeCapturaFinalizada) {
        return saveLabelEnum.CONTINUE;
      }
      if (hasObligaciones) {
        return saveLabelEnum.SAVE;
      }
    }
    return saveLabelEnum.NOT_SUPPORTED;
  };

  const showAlertas = () => Modal.confirm({
    width: '60vw',
    title: 'No es posible iniciar trámite debido a que existen alertas restrictivas',
    content: 'Usted debe presentarse en alguna oficina de recaudación a consultar el detalle',
    cancelText: 'Cerrar',
    okText: (
      <>
        Ver oficinas
        <RightOutlined />
      </>
    ),
    okButtonProps: {
      style: { background: COLORS.accent },
    },
    onOk: () => navigate('/oficinas'),
  });

  const saveGIS = async (_base64 = form.getFieldValue('base64')) => {
    if (!loading) {
      setLoading(true);
    }
    if (getFileExpediente(tipoDeDocumentoExpedienteEnum.GIS)
      && !existingValidLogForStep(pasoActual.id)) {
      const _log = await postLog('Captura de GIS', true);
      if (!_log) {
        message.info('Se guardó la imagen de referencia, puede continuar');
      }
      setLoading(false);
      return;
    }
    const file = await dataUrlToFile(_base64, 'png');
    const values = {
      tipo_de_documento_para_expediente: tipoDeDocumentoExpedienteEnum.GIS,
      archivo: { file, name: 'gis.png' },
    };
    const [savedFile, _log] = await saveFile(!pasoActual || existingValidLogForStep(pasoActual.id)
      ? null : 'Captura de GIS', values);
    if (!_log || !savedFile) {
      message.info('Se guardó la imagen de referencia, puede continuar');
    }
    setLoading(false);
  };

  const nextOrSaveButton = useMemo(
    () => {
      const _canContinue = canContinue();
      const nextButtonProps = {
        disabled: !_canContinue,
        $accent: true,
        shape: 'round',
        style: { height: 32, marginBottom: 25 },
      };
      if (_canContinue && currentStepIsLastStep) {
        return <FakeButton />;
      }
      if (_canContinue) {
        nextButtonProps.onClick = nextStep;
      }
      if (!tramite && currentStep === stepsEnum.PADRON_SOLICITANTE && hasAlertasRestrictivas) {
        nextButtonProps.onClick = showAlertas;
      }
      if (_canContinue) {
        const currIdx = stepsTimelineItems.findIndex((e) => e.key === currentStep);
        const nextInTimeline = stepsTimelineItems[currIdx + 1];
        const next = nextInTimeline?.key;
        const prevToNext = plantillaDeTramite.pasos_para_tramites.toReversed()
          .find((p) => p.orden < nextInTimeline.orden)?.id;
        if (currentStep !== lastLog?.paso && typeof next === 'number' && prevToNext) {
          nextButtonProps.disabled = !existingValidLogForStep(prevToNext);
        }
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Button {...nextButtonProps}>
            Siguiente
            <RightOutlined />
          </Button>
        );
      }
      const _label = getButtonLabel();
      const faltaExpediente = pasoActual?.tipo_de_paso === tiposDePasosEnum.GIS
          && !getFileExpediente(tipoDeDocumentoExpedienteEnum.GIS);
      if (!_label || (_label !== saveLabelEnum.CONTINUE && !tramiteEnProceso)) {
        if (!faltaExpediente) {
          return <FakeButton />;
        }
      }
      const SaveIcon = nextOrSaveIconsMap[_label];
      nextButtonProps.disabled = !_label;
      nextButtonProps.onClick = form.submit;

      if (_label === saveLabelEnum.START_TRAMITE && hasAlertasRestrictivas) {
        nextButtonProps.onClick = showAlertas;
      }
      if (faltaExpediente) {
        nextButtonProps.onClick = saveGIS;
      }
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Button {...nextButtonProps}>
          {_label || saveLabelEnum.CONTINUE}
          <SaveIcon />
        </Button>
      );
    },
    [
      currentStep, plantillaDeTramite, casoDeRequisitos, statusRequisitos, base64Src,
      tramite, padronSolicitante, padronAfectado, tramiteHasAllVariables, cargosTramite,
      currentStepIsLastStep, historial, cargosSaldados, hasGISInfo, expediente, citas,
    ],
  );

  const patchAfectado = async (soloPropietario = false, _values = form.getFieldsValue()) => {
    setLoading(true);
    const values = soloPropietario ? _values.valuesCambio : _values;
    const _padron = await patchPadronesPrivateMap[tipoPadronAfectado](padronAfectado.id, values);
    if (_padron) {
      const padronAsString = padronToString(padronAfectado, tipoPadronAfectado, true);
      if (pasoActual.tipo_de_paso === tiposDePasosEnum.CAMBIO_DE_PROPIETARIO) {
        await postLog(`Propietario de ${padronAsString} modificado`, true);
      } else {
        await postLog(`${padronAsString} modificado`, true);
      }
      const _padronAfectado = await searchPadron(_padron.id, tipoPadronAfectado);
      setPadronAfectado(_padronAfectado);
      message.info(`${padronAsString} actualizado correctamente`);
    }
    setLoading(false);
  };

  const finishTramite = async () => {
    setLoading(true);
    // De aqui es provisional en lo que se encuentra una mejor manera de solucionar
    let expediente_de_tramite = getFileExpediente(
      tipoDeDocumentoExpedienteEnum.DOCUMENTO,
      pasoActual?.id,
    )?.id;
    if (!expediente_de_tramite) {
      const _expediente = await getExpedientesDeTramites({
        tramite: tramite.id,
        tipo_de_documento_para_expediente: tipoDeDocumentoExpedienteEnum.DOCUMENTO,
      });
      expediente_de_tramite = _expediente[0]?.id;
    }
    // hasta aqui ----------------------------------------------------------
    const values = {
      estados_globales: 4, // Autorizado
      expediente_de_tramite,
    };
    const saved = await patchTramite(tramite.id, values);
    if (saved) {
      await postLog('Trámite finalizado en portal ciudadano');
      const _tramite = {
        ...tramite,
        ...saved,
      };
      setTramite(_tramite);
      if (tipoDeAfectacion === afectacionesEnum.ALTA) {
        const patchValues = { estados_globales: 1 };
        if ([padronesEnum.CONTRIBUYENTE, padronesEnum.EXPEDIENTE_LICENCIA_FUNCIONAMIENTO]
          .includes(tipoPadronAfectado)) {
          patchValues.estado_del_padron = 2; // Activo
        }
        await patchAfectado(false, patchValues);
      }
      return _tramite;
    }
    setLoading(false);
    return null;
  };

  const getAcuseVariables = async (id_reporte) => {
    setLoading(true);
    const src = `${VISIBLE_PREVIEW_ENUM.ACUSE_VARIABLES}_${id_reporte}`;
    if (!base64Src[src]) {
      const values = {
        ID: id_reporte,
        Parametros: [
          {
            Nombre: 'tramite',
            TipoParametro: 'Texto',
            Valor: tramite.id,
          },
          {
            Nombre: 'clave',
            TipoParametro: 'Texto',
            Valor: tramite.id,
          },
        ],
      };
      if (pasoActual) {
        values.Parametros.push({
          Nombre: 'paso',
          TipoParametro: 'Texto',
          Valor: pasoActual.id,
        });
      }
      const [err, _base64] = await postObtenerDocumento(values);
      if (_base64) {
        setBase64({ ...base64Src, [src]: _base64 });
        setVisiblePreview(src);
      } else {
        message.warn(err);
      }
    } else {
      setVisiblePreview(src);
    }
    setLoading(false);
  };

  const stepPropsMap = {
    [stepsEnum.PADRON_SOLICITANTE]: {
      plantillaDeTramite,
      tipoPadronSolicitante,
      setTipoPadronSolicitante,
      padronSolicitante,
      setPadronSolicitante,
      padrones,
      tramite,
      getAdeudo,
    },
    [stepsEnum.PADRON_AFECTADO]: {
      setLoading,
      loading,
      tipoDePadron: tipoPadronAfectado,
      tipoPadronSolicitante,
      padronSolicitante,
      padrones,
      padronAfectado,
      setPadronAfectado,
      plantillaDeTramite,
      tramite,
      getAdeudo,
      hasGISInfo,
      setHasGISInfo,
      screenshot: getFileExpediente(tipoDeDocumentoExpedienteEnum.GIS)?.archivo,
      callback: (attrs) => tramite && setTramite({ ...tramite, attrs }),
    },
    [stepsEnum.REQUISITOS_GENERALES]: {
      tramite,
      requisitosGenerales: plantillaDeTramite?.requisitos_generales,
      setStatusRequisitos,
      loading,
      setLoading,
      setShowMessagesRequisitos,
      showMessagesRequisitos,
    },
    [stepsEnum.CASOS_DE_REQUISITOS]: {
      casos: plantillaDeTramite?.casos,
      tramite,
      setCasoDeRequisitos,
      casoDeRequisitos,
    },
    [stepsEnum.REQUISITOS_DEL_CASO]: {
      tramite,
      casoDeRequisitos,
      setStatusRequisitos,
      setCasoDeRequisitos: () => setTramite({ ...tramite, caso: casoDeRequisitos?.id }),
      loading,
      setLoading,
      showMessagesRequisitos,
      setShowMessagesRequisitos,
    },
    [stepsEnum.VARIABLES]: {
      catalogoDeVariables,
      variables: plantillaDeTramite?.variables,
      disabled: tramiteHasAllVariables,
      complementos,
      valoresDeVariablesDeSelecion,
      onClick: plantillaDeTramite?.id_acuse_variables
        && (() => getAcuseVariables(plantillaDeTramite?.id_acuse_variables)),
      categoriasDeVariables,
      required: true,
    },
    [stepsEnum.GENERAR_CARGOS]: {
      referencia: referenciaPagoNetpay,
      padron: padronAfectado || padronSolicitante,
      solicitante: padronSolicitante,
      tiposDeCargos: plantillaDeTramite?.tipos_de_cargos,
      tiposDeCargosConsulta: plantillaDeTramite?.tipos_de_cargos_consulta,
      id_reporte_acuse: plantillaDeTramite?.id_reporte_acuse,
      cargosTramite,
      getRecibo,
      imprimirOrdenDePago,
      getAcuseTramite,
      catalogoDeVariables,
      user,
      loading,
      setLoading,
      setCargosSaldados,
      cargosSaldados,
      tarifas,
    },
  };

  const logWithLoading = async (..._args) => {
    const logMessageMap = {
      [tiposDePasosEnum.OBLIGACIONES]: 'Finalizada captura de obligaciones',
      [tiposDePasosEnum.EMPRESAS]: 'Finalizada captura de empresas',
      [tiposDePasosEnum.ACTIVIDADES_ECONOMICAS]: 'Finalizada captura de actividades económicas',
      [tiposDePasosEnum.ACTIVIDADES_ECONOMICAS_SAT]: 'Finalizada captura de actividades económicas SAT',
    };
    setLoading(true);
    const args = _args.length ? _args : [logMessageMap[pasoActual.tipo_de_paso], true];
    const log = await postLog(...args);
    setLoading(false);
    return log;
  };

  const getCurrentStepComponentProps = () => {
    if (typeof currentStep === 'string') {
      return stepPropsMap[currentStep];
    }
    if (!pasoActual) return null;
    const paso = pasosDelTramite.find((p) => p.id === pasoActual.id);
    if (!paso) return null;
    const _disabled = existingValidLogForStep(paso.id);
    if (paso.tipo_de_paso === tiposDePasosEnum.VARIABLES) {
      return {
        catalogoDeVariables,
        variables: paso.variables,
        disabled: (pasoActual?.hasAllVariables && existingValidLogForStep(paso.id))
          || !paso.atiende_solicitante,
        complementos,
        valoresDeVariablesDeSelecion,
        onClick: paso.id_de_reporte && (() => getAcuseVariables(paso.id_de_reporte)),
        categoriasDeVariables,
      };
    }
    if (paso.tipo_de_paso === tiposDePasosEnum.GIS) {
      const screenshot = getFileExpediente(tipoDeDocumentoExpedienteEnum.GIS)?.archivo;
      return {
        callback: (attrs) => tramite && setTramite({ ...tramite, attrs }),
        clave_catastral_municipal: padronAfectado?.clave_catastral_municipal,
        setLoading,
        screenshot,
        disabled: existingValidLogForStep(paso.id) && screenshot,
        estados_globales: tramite?.estados_globales,
      };
    }
    if (paso.tipo_de_paso === tiposDePasosEnum.EMISION_DE_DOCUMENTO) {
      const doc = getFileExpediente(tipoDeDocumentoExpedienteEnum.DOCUMENTO, pasoActual?.id);
      return {
        callback: async (_base64) => {
          if (_base64) {
            const file = await dataUrlToFile(_base64, 'documento', 'application/pdf');
            const comment = `${pasoActual.nombre}: documento generado por funcionario`;
            const [savedFile, _log] = await saveFile(existingValidLogForStep(pasoActual.id)
              ? null : comment, { archivo: { file, name: 'documento.pdf' } });
            if (!_log || !savedFile) {
              message.info('Intentelo de nuevo');
              setLoading(false);
              return;
            }
            if (currentStepIsLastStep) {
              const finished = await finishTramite();
              setLoading(false);
              if (!finished) {
                setBase64({ ...base64Src, [`error_documento_${pasoActual.id}`]: 'retry' });
                message.info('Intentelo de nuevo');
              }
            }
          }
        },
        iFrameUrl: doc?.archivo,
        tramite,
        setLoading,
        firmasElectronicas,
        reporteID: pasoActual.id_de_reporte,
        attrs: { ...expedienteToAttrs(expediente), ...tramite?.attrs },
      };
    }
    if (paso.tipo_de_paso === tiposDePasosEnum.REQUISITOS) {
      return {
        tramite,
        requisitosGenerales: paso?.requisitos,
        setStatusRequisitos,
        loading,
        setLoading,
        setShowMessagesRequisitos,
        showMessagesRequisitos,
        disabled: !paso.atiende_solicitante || existingValidLogForStep(paso.id),
        postLog,
      };
    }
    if (paso.tipo_de_paso === tiposDePasosEnum.CARGOS) {
      return {
        tiposDeCargos: paso.tipos_de_cargos,
        referencia: referenciaPagoNetpay,
        padron: padronAfectado || padronSolicitante,
        solicitante: padronSolicitante,
        cargosTramite,
        catalogoDeVariables,
        getRecibo,
        imprimirOrdenDePago,
        setCargosSaldados,
        cargosSaldados,
        setLoading,
        tarifas,
      };
    }
    if (paso.tipo_de_paso === tiposDePasosEnum.FIRMA_ELECTRONICA) {
      return { firmado: pasoActualHasFirma };
    }
    if (paso.tipo_de_paso === tiposDePasosEnum.CARGA_DE_DOCUMENTO) {
      return {
        iFrameUrl: getFileExpediente(tipoDeDocumentoExpedienteEnum.DOCUMENTO, paso.id)?.archivo,
      };
    }
    if (paso.tipo_de_paso === tiposDePasosEnum.ALTA_AFECTADO) {
      return {
        fields: plantillaDeTramite?.configuracion_de_padron
          ?.configuraciones_de_campos_de_padrones,
        tipoDeAfectacion,
        setLoading,
        tipoDePadron: tipoPadronAfectado === plantillaDeTramite
          ?.configuracion_de_padron.padron.id ? tipoPadronAfectado : null,
        tipoDePadronSolicitante: tipoPadronSolicitante,
        padronSolicitante,
        padron: padronAfectado,
        disabled: existingValidLogForStep(paso.id),
      };
    }
    if (paso.tipo_de_paso === tiposDePasosEnum.MODIFICACION_AFECTADO) {
      return {
        fields: paso.configuracion_de_padron.configuraciones_de_campos_de_padrones,
        tipoDeAfectacion: afectacionesEnum.MODIFICACION,
        setLoading,
        tipoDePadron: tipoPadronAfectado,
        tipoDePadronSolicitante: tipoPadronSolicitante,
        padron: padronAfectado,
        disabled: existingValidLogForStep(paso.id),
      };
    }
    if (paso.tipo_de_paso === tiposDePasosEnum.MODIFICACION_SOLICITANTE) {
      return {
        fields: paso.configuracion_de_padron.configuraciones_de_campos_de_padrones,
        tipoDeAfectacion: afectacionesEnum.MODIFICACION,
        setLoading,
        tipoDePadron: tipoPadronSolicitante === paso.configuracion_de_padron.padron.id
          ? tipoPadronSolicitante : null,
        padron: padronSolicitante,
        disabled: existingValidLogForStep(paso.id),
      };
    }
    if (paso.tipo_de_paso === tiposDePasosEnum.CITA) {
      if (!citaPaso) {
        return {
          plantilla: plantillaDeTramite.id,
          setLoading,
        };
      }
      return {
        cita: citaPaso.folio,
      };
    }
    if (paso.tipo_de_paso === tiposDePasosEnum.DIRECCION_PRINCIPAL) {
      return {
        isEditing: !existingValidLogForStep(paso.id),
        propName: direccionPrincipalEnum[tipoPadronAfectado || tipoPadronSolicitante],
        fatherObject: tipoPadronAfectado ? padronAfectado : padronSolicitante,
        setLoading,
      };
    }
    if (paso.tipo_de_paso === tiposDePasosEnum.DIRECCION_SECUNDARIA) {
      return {
        isEditing: !existingValidLogForStep(paso.id),
        propName: direccionSecundariaEnum[tipoPadronAfectado || tipoPadronSolicitante],
        altPropName: direccionPrincipalEnum[tipoPadronAfectado || tipoPadronSolicitante],
        fatherObject: tipoPadronAfectado ? padronAfectado : padronSolicitante,
        setLoading,
      };
    }
    if (paso.tipo_de_paso === tiposDePasosEnum.EMPRESAS) {
      return {
        contribuyente: padronAfectado || padronSolicitante,
        mutable: !_disabled || !hasComentarioDeCapturaFinalizada,
        callback: async (record) => logWithLoading(record.message, false),
        fetchCallback: (_data) => setHasEmpresas(!!_data.count),
      };
    }
    if ([tiposDePasosEnum.ACTIVIDADES_ECONOMICAS,
      tiposDePasosEnum.ACTIVIDADES_ECONOMICAS_SAT].includes(paso.tipo_de_paso)) {
      return {
        mutable: !_disabled || !hasComentarioDeCapturaFinalizada,
        callback: async (record) => logWithLoading(record.message, false),
        fetchCallback: (_data) => setHasActividades(!!_data.length),
        contribuyente: (padronAfectado || padronSolicitante)?.id,
        sat: paso.tipo_de_paso === tiposDePasosEnum.ACTIVIDADES_ECONOMICAS_SAT,
      };
    }
    if (paso.tipo_de_paso === tiposDePasosEnum.OBLIGACIONES) {
      return {
        contribuyente: padronSolicitante?.id,
        contribuyenteRFC: padronSolicitante?.rfc,
        tipoDePadron: tipoPadronAfectado,
        padron: padronAfectado,
        mutable: !_disabled || !hasComentarioDeCapturaFinalizada,
        callback: async (record) => logWithLoading(record.message, false),
        fetchCallback: (_data) => setHasObligaciones(!!_data.length),
      };
    }
    return null;
  };

  const componentsByStepMap = {
    [tiposDePasosEnum.GIS]: ArcGisMap,
    [tiposDePasosEnum.VARIABLES]: Variables,
    [tiposDePasosEnum.EMISION_DE_DOCUMENTO]: Documento,
    [tiposDePasosEnum.REQUISITOS]: RequisitosDelTramite,
    [tiposDePasosEnum.CARGOS]: Cargos,
    [tiposDePasosEnum.FIRMA_ELECTRONICA]: PasoFirma,
    [tiposDePasosEnum.CARGA_DE_DOCUMENTO]: Documento,
    [tiposDePasosEnum.MODIFICACION_SOLICITANTE]: RenderFieldsByConfig,
    [tiposDePasosEnum.MODIFICACION_AFECTADO]: RenderFieldsByConfig,
    [tiposDePasosEnum.ALTA_AFECTADO]: RenderFieldsByConfig,
    [tiposDePasosEnum.VISTA_PADRON]: RenderFieldsByConfig,
    [tiposDePasosEnum.CITA]: citaPaso ? ConsultaCita : DatosCita,
    [tiposDePasosEnum.DIRECCION_PRINCIPAL]: Direccion,
    [tiposDePasosEnum.DIRECCION_SECUNDARIA]: Direccion,
    [tiposDePasosEnum.EMPRESAS]: EmpresasDeContribuyente,
    [tiposDePasosEnum.ACTIVIDADES_ECONOMICAS]: ActividadesEconomicas,
    [tiposDePasosEnum.ACTIVIDADES_ECONOMICAS_SAT]: ActividadesEconomicas,
    [tiposDePasosEnum.OBLIGACIONES]: ObligacionesFiscalesContribuyente,
  };

  const stepComponents = {
    [stepsEnum.PADRON_SOLICITANTE]: PadronSolicitante,
    [stepsEnum.PADRON_AFECTADO]: tipoDeAfectacion !== afectacionesEnum.ALTA || padronAfectado
      ? PadronAfectado : null,
    [stepsEnum.REQUISITOS_GENERALES]: RequisitosDelTramite,
    [stepsEnum.CASOS_DE_REQUISITOS]: CasosDeRequisitos,
    [stepsEnum.REQUISITOS_DEL_CASO]: RequisitosDelTramite,
    [stepsEnum.VARIABLES]: Variables,
    [stepsEnum.GENERAR_CARGOS]: Cargos,
    ...Object.fromEntries(pasosDelTramite.map((paso) => {
      const output = [paso.id];
      if (paso.atiende_solicitante || paso.detalle_visible_para_ciudadano) {
        output[1] = componentsByStepMap[paso.tipo_de_paso];
      }
      return output;
    }) || []),
  };

  useEffect(() => scrollToTop(screens), [currentStep]);

  // auto guardado de captura de gis
  useEffect(() => {
    if (validateGIS && hasGISInfo && currentStep === stepsEnum.PADRON_AFECTADO
      && !getFileExpediente(tipoDeDocumentoExpedienteEnum.GIS)
      && location.pathname.includes('seguimiento') && base64) {
      saveGIS(base64);
    }
  }, [currentStep, hasGISInfo, base64]);

  const saveTramite = async (_padronAfectado = padronAfectado) => {
    setLoading(true);
    const values = {
      plantilla: plantillaDeTramite.id,
      tipo_padron_id: tipoPadronAfectado,
      content_type_a_padron: getContenTypePorTipoDePadron(tipoPadronAfectado),
      object_id_padron: _padronAfectado?.id,
      solicitante_ciudadano: tipoPadronSolicitante === solicitantesEnum.CIUDADANO,
      [tipoPadronSolicitante === solicitantesEnum.CIUDADANO ? 'ciudadano' : 'contribuyente']: padronSolicitante.id,
      tipos_de_registros_de_tramite: 1,
      estados_globales: 2,
      fecha_de_inicio: toMoment(new Date()).format(DATE_FORMAT),
      procedencia: 7,
    };
    if (plantillaDeTramite.casos.length === 1) {
      values.caso = plantillaDeTramite.casos[0].id;
    }
    if (esConsultaDeAdeudo) {
      values.cargos = cargosTramite.map((c) => c.id);
    }
    const _tramite = await postTramite(values);
    if (!_tramite) {
      setLoading(false);
      return;
    }
    navigate(`/tramites/seguimiento/${_tramite.id}`, { state: { currentStep } });
  };

  const saveCargos = async (_tiposDeCargos) => {
    setLoading(true);
    const values = form.getFieldsValue();
    const tiposDeCargosGenerados = cargosTramite.map((c) => c.tipo_de_cargo.id);
    const tiposDeCargosNoGenerados = _tiposDeCargos
      .filter((tc) => !tiposDeCargosGenerados.includes(tc.id));
    const promises = tiposDeCargosNoGenerados.map((tc) => {
      const _cargo = {
        padron: padronAfectado ? tipoPadronAfectado : tipoPadronSolicitante,
        padron_id: (padronAfectado || padronSolicitante).id,
        tipo_de_cargo: tc.id,
        tramite: tramite.id,
        procedencia: 7,
      };
      if (tc.tipo_de_aplicacion === aplicacionesCargosEnum.FIJO) {
        _cargo.importe = tc.importe;
      }
      if (aplicacionesCargosEnum.MULTIPLO === tc.tipo_de_aplicacion) {
        _cargo.importe = 1;
      }
      if (tc.tipo_de_aplicacion === aplicacionesCargosEnum.TARIFA) {
        _cargo.importe = values[tc.id].importe;
      }
      if ([aplicacionesCargosEnum.FORMULA, aplicacionesCargosEnum.SQL_FUNCTION]
        .includes(tc.tipo_de_aplicacion)) {
        const _variables = tc.variables.map((v) => catalogoDeVariables.find((_v) => _v.id === v));
        _cargo.variables = valuesToVariables(values, _variables, tc.id);
      }
      return _cargo;
    });
    const responses = promises.length
      ? await Promise.all(promises.map((e) => postGenerarCargos(e))) : [];
    const cargos = responses.flat(2);
    if (cargos.length) {
      message.info('Cargos generados correctamente');
      await getCargosTramite();
      setBase64({ ...base64Src, [VISIBLE_PREVIEW_ENUM.PAYMENT_ORDER]: null });
      if (pasoActual) {
        await postLog(`Cargos generados por solicitante (${listToString(cargos
          .map((c) => c.id.toString()))})`, false);
      }
    }
    setLoading(false);
  };

  const saveVariablesTramite = async () => {
    setLoading(true);
    const values = form.getFieldsValue();
    const variablesData = valuesToVariablesComplementos(
      values,
      plantillaDeTramite.variables,
      catalogoDeVariables,
      tramite.id,
      valoresDeVariablesDeSelecion,
    );
    const variablesToPost = variablesData
      .filter((vd) => !complementos.some((vt) => vt.variable === vd.variable));
    const responses = await Promise.all(variablesToPost.map((vd) => postComplementoDeTramite(vd)));
    const complementosGenerados = responses.filter((r) => r);
    if (complementosGenerados.length) {
      // const variablesAsString = listToString(complementosGenerados
      //   .map((cv) => catalogoDeVariables.find((v) => v.id === cv.variable).nombre_de_variable));
      // await postLog(`Variables capturadas: ${variablesAsString}`);
      setComplementos([...complementos, ...complementosGenerados]);
    }
    setLoading(false);
  };

  const saveVariablesPaso = async () => {
    setLoading(true);
    const values = form.getFieldsValue();
    const variablesData = valuesToVariablesComplementos(
      values,
      pasoActual.variables,
      catalogoDeVariables,
      tramite.id,
      valoresDeVariablesDeSelecion,
    );
    const variablesToPost = variablesData
      .filter((vd) => !complementos.some((vt) => vt.variable === vd.variable));
    const variablesToPatch = variablesData.map((vd) => {
      const match = complementos.find((vt) => vt.variable === vd.variable);
      if (match) {
        return { ...vd, id: match.id };
      }
      return null;
    }).filter((vd) => vd);
    const responses = await Promise.all([
      ...variablesToPost.map((vd) => postComplementoDeTramite(vd)),
      ...variablesToPatch.map(({ id, ...vd }) => patchComplementoDeTramite(id, vd)),
    ]);
    const _complementos = responses.filter((r) => r);
    if (_complementos.length) {
      const variablesAsString = listToString(_complementos.map((cv) => catalogoDeVariables
        .find((v) => v.id === cv.variable).nombre_de_variable));
      await postLog(`Variables capturadas: ${variablesAsString}`, responses.every((r) => r));
      setComplementos(_complementos);
    }
    setLoading(false);
  };

  const firmadoAutomatico = async () => {
    setLoading(true);
    const values = { paso: pasoActual.id };
    if (pasoActual.firma_automatica) {
      [values.usuario] = pasoActual.usuarios;
    }
    const [_logValues] = generateLogValues(pasoActual, pasosDelTramite, true);
    const logValues = {
      ..._logValues,
      tramite: tramite.id,
      comentario: 'Firmado automáticamente por solicitante',
    };
    const updated = await patchFirmarTramite(tramite, values, logValues);
    if (updated) {
      await getHistorial();
      message.info('Trámite firmado correctamente');
      setTramite({
        ...tramite,
        firma_electronica: updated.firma_electronica,
      });
    }
    setLoading(false);
  };

  const saveCita = async () => {
    setLoading(true);
    const formValues = form.getFieldsValue();
    const [fecha_cita, horario] = formValues.horario.split(' ');
    const [horario_inicio, horario_fin] = horario.split('-');
    const values = {
      plantilla_de_tramite: plantillaDeTramite.id,
      oficina: formValues.oficina,
      entidad: ID_ENTIDAD,
      estado_de_cita: 1, // Programada
      fecha_alta: toMoment(new Date()),
      fecha_cita: toMoment(fecha_cita, [DATE_FRONTEND_FORMAT]),
      horario_inicio,
      horario_fin,
      paso: pasoActual.id,
      tramite: tramite.id,
      ventanilla: null,
      [tipoPadronSolicitante === padronesEnum.CIUDADANO
        ? 'ciudadano' : 'contribuyente']: padronSolicitante.id,
    };
    const _cita = await postCita(values);
    if (_cita) {
      postLog(`Cita con folio ${_cita.folio} programada por solicitante`);
      await fetchCitas();
    }
    setLoading(false);
  };

  const extraPropsAltaPadron = {
    [padronesEnum.VEHICULO]: {
      es_propietario_ciudadano: tipoPadronSolicitante === solicitantesEnum.CIUDADANO,
      [tipoPadronSolicitante === solicitantesEnum.CIUDADANO
        ? 'propietario' : 'contribuyente_propietario']: padronSolicitante?.id,
    },
    [padronesEnum.CONTRIBUYENTE]: {
      estado_del_padron: 1, // Ingresado,
    },
  };

  const iniciarTramite = async (_padronAfectado = padronAfectado) => {
    setLoading(true);
    const values = {
      plantilla: plantillaDeTramite.id,
      tipo_padron_id: tipoPadronAfectado,
      content_type_a_padron: getContenTypePorTipoDePadron(tipoPadronAfectado),
      object_id_padron: _padronAfectado?.id,
      solicitante_ciudadano: tipoPadronSolicitante === solicitantesEnum.CIUDADANO,
      [tipoPadronSolicitante === solicitantesEnum.CIUDADANO ? 'ciudadano' : 'contribuyente']: padronSolicitante.id,
      tipos_de_registros_de_tramite: 1,
      estados_globales: 2,
      fecha_de_inicio: toMoment(new Date()).format(DATE_FORMAT),
      procedencia: 7,
    };
    if (esConsultaDeAdeudo) {
      values.cargos = cargosTramite.map((c) => c.id);
    }
    if (plantillaDeTramite.casos.length === 1) {
      values.caso = plantillaDeTramite.casos[0].id;
    }
    await postTramite(values);
    setLoading(false);
  };

  const postPadron = async () => {
    setLoading(true);
    const labelTipoDePadronAfectado = padrones
      .find((e) => e.id === tipoPadronAfectado)?.descripcion;
    const values = {
      ...extraPropsAltaPadron[tipoPadronAfectado],
      estados_globales: ESTADOS_GLOBALES_MAP.EN_PROCESO,
      ...form.getFieldsValue(),
    };
    const _padron = await postPadronesMap[tipoPadronAfectado](values);
    if (_padron) {
      if (pasoActual) {
        const valuesPatchTramite = { object_id_padron: _padron.id };
        const _tramite = await patchTramite(tramite.id, valuesPatchTramite);
        setTramite(_tramite);
      }
      const _padronAfectado = await searchPadron(_padron.id, tipoPadronAfectado);
      setPadronAfectado(_padronAfectado);
      const padronAsString = padronToString(padronAfectado, tipoPadronAfectado, true);
      message.info(`${labelTipoDePadronAfectado} registrado correctamente`);
      await postLog(`${padronAsString} agregado`, true);
      if (!pasoActual) {
        await iniciarTramite(_padron);
      }
    }
    setLoading(false);
  };

  const patchSolicitante = async () => {
    setLoading(true);
    const values = form.getFieldsValue();
    // eslint-disable-next-line max-len
    const _padron = await patchPadronesPrivateMap[tipoPadronSolicitante](padronSolicitante.id, values);
    if (_padron) {
      const padronAsString = padronToString(padronSolicitante, tipoPadronSolicitante, true);
      await postLog(`${padronAsString} modificado`, true);
      setPadronSolicitante(_padron);
      message.info(`${padronAsString} actualizado correctamente`);
    }
    setLoading(false);
  };

  const saveAddress = async () => {
    setLoading(true);
    const _values = formatSent(form.getFieldsValue(), { clean: true });
    const stepProps = getCurrentStepComponentProps();
    const values = { [stepProps.propName]: { ..._values, codigo_postal: _values.colonia } };
    const tipoPadron = tipoPadronAfectado || tipoPadronSolicitante;
    const saved = await patchPadronesPrivateMap[tipoPadron](stepProps.fatherObject.id, values);
    if (saved) {
      const action = (padronAfectado || padronSolicitante)[stepProps.propName] ? 'agregada' : 'modificada';
      const _message = `${stepProps.propName.replace('direccion_', 'dirección_').replace(/_/g, ' ')} ${action}`.toUpperCase();
      await postLog(_message, true);
      message.info(_message);
      (tipoPadronAfectado ? setPadronAfectado : setPadronSolicitante)(saved);
    }
    setLoading(false);
  };

  const onFinish = async () => {
    if (DEV) _logger('[values]', form.getFieldsValue());
    try {
      await form.validateFields();
    } catch {
      return null;
    }
    if (typeof currentStep === 'string') {
      if (currentStep === stepsEnum.PADRON_SOLICITANTE) {
        if (padronSolicitante) {
          if (tipoPadronAfectado && !altaEnPaso) {
            return nextStep();
          }
          return saveTramite();
        }
      }
      if (currentStep === stepsEnum.PADRON_AFECTADO) {
        if (tipoDeAfectacion === afectacionesEnum.ALTA) {
          // return postPadron();
        }
        return saveTramite();
      }
      if (currentStep === stepsEnum.VARIABLES) {
        return saveVariablesTramite();
      }
      if (currentStep === stepsEnum.REQUISITOS_GENERALES
          || currentStep === stepsEnum.REQUISITOS_DEL_CASO) {
        return setShowMessagesRequisitos(true);
      }
      if (currentStep === stepsEnum.GENERAR_CARGOS) {
        return saveCargos(plantillaDeTramite.tipos_de_cargos);
      }
    }
    if (!pasoActual) return null;
    const { tipo_de_paso } = pasoActual;
    if (tipo_de_paso === tiposDePasosEnum.REQUISITOS) return setShowMessagesRequisitos(true);
    if (tipo_de_paso === tiposDePasosEnum.VARIABLES) return saveVariablesPaso();
    if (tipo_de_paso === tiposDePasosEnum.CARGOS) return saveCargos(pasoActual.tipos_de_cargos);
    if (tipo_de_paso === tiposDePasosEnum.FIRMA_ELECTRONICA) return firmadoAutomatico();
    if (tipo_de_paso === tiposDePasosEnum.GIS) return saveGIS();
    if (tipo_de_paso === tiposDePasosEnum.CITA) return saveCita();
    if (tipo_de_paso === tiposDePasosEnum.ALTA_AFECTADO) return postPadron();
    if (tipo_de_paso === tiposDePasosEnum.MODIFICACION_SOLICITANTE) return patchSolicitante();
    if ([tiposDePasosEnum.DIRECCION_PRINCIPAL, tiposDePasosEnum.DIRECCION_SECUNDARIA]
      .includes(tipo_de_paso)) return saveAddress();
    if ([tiposDePasosEnum.ACTIVIDADES_ECONOMICAS, tiposDePasosEnum.ACTIVIDADES_ECONOMICAS_SAT,
      tiposDePasosEnum.OBLIGACIONES, tiposDePasosEnum.EMPRESAS].includes(tipo_de_paso)) {
      return logWithLoading();
    }
    return null;
  };

  const titlePadronSolicitante = padronSolicitante && (
    <Text $accent $uppercase strong>
      PADRÓN SOLICITANTE
      <br />
      {padronToString(padronSolicitante, tipoPadronSolicitante, true)}
    </Text>
  );

  const titlePadronAfectado = padronAfectado && (
    <Text $accent $uppercase strong>
      PADRÓN SECUNDARIO
      <br />
      {padronToString(padronAfectado, tipoPadronAfectado, true)}
    </Text>
  );

  useEffect(() => {
    if (lastLog && plantillaDeTramite) {
      const { pasos_para_tramites } = plantillaDeTramite;
      const paso = pasos_para_tramites.find((p) => p.id === lastLog.paso);
      const pasoAMandar = pasos_para_tramites.find((p) => p.id === lastLog.paso_a_mandar);
      if (pasoAMandar.orden < paso.orden && !lastLog.comentario?.es_comentario_interno) {
        notification.destroy();
        notification.info({
          message: <Text $bold>Comentarios</Text>,
          description: (
            <Text style={{ fontSize: 14 }}>
              Un funcionario regresó el trámite al paso
              {' '}
              <Text $bold $xs>
                {pasoAMandar.nombre}
              </Text>
              , avance hasta dicho paso y presione en el ícono
              <Text $accent style={{ fontSize: 16 }}>
                <FieldTimeOutlined style={{ padding: '0 5px' }} />
              </Text>
              al lado del folio de trámite para revisar el historial de seguimiento,
              comentarios y archivos adjuntos de haberlos.
            </Text>
          ),
          duration: 0,
          closeIcon: <CloseCircleOutlined />,
        });
        setHasLogsToSee(true);
      }
    }
  }, [lastLog, plantillaDeTramite]);

  if (!plantillaDeTramite) {
    if (errorMessages.length) {
      return (
        <Result
          status="warning"
          title={errorMessages[0]}
          extra={(
            <>
              Nos pondremos en contacto con soporte para solucionarlo lo antes posible
              <br />
              {errorMessages.length && (!PROD || DEV) && (
                <>
                  <b>TEST ONLY:</b>
                  {' '}
                  {errorMessages.filter((e, idx) => idx !== 0).join(', ')}
                  <br />
                </>
              )}
              {(!/incorrecta/.test(errorMessages[1]) || (!PROD || DEV)) && (
                <Button disabled={loading} onClick={fetchTramiteOPlantilla}>
                  <Text>
                    Reintentar
                    <ReloadOutlined />
                  </Text>
                </Button>
              )}
            </>
          )}
        />
      );
    }
    return (
      <Row style={{ width: '100%', height: '100%' }} justify="center" align="middle">
        <Spin />
      </Row>
    );
  }

  const isCancelable = () => {
    if (!tramiteEnProceso) return false;
    const tiposPasosModificaciones = [
      tiposDePasosEnum.MODIFICACION_AFECTADO,
      tiposDePasosEnum.MODIFICACION_SOLICITANTE,
      tiposDePasosEnum.CAMBIO_DE_PROPIETARIO,
    ];
    const pasosModificaciones = plantillaDeTramite.pasos_para_tramites
      .filter((p) => tiposPasosModificaciones.includes(p.tipo_de_paso));
    // No hay log autogenerados para ningun paso de modificacion, ni logs que permitieron avanzar
    return pasosModificaciones.every((p) => !historial.some((l) => {
      const paso_a_mandar = plantillaDeTramite.pasos_para_tramites.find((_p) => _p.id === l.paso);
      return l.paso === p.id
        && (paso_a_mandar.orden > p.orden || /\[autogenerado\]/i.test(l.comentario?.comentario));
    }));
  };

  const CurrentStepComponent = stepComponents[currentStep];
  const _currentStepComponentProps = getCurrentStepComponentProps();
  const currentStepComponentProps = {
    ..._currentStepComponentProps,
    ...(_currentStepComponentProps ? {
      disabled: !tramiteEnProceso || !!_currentStepComponentProps?.disabled,
      mutable: !!_currentStepComponentProps?.mutable && tramiteEnProceso,
      estados_globales: tramite?.estados_globales,
    } : {}),
  };
  const showInstructions = pasoActual && !!pasoActual.instrucciones_portal_ciudadano
    && pasoActual.tipo_de_paso !== tiposDePasosEnum.CARGOS;

  const historialesPasoActual = historial
    .filter((h) => (h.paso === pasoActual?.id || h.paso_a_mandar === pasoActual?.id)
    && (!h.comentario || !h.comentario.es_comentario_interno));

  return (
    <Container>
      <Spin spinning={loading || loadingAdeudo} tip={loadingAdeudo || 'Cargando...'}>
        <>
          {![stepsEnum.PADRON_SOLICITANTE, stepsEnum.PADRON_AFECTADO].includes(currentStep) && (
            <Row justify="space-between">
              <Title level={3} $accent style={{ width: '100%' }}>
                <Text style={{ margin: 0, fontSize: 'inherit' }} $accent>
                  {plantillaDeTramite.nombre}
                </Text>
                {tramite && (
                  <>
                    {screens.xs ? (<br />) : ' — '}
                    <Text style={{ margin: 0, fontSize: 'inherit' }} $accent copyable>
                      {tramite.folio}
                    </Text>
                    {tramite.estados_globales !== 2 && (
                      <>
                        {' - '}
                        {tramite.estados_globales === 4 && (
                          <Text style={{ margin: 0, fontSize: 'inherit' }} type="success">(FINALIZADO)</Text>
                        )}
                        {tramite.estados_globales === 5 && (
                          <Text style={{ margin: 0, fontSize: 'inherit' }} type="danger">(CANCELADO)</Text>
                        )}
                      </>
                    )}
                    {!!pasoActual && (
                      <React.Fragment key={currentStep}>
                        {' — '}
                        <Tooltip
                          title={<Text $accent>PRESIONE PARA VER HISTORIAL DE SEGUIMIENTO</Text>}
                          overlayStyle={{ maxWidth: 700 }}
                        >
                          <FieldTimeOutlined
                            style={{ cursor: 'pointer' }}
                            onClick={() => setVisibleSeguimiento(true)}
                            className={currentStep === pasoActual.id && hasLogsToSee ? 'excited-icon' : null}
                          />
                        </Tooltip>
                      </React.Fragment>
                    )}
                    {isCancelable() && (
                      <Button
                        onClick={() => setVisibleCancelar(true)}
                        type="link"
                        style={{ float: 'right' }}
                        danger
                      >
                        <StopOutlined />
                        Cancelar
                      </Button>
                    )}
                  </>
                )}
              </Title>
              {![stepsEnum.PADRON_SOLICITANTE, stepsEnum.PADRON_AFECTADO].includes(currentStep) && (
                <Title
                  level={3}
                  style={{ marginTop: 0, minHeight: 32, width: '100%' }}
                  $accent
                >
                  {padronSolicitante && (
                    <Tooltip
                      title={titlePadronSolicitante}
                      overlayStyle={{ maxWidth: 700 }}
                      placement="bottom"
                    >
                      <Text style={{ margin: 0, fontSize: 'inherit' }} $accent>
                        {getPadronKey(padronSolicitante, tipoPadronSolicitante)}
                        {' '}
                        <InfoCircleOutlined />
                      </Text>
                    </Tooltip>
                  )}
                  {padronAfectado && (
                    <>
                      {' — '}
                      <Tooltip
                        title={titlePadronAfectado}
                        overlayStyle={{ maxWidth: 700 }}
                        placement="bottom"
                      >
                        <Text style={{ margin: 0, fontSize: 'inherit' }} $accent>
                          {getPadronKey(padronAfectado, tipoPadronAfectado)}
                          {' '}
                          <InfoCircleOutlined />
                        </Text>
                      </Tooltip>
                    </>
                  )}
                </Title>
              )}
            </Row>
          )}
          <StepsContent>
            <React.Suspense fallback={<span />}>
              <Form
                form={form}
                layout={tiposDePasosEnum.CITA && citaPaso ? 'horizontal' : 'vertical'}
                // preserve={false}
                onFinish={tramiteEnProceso ? onFinish : null}
                onFinishFailed={(errorInfo) => DEV && _logger('[onFinishFailed]', errorInfo)}
                scrollToFirstError
              >
                <Row
                  gutter={10}
                  style={{ height: '100%', paddingBottom: 20 }}
                >
                  {screens.xs && (
                    <Col span={24}>
                      <Title level={3} style={{ fontWeight: 'bold ' }}>
                        {currentStepTitle}
                      </Title>
                    </Col>
                  )}
                  {showInstructions && (
                    <Instrucciones instrucciones={pasoActual?.instrucciones_portal_ciudadano} />
                  )}
                  {!!CurrentStepComponent && (
                    <React.Fragment key={currentStep}>
                      <CurrentStepComponent
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...currentStepComponentProps}
                        disabled={!tramiteEnProceso || !!currentStepComponentProps?.disabled}
                      />
                    </React.Fragment>
                  )}
                </Row>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Form>
            </React.Suspense>
            <Row justify="space-between" align="middle">
              {currentStep !== stepsEnum.PADRON_SOLICITANTE ? (backButton) : (<FakeButton />)}
              {nextOrSaveButton}
            </Row>
            {!screens.xs && (
              <StepsTimeline
                currentStepTimeline={currentStep}
                stepsTimelineItems={stepsTimelineItems}
                pasoActivo={lastLog?.paso_a_mandar}
                setCurrentStep={setCurrentStep}
                stepToInLastLog={stepToInLastLog}
                historial={historial}
              />
            )}
          </StepsContent>
        </>
        <Previewer
          visible={Boolean(base64Src[visiblePreview])}
          onCancel={() => setVisiblePreview()}
          base64={isPDFBase64(base64Src[visiblePreview])
            ? base64Src[visiblePreview] : null}
          iFrameUrl={!isPDFBase64(base64Src[visiblePreview])
            ? base64Src[visiblePreview] : null}
        />
      </Spin>
      <Previewer
        visible={visiblePreviewRecibo}
        base64={recibo?.base64}
        onCancel={onCancelPreviewRecibo}
      />
      <ModalCancelarTramite
        tramite={tramite?.id}
        setVisible={setVisibleCancelar}
        visible={visibleCancelar}
        cancelarPadron={tipoDeAfectacion === afectacionesEnum.ALTA}
        callback={() => navigate('/mi-cuenta')}
        tipoDePadron={padrones.find((e) => e.id === tipoPadronAfectado)?.descripcion}
        cargos={cargosTramite}
      />
      <ModalHistorial
        visible={visibleSeguimiento}
        setVisibleSeguimiento={setVisibleSeguimiento}
        historialesPasoActual={historialesPasoActual}
        plantillaDeTramite={plantillaDeTramite}
        pasoActual={pasoActual}
        setVisiblePreview={setVisiblePreview}
        setBase64={setBase64}
      />
    </Container>
  );
};

const Container = styled.div`
  min-height: 100%;
  overflow: hidden scroll;
  padding: 20px 30px;
  .ant-form-item-label label,
  .ant-select-selection-item,
  .ant-input {
    font-size: 16px;
  }
  .ant-form-item-control-input-content,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
  }

  .ant-steps {
    .ant-steps-item-container>.ant-steps-item-icon {
      border-color: ${COLORS.cream};
      background: ${COLORS.cream};
      .ant-steps-icon {
        color: ${COLORS.white};
      }
    }
    .ant-steps-item-description {
      font-size: 12px;
      font-weight: bold;
    }
    .ant-steps-item-finish .ant-steps-icon-dot,
    .ant-steps-item-process .ant-steps-icon-dot {
      background: ${COLORS.accent};
      border-color: ${COLORS.accent};
    }
    .ant-steps-item-process .ant-steps-icon-dot {
      width: 14px;
      height: 14px;
      bottom: 2px !important;
      left: -2px !important;
    }
  }

  .ant-steps-item-tail:after {
    background-color: ${COLORS.text} !important;
    height: 3px;
  }

  .ant-result-icon span, .ant-result-title {
    color: ${({ theme }) => theme.primaryText} !important;
  }

  @media only screen and (max-width: 576px) {
    padding: 12px;
  }
`;

const StyledButton = styled(Button)`
  height: 80px;
  background: ${({ $selected }) => ($selected ? COLORS.accent : COLORS.cream2)} !important;
  color: ${({ $selected }) => ($selected ? COLORS.white : COLORS.text)} !important;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span:not(.anticon) {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    text-transform: uppercase;
    margin: 0;
    font-weight: bold;
    width: calc(100% - 40px);
    padding-right: 10px;
    text-align: left;
  }

  .anticon, .ant-btn-link {
    font-size: 20px;
  }

  .ant-btn-link {
    border: none;
    padding: 0 0 0 10px;
  }

  &.disabled .ant-btn-link * {
    color: rgba(0, 0, 0, 0.25) !important;
  }

  :not(.disabled) {
    * {
      color: ${({ theme, $selected }) => ($selected ? theme.primaryText : theme.color)} !important;
    }
    :focus, :hover, :active {
      background: ${({ theme }) => theme.backgroundColor};
      opacity: 0.8;
      color: ${({ theme, $selected }) => ($selected ? theme.primaryText : theme.color)} !important;
    }
  }
`;

const StepsContent = styled.div`
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  padding: 20px 20px 10px;
  .scrollable {
    min-height: calc(100vh - 450px);
    overflow: hidden scroll;
    margin-bottom: 10px
  }
  .ant-form {
    min-height: calc(100vh - 450px);
  }
  iframe {
    min-height: 50vh;
  }

  @media only screen and (max-width: 576px) {
    padding: 12px;
  }
`;

const FakeButton = styled.span`
  width: 135px;
`;

const Tooltip = styled(AntTooltip).attrs({ color: '#FAFAFA', overlayStyle: { maxWidth: 700 } })``;

export default GPM;
