/**
 * GET Empresas
 * @param {Object} params
 * @returns {Promise<object|object[]>}
 */
const getAllPages = async (getter, params = null) => {
  const data = await getter(params);
  if (!data?.next) return data.results || data;
  const page = new URL(data.next).searchParams.get('page');
  const _data = await getter({ ...params, page });
  return data.results.concat(_data);
};

export default getAllPages;
