import React from 'react';
import PropTypes from 'prop-types';
import { Select as AntSelect } from 'antd';
import { normalizeValue } from 'utils/formatters';

const filterOption = (input, option) => {
  const normalzedInput = normalizeValue(input);
  const normalzedOption = normalizeValue(option.label);
  return normalzedOption.indexOf(normalzedInput) >= 0;
};

const mapOptions = (options, getValue, getLabel) => options.map((e) => ({
  disabled: !!e.disabled,
  value: getValue(e),
  label: getLabel(e),
}));

const getTrueFalseOps = (ops) => [{ label: ops?.[0] || 'Sí', value: true }, { label: ops?.[1] || 'No', value: false }];

const Select = ({
  options,
  disabled,
  getValue,
  getLabel,
  allowClear,
  showSearch,
  filterOption: _filterOption,
  trueFalse,
  ...props
}) => (
  <AntSelect
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    showSearch={showSearch}
    showArrow={!disabled}
    options={trueFalse ? getTrueFalseOps(options) : mapOptions(options, getValue, getLabel)}
    disabled={disabled}
    filterOption={showSearch && (_filterOption || filterOption)}
    optionFilterProp="children"
    notFoundContent={options.length ? 'No hay coincidencias' : 'No hay datos'}
    allowClear={allowClear}
  />
);

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  getLabel: PropTypes.func,
  allowClear: PropTypes.bool,
  showSearch: PropTypes.bool,
  filterOption: PropTypes.func,
  trueFalse: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
};

Select.defaultProps = {
  options: [],
  disabled: false,
  allowClear: true,
  getValue: (e) => e.id,
  getLabel: (e) => e.descripcion,
  showSearch: true,
  filterOption: null,
  trueFalse: false,
};

export default Select;
