/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import {
  Row,
  Col,
  Input,
  Form,
  message,
  Select,
  Card,
} from 'antd';
import _logger from 'utils/logger';
import { getRecibo, postFacturar } from 'api/recaudacion';
import { getUsosDeCFDI } from 'api/catalogos';
import { RFC_PATTERN } from 'utils/patterns';
import {
  COLORS,
  Title,
  Text,
  Button,
} from 'components';
import { getRegimenesFiscales } from 'api/padrones';
import { noSpaces, toUpper } from 'utils/formatters';
import {
  AuditOutlined,
  BarcodeOutlined,
  CloseOutlined,
  FilePdfOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { ID_ENTIDAD, RECEIPT_LABEL } from 'utils/env';
import { useDispatch, useSelector } from 'react-redux';
import { selectMetodosDePago } from 'store/catalogos';
import { getMetodosDePago } from 'api/configuracion';
import useQuery from 'hooks/useQuery';
import { extra, getRoute } from 'components/Shortcuts';
import NavigationCard from 'views/Payments/components/NavigationCard';
import ModalConfirm from 'components/ModalConfirm';

const Facturacion = () => {
  const metodosDePago = useSelector(selectMetodosDePago);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formFactura] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [recibo, setRecibo] = useState(null);
  const [visible, setVisible] = useState(false);
  const [usos, setUsos] = useState([]);
  const [regimentesFiscales, setRegimentesFiscales] = useState([]);
  const [_ref, _f] = useQuery(['_ref', '_f']);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const debounce = useRef(null);

  const rules = {
    correo: [{ required: true }, { type: 'email' }],
    required: [{ required: true }],
    rfc: [{ pattern: RFC_PATTERN, message: ' Ingrese un RFC valido.' }],
  };

  useEffect(() => {
    if (_ref && _f) {
      form.setFieldsValue({
        referencia_de_seguridad: _ref,
        folio: _f,
      });
      setTimeout(() => form.submit());
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const [
        _usos,
        _regimenes,
      ] = await Promise.all([
        getUsosDeCFDI(),
        getRegimenesFiscales(),
        getMetodosDePago(dispatch),
      ]);
      if (mounted) {
        setUsos(_usos);
        setRegimentesFiscales(_regimenes);
      }
    };
    fetchData();
    return () => { mounted = false; };
  }, []);

  const handleSearchRecibo = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const params = form.getFieldsValue();
      const keys = Object.keys(params);
      const validProps = keys.filter((prop) => params[prop]);
      if (validProps.length !== 1) {
        const [_recibo] = await getRecibo(params);
        if (_recibo?.estados_globales !== 5
          || _recibo?.xml_archivo
          || _recibo?.pdf_de_rfc) {
          if (_recibo) {
            setRecibo(_recibo);
          } else {
            message.info(`No se encontró un ${RECEIPT_LABEL} con ese Folio.`);
          }
        } else if (_recibo?.estados_globales === 5) {
          message.info(`No se puede facturar un ${RECEIPT_LABEL} cancelado`);
        } else {
          message.info(`El ${RECEIPT_LABEL} ya esta facturado`);
        }
      } else {
        message.warn('Favor de llenar al menos un campo más');
      }
      setLoading(false);
    } catch (error) {
      _logger(error);
      setLoading(false);
    }
  };
  const CardRecibo = () => (
    <Card title={(
      <Title level={3}>
        Facturación
      </Title>
    )}
    >
      <CardHeader>
        <Row justify="center">
          <Title level={3} $capitalize>
            Información de
            {' '}
            {RECEIPT_LABEL}
            {' '}
            a facturar
          </Title>
        </Row>
      </CardHeader>
      <br />
      <Form
        form={form}
        layout="vertical"
        onFinish={() => handleSearchRecibo()}
      >
        <Row justify="center" gutter={10}>
          <Col xs={24} sm={24} md={8}>
            <Form.Item label="Transacción" name="id" normalize={toUpper}>
              <Input
                disabled={loading}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="Folio de Recibo"
              name="folio"
              normalize={toUpper}
              // tooltip="Ubicado en la parte superior de tu Ticket"
            >
              <Input
                disabled={loading}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="Referencia de impresión"
              name="referencia_de_seguridad"
              tooltip="Referencia del Ticket"
              rules={rules.required}
            >
              <Input
                disabled={loading}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <SubmitButton
              htmlType="submit"
              loading={loading}
            >
              <Text>
                Consultar
                {' '}
                {RECEIPT_LABEL}
              </Text>
              <FileTextOutlined />
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    </Card>
  );

  const CardReciboInfo = () => (
    <Card title={(
      <Title level={3}>
        Información del
        {' '}
        {RECEIPT_LABEL}
      </Title>
    )}
    >
      <Row justify="center">
        <Col span={24}>
          <Row gutter={[10, 10]}>
            <StyledCol xs={24} sm={24} md={12}>
              <Col span={24}>
                <Title level={4}>
                  Folio del
                  {' '}
                  {RECEIPT_LABEL}
                </Title>
              </Col>
              <Col span={24}>
                <Text>
                  {recibo?.folio}
                </Text>
              </Col>
            </StyledCol>
            <StyledCol xs={24} sm={24} md={12}>
              <Col span={24}>
                <Title level={4}>
                  Folio de Facturación
                </Title>
              </Col>
              <Col span={24}>
                <Text>
                  {recibo?.folio_de_facturacion}
                </Text>
              </Col>
            </StyledCol>
            <StyledCol xs={24} sm={24} md={12}>
              <Col span={24}>
                <Title level={4}>
                  Fecha
                </Title>
              </Col>
              <Col span={24}>
                <Text>
                  {recibo?.fecha_de_creacion}
                </Text>
              </Col>
            </StyledCol>
            <StyledCol xs={24} sm={24} md={12}>
              <Col span={24}>
                <Title level={4}>
                  Importe Total
                </Title>
              </Col>
              <Col span={24}>
                <Text>
                  {recibo?.importe_total}
                </Text>
              </Col>
            </StyledCol>
          </Row>
          <br />
        </Col>
      </Row>
      {!(recibo?.pdf_de_rfc || recibo?.xml_archivo) && (
        <Row justify="center">
          <SubmitButton
            size="large"
            onClick={() => setVisible(true)}
            loading={loading}
          >
            <Text>Ingresar Información fiscal</Text>
            <AuditOutlined />
          </SubmitButton>
        </Row>
      )}
      <br />
      {(recibo?.pdf_de_rfc || recibo?.xml_archivo) && (
        <Row justify="space-between" gutter={[10, 10]}>
          <Col xs={24} sm={24} md={12}>
            <SubmitButton onClick={() => window.open(recibo?.pdf_de_rfc, '_blank')}>
              <Text>Ver factura</Text>
              <FilePdfOutlined />
            </SubmitButton>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <SubmitButton onClick={() => window.open(recibo?.xml_archivo, '_blank')}>
              <Text>Ver XML</Text>
              <BarcodeOutlined />
            </SubmitButton>
          </Col>
        </Row>
      )}
    </Card>
  );

  const facturar = async () => {
    try {
      setLoading(true);
      await formFactura.validateFields();
      const values = formFactura.getFieldsValue();
      values.folio = recibo.folio;
      values.entidad = ID_ENTIDAD;
      values.fecha_de_pago = recibo.fecha_de_creacion;
      const factura = await postFacturar(values);
      if (!Object.hasOwn(factura, 'error')) {
        const [_recibo] = await getRecibo({ folio: recibo.folio });
        setRecibo(_recibo);
        setVisible(false);
        setLoading(false);
      } else {
        message.warn(factura.error);
        setLoading(false);
      }
    } catch (err) {
      _logger(err);
    }
  };

  const debouncedFacturar = () => {
    if (debounce.current) {
      clearTimeout(debounce.current);
    }
    debounce.current = setTimeout(() => {
      facturar();
    }, 500);
  };

  const Formulario = () => (
    <Card title={(
      <Title level={3}>
        Información Fiscal
      </Title>
    )}
    >
      <Row justify="center">
        <Col span={24}>
          <Form
            form={formFactura}
            layout="vertical"
            onFinish={() => setIsVisibleModal(true)}
            scrollToFirstError
          >
            <Row gutter={10}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="rfc"
                  label="RFC"
                  rules={[...rules.rfc, { required: true }]}
                  normalize={(value) => noSpaces(value.toUpperCase())}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="codigo_postal"
                  label="Código Postal"
                  rules={[{ required: true }]}
                >
                  <Input disabled={loading} maxLength={5} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="razon_social"
                  label="Razón Social"
                  rules={[{ required: true }]}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="email"
                  label="Correo Electrónico"
                  rules={rules.correo}
                >
                  <Input disabled={loading} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="uso"
                  rules={[{ required: true }]}
                  label="Uso de la Factura"
                >
                  <Select options={usos} disabled={loading} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="regimen_fiscal"
                  rules={[{ required: true }]}
                  label="Régimen Fiscal"
                >
                  <Select options={regimentesFiscales} disabled={loading} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="metodo_de_pago"
                  rules={[{ required: true }]}
                  label="Metodo de Pago"
                >
                  <Select options={metodosDePago} disabled={loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <SubmitButton htmlType="submit" disabled={loading}>
                <Text>Facturar a</Text>
                <FileTextOutlined />
              </SubmitButton>
            </Row>
          </Form>
        </Col>
      </Row>

    </Card>
  );

  const navCard = extra().map((e) => e).filter((f) => f.id === 'receiptByReference');

  return (
    <>
      {(!recibo && !visible) && (<CardRecibo />)}
      {(recibo && !visible) && (<CardReciboInfo />)}
      {(recibo && visible) && (<Formulario />)}
      {recibo && (
        <>
          <br />
          <br />
          <Row justify="center">
            <Col>
              <ExitButton
                disabled={loading}
                onClick={() => {
                  setRecibo(null);
                  form.resetFields();
                  formFactura.resetFields();
                  setVisible(false);
                }}
              >
                <Text>Salir</Text>
                <CloseOutlined />
              </ExitButton>
            </Col>
          </Row>
        </>
      )}
      <br />
      <Row gutter={[10, 10]} justify="center">
        {navCard.map((e) => (
          <NavigationCard
            text={e.nombre}
            to={getRoute(e)}
            type="banner"
            bannerBody={e.bannerBody}
            buttonLabel={e.buttonLabel}
            key={e.id}
          />
        ))}
      </Row>
      <ModalConfirm
        visible={isVisibleModal}
        setVisible={setIsVisibleModal}
        onFinish={() => debouncedFacturar()}
        body="Favor de verificar si la información fiscal es la correcta. ¿Desea continuar?"
        btnText="Si, generar la factura"
        loading={loading}
      />
    </>
  );
};

const CardHeader = styled.div`
  background-color:${COLORS.accent};
  padding: 15px 0;
  text-align: center;
  .ant-typography {
    color: #FFFFFF !important;
  }
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const StyledCol = styled(Col)`
  /* border-bottom-size: 1px; */
  border-bottom-style: solid;
  border-bottom-color: #f8f9fa;
`;

const SubmitButton = styled(Button).attrs({
  $accent: true,
  block: true,
})`
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: inherit;
  width: 100%;
  max-width: 500px;
`;

const ExitButton = styled(Button)`
  color: ${COLORS.gray};
  border-color: ${COLORS.gray};
  background-color: transparent;
  border-radius: 0.25rem;
`;

export default Facturacion;
