import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, COLORS, Text } from 'components';
import {
  Spin,
  Steps,
  Form,
  Row,
  Col,
  Table,
  Modal,
  Input,
} from 'antd';
import _logger from 'utils/logger';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
  LogoutOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { Container, StepsContent } from 'components/Steps';
import DatosSolicitante from 'components/Steps/DatosSolicitante';
import { DEV } from 'utils/env';
import { postEvidencias, postQueja, postTestigos } from 'api/solicitudes/quejas';
import { padronesEnum } from 'views/Tramites/GPM/enums';
import { postCiudadano, postContribuyente } from 'api/padrones';
import InformacionGeneral from './components/InformacionGeneral';
import Evidencia from './components/Evidencia';
import ServidorPublico from './components/ServidorPublico';

const stepsEnum = Object.freeze({
  SOLICITANTE: 'SOLICITANTE',
  INFORMACION_GENERAL: 'INFORMACION_GENERAL',
  EVIDENCIA: 'EVIDENCIA',
  SERVIDOR_PUBLICO: 'SERVIDOR_PUBLICO',
});

const columns = [
  {
    title: 'Descripción',
    dataIndex: 'descripcion',
    key: 'descripcion',
  },
];

const getTitle = (step, currentStep) => (
  <b style={{ color: step.key === currentStep ? COLORS.accent : null }}>
    {step.title}
  </b>
);

const getStatus = (currIdx, idx) => {
  if (!currIdx) return null;
  if (currIdx > idx) return 'finish';
  if (currIdx < idx) return 'wait';
  return 'process';
};

const getRedirect = () => {
  const url = new URL(window.location);
  const encoded = encodeURIComponent(url.pathname);
  return encoded;
};

const steps = [
  {
    key: stepsEnum.SOLICITANTE,
    title: 'Solicitante',
  },
  {
    key: stepsEnum.INFORMACION_GENERAL,
    title: 'Información General',
  },
  {
    key: stepsEnum.EVIDENCIA,
    title: 'Evidencia',
  },
  {
    key: stepsEnum.SERVIDOR_PUBLICO,
    title: 'Servidor Público',
  },
];

const NewComplain = () => {
  const navigation = useNavigate();
  const [formModal] = Form.useForm();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(stepsEnum.SOLICITANTE);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [isAnonimo, setIsAnonimo] = useState(false);

  const onFinish = async () => {
    try {
      setLoading(true);
      const values = form.getFieldsValue();
      const _padron = values?.tipo_de_padron === padronesEnum.CONTRIBUYENTE
        ? 'contribuyente' : 'ciudadano';
      if (!values[_padron]) {
        const requestFunctions = {
          ciudadano: postCiudadano,
          contribuyente: postContribuyente,
        };
        const _newPadron = await requestFunctions[_padron](values);
        values[_padron] = _newPadron.id;
      }

      const _evidencia = values.evidencia;
      delete values.evidencia;
      const _queja = await postQueja(values);
      if (_queja) {
        if (dataSource.length) {
          await Promise.all(dataSource.map((testigo) => postTestigos(_queja.id, testigo)));
        }
        if (_evidencia?.length) {
          const promises = [];
          _evidencia.forEach((file) => {
            const formData = new FormData();
            formData.append('archivo', file.originFileObj);
            formData.append('descripcion', 'Evidencia');
            formData.append('extension_del_documento', file.type);
            formData.append('queja', _queja.id);
            promises.push(postEvidencias(formData));
          });
          await Promise.all(promises);
        }
        navigation('/protesta/consulta', { state: { queja: _queja.folio } });
      }
    } catch (error) {
      _logger('Error', error);
    } finally {
      setLoading(false);
    }
  };

  const rightButton = () => {
    if (currentStep === stepsEnum.SOLICITANTE) {
      return (
        <Button
          $accent
          onClick={async () => {
            if (!isAnonimo) {
              await form.validateFields([
                'tipo_de_persona',
                'rfc',
                'curp',
                'CURP',
                'nombre',
                'apellido_paterno',
                'lada_celular',
                'telefono_celular',
                'correo_electronico',
              ]);
            }
            setCurrentStep(stepsEnum.INFORMACION_GENERAL);
          }}
          block
        >
          {isAnonimo ? (
            <>
              <Text>
                Siguiente
              </Text>
              <ArrowRightOutlined />
            </>
          ) : (
            <>
              <Text>
                Guardar
              </Text>
              <SaveOutlined />
            </>
          )}
        </Button>
      );
    }
    if (currentStep === stepsEnum.INFORMACION_GENERAL) {
      return (
        <Button
          $accent
          onClick={async () => {
            await form.validateFields([
              'categoria_de_solicitud',
              'objeto_de_alteracion',
              'fecha_de_los_hechos',
              'hora_de_los_hechos',
              'oficina_de_queja',
              'institucion_de_queja',
              'tramite_de_queja',
              'detalle_de_hechos',
            ]);
            setCurrentStep(stepsEnum.EVIDENCIA);
          }}
          block
        >
          <Text>
            Siguiente
          </Text>
          <ArrowRightOutlined />
        </Button>
      );
    }
    if (currentStep === stepsEnum.EVIDENCIA) {
      return (
        <Button
          $accent
          onClick={async () => {
            setCurrentStep(stepsEnum.SERVIDOR_PUBLICO);
          }}
          block
        >
          <Text>
            Siguiente
          </Text>
          <ArrowRightOutlined />
        </Button>
      );
    }
    if (currentStep === stepsEnum.SERVIDOR_PUBLICO) {
      return (
        <Button
          $accent
          onClick={form.submit}
          block
        >
          <Text>
            Mandar protesta
          </Text>
          <SaveOutlined />
        </Button>
      );
    }
    return (
      <Button $accent block>
        <Link to="/quejas">
          <Text>
            Salir
          </Text>
          <LogoutOutlined />
        </Link>
      </Button>
    );
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onRow = (record) => ({ onClick: () => setSelectedRowKeys([record.key]) });

  const onCancel = () => {
    setVisibleModal(false);
    setSelectedRowKeys([]);
    formModal.resetFields();
  };

  const onFinishModal = (values) => {
    const clone = [...dataSource];
    const allValues = [
      ...clone,
      {
        value: clone.length + 1,
        descripcion: values.descripcion,
      },
    ];
    setDataSource(allValues);
    onCancel();
  };

  const onClickDelete = () => {
    const clone = [...dataSource];
    const index = clone.findIndex((item) => item.key === selectedRowKeys[0]);
    clone.splice(index, 1);
    setDataSource(clone);
    setSelectedRowKeys([]);
  };

  const onClickEdit = () => {
    const clone = [...dataSource];
    const index = clone.findIndex((item) => item.key === selectedRowKeys[0]);
    formModal.setFieldsValue(clone[index]);
    setVisibleModal(true);
  };

  return (
    <Container>
      <Spin spinning={loading} tip="Cargando...">
        <Steps
          size="small"
          progressDot
          labelPlacement="vertical"
          current={steps.findIndex((s) => s.key === currentStep)}
          style={{ overflowX: 'scroll', paddingTop: 10 }}
          direction="horizontal"
          responsive={false}
        >
          {steps.map((step, idx) => (
            <Steps.Step
              key={step.key}
              title={getTitle(step, currentStep)}
              status={getStatus(steps.findIndex((s) => s.key === currentStep), idx)}
              onClick={() => (DEV ? setCurrentStep(step.key) : null)}
            />
          ))}
        </Steps>
        <StepsContent>
          <Form
            preserve
            scrollToFirstError
            form={form}
            layout="vertical"
            name="new-complain"
            onFinish={onFinish}
            initialValues={{
              tipo_de_padron: 1,
              tipo_de_persona: 1,
              tiene_pruebas: false,
              es_extranjero: false,
            }}
            onFinishFailed={(errorInfo) => DEV && _logger('[onFinishFailed]', errorInfo)}
          >

            <DatosSolicitante
              visible={currentStep === stepsEnum.SOLICITANTE}
              getRedirect={getRedirect}
              allowAnonimo
              setIsAnonimo={setIsAnonimo}
            />
            <InformacionGeneral visible={currentStep === stepsEnum.INFORMACION_GENERAL} />
            <Evidencia visible={currentStep === stepsEnum.EVIDENCIA} />
            <ServidorPublico visible={currentStep === stepsEnum.SERVIDOR_PUBLICO} />
            {currentStep === stepsEnum.SERVIDOR_PUBLICO && (
              <Row justify="end" gutter={[10, 20]}>
                <Col>
                  <Button $accent onClick={() => setVisibleModal(true)}>
                    Agregar
                    <PlusOutlined />
                  </Button>
                </Col>
                <Col>
                  <Button disabled={!selectedRowKeys.length} onClick={onClickEdit}>
                    Editar
                    <EditOutlined />
                  </Button>
                </Col>
                <Col>
                  <Button disabled={!selectedRowKeys.length} onClick={onClickDelete}>
                    Eliminar
                    <DeleteOutlined />
                  </Button>
                </Col>
                <Col span={24}>
                  <TableContainer>
                    <Table
                      columns={columns}
                      size="small"
                      rowSelection={rowSelection}
                      onRow={onRow}
                      rowKey="value"
                      dataSource={dataSource}
                      pagination={false}
                    />
                  </TableContainer>
                </Col>
              </Row>
            )}
            <Row justify="space-between" style={{ marginTop: 20 }}>
              <Col />
              <Col style={{ width: 180 }}>
                <Form.Item style={{ width: 180 }}>
                  {rightButton()}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </StepsContent>
      </Spin>
      <Modal
        title="Agregar Testigo"
        visible={visibleModal}
        centered
        closable={false}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={onFinishModal}
          form={formModal}
        >
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                label="Descripción"
                name="descripcion"
                rules={[{ required: true }]}
              >
                <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" gutter={10}>
            <Col>
              <Button onClick={onCancel}>
                <ArrowLeftOutlined />
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button $accent type="primary" htmlType="submit">
                Guardar
                <SaveOutlined />
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Container>
  );
};

const TableContainer = styled.div`
.ant-table-thead > tr > th {
  background-color: ${COLORS.accent};
  color: white;
  font-weight: bold;
}
`;

export default NewComplain;
