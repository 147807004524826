/* eslint-disable import/no-dynamic-require */
import React, { useState } from 'react';
import {
  Space,
  Grid,
  Row,
  Col,
} from 'antd';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import {
  FlexCol,
  Title,
  Text,
  Button,
  Card,
  COLORS,
} from 'components';
import {
  ENV_NAME,
  ENV_NAMES_ENUM,
  IPROVINAY,
  REPUVE,
} from 'utils/env';
import { logoHeader } from 'assets';
import Previewer from 'components/Previewer';

const CardsNayarit = () => {
  const [previewUrl, setPreviewUrl] = useState('');

  return (
    <Col className="card-container" xs={24} sm={24} md={12}>
      <Card style={{ height: '100%' }} bg={COLORS.gray}>
        <Title level={3} color={COLORS.white}>
          Padrón Estatal
        </Title>
        <Text color={COLORS.white}>
          Descarga los formatos de los servicios relacionados al Padrón Estatal.
        </Text>
        <Button
          style={{ margin: '10px 0' }}
          color={COLORS.white}
          block
          onClick={() => {
            const url = 'https://opensir.sfo2.digitaloceanspaces.com/opensir/media/formato-de-devolucio%CC%81n-de-impuestos-estatales.pdf';
            setPreviewUrl(url);
          }}
        >
          Formato de devolución de impuestos estatales
        </Button>
        <Button
          color={COLORS.white}
          block
          onClick={() => {
            const url = 'https://opensir.sfo2.digitaloceanspaces.com/opensir/media/formato-de-inscripcion-o-movimientos-posteriores-al-padron-estatal.pdf';
            setPreviewUrl(url);
          }}
        >
          Formato de inscripción o movimientos posteriores al padrón estatal
        </Button>
      </Card>
      <Previewer
        visible={!!previewUrl}
        onCancel={() => setPreviewUrl('')}
        iFrameUrl={previewUrl}
      />
    </Col>
  );
};

const cards = {
  [ENV_NAMES_ENUM.NAYARIT]: {
    title: 'VENTANILLA ÚNICA',
    subTitle: 'Portal de Atención al Contribuyente',
    welcomeText: 'Bienvenido a la ventanilla única, donde puedes consultar, realizar, pagar tus trámites y servicios en línea, así como localizar las oficinas de atención.',
    colorTextCard: '',
    colorButtonCard: '',
    titleCard1: 'Realiza tus pagos y obtén tu factura aquí.',
    bodyTextCard: 'Ya puedes realizar pagos de contribuciones, derechos, servicios y pagos de diversos',
    cards: (<CardsNayarit />),
  },
  [ENV_NAMES_ENUM.JUAREZ]: {
    title: 'VENTANILLA VIRTUAL',
    titleAccent: false,
    // subTitle: 'de Atención Ciudadana',
    // welcomeText: 'Bienvenido a la ventanilla única, donde puedes consultar, realizar
    // y pagar tus trámites y servicios en línea, así como localizar las oficinas de atención.',
    colorTextCard: 'white',
    colorButtonCard: COLORS.white,
    titleCard1: 'Realiza pagos y obtén tu factura',
    bodyTextCard: 'Puedes realizar el pago de impuestos, trámites, servicios, multas y otros.',
    cards: (
      <Col className="card-container" xs={24} sm={24} md={12}>
        <Card style={{ height: '100%' }} bg={COLORS.gray}>
          <Title level={3} color={COLORS.white}>
            Información de interés
          </Title>
          <Text color={COLORS.white}>
            Encuentra información complementaria para tus trámites y servicios.
          </Text>
          <Button style={{ margin: '10px 0' }} color={COLORS.white} block>
            <Link to="/oficinas">
              Oficinas de atención
            </Link>
          </Button>
          <Button color={COLORS.white} block>
            <Link to="/normateca">
              Consultar normatividad
            </Link>
          </Button>
        </Card>
      </Col>
    ),
  },
  [ENV_NAMES_ENUM.HERMOSILLO]: {
    title: 'VENTANILLA ÚNICA',
    subTitle: 'de Atención Ciudadana',
    subTitleColor: 'white',
    welcomeText: 'Bienvenido a la ventanilla única, donde puedes consultar, realizar y pagar tus trámites y servicios en línea, así como localizar las oficinas de atención.',
    colorTextCard: 'white',
    colorButtonCard: COLORS.white,
    titleCard1: 'Realiza pagos y obtén tu factura',
    bodyTextCard: 'Puedes realizar el pago de impuestos, trámites, servicios, multas y otros.',
    cards: (
      <Col className="card-container" xs={24} sm={24} md={12}>
        <Card style={{ height: '100%' }} bg={COLORS.gray}>
          <Title level={3} color={COLORS.white}>
            Información de interés
          </Title>
          <Text color={COLORS.white}>
            Encuentra información complementaria para tus trámites y servicios.
          </Text>
          <Button style={{ margin: '10px 0' }} color={COLORS.white} block>
            <Link to="/oficinas">
              Oficinas de atención
            </Link>
          </Button>
          <Button color={COLORS.white} block>
            <Link to="/normateca">
              Consultar normatividad
            </Link>
          </Button>
        </Card>
      </Col>
    ),
  },
  [ENV_NAMES_ENUM.ROATAN]: {
    title: 'VENTANILLA ÚNICA',
    subTitle: 'Portal de Atención al Contribuyente',
    welcomeText: 'Bienvenido a la ventanilla única, donde puedes consultar, realizar, pagar tus trámites y servicios en línea, así como localizar las oficinas de atención.',
    colorTextCard: '',
    colorButtonCard: '',
    bodyTextCard: 'Ya puedes realizar pagos de contribuciones, derechos, servicios y pagos de diversos',
    cards: (
      <>
        <Col className="card-container" xs={24} sm={24} md={12}>
          <Card style={{ height: '100%' }} bg={COLORS.cream2}>
            <Title level={3}>
              Ligas de interés
            </Title>
            <Text>
              Consulta de sitios de interés
            </Text>
            <Button block style={{ marginTop: 40 }}>
              <a
                href="https://portalunico.iaip.gob.hn/portal=190"
                target="_blank"
                rel="noreferrer"
              >
                Portal Único de Transparencia
              </a>
            </Button>
            <Button block style={{ marginTop: 10 }}>
              <a
                href="https://amhon.hn/interes-municipal/"
                target="_blank"
                rel="noreferrer"
              >
                Interés Municipal (AMHON)
              </a>
            </Button>
          </Card>
        </Col>
        <Col className="card-container" xs={24} sm={24} md={12}>
          <Card style={{ height: '100%' }} bg={COLORS.gray}>
            <Title level={3} color={COLORS.white}>
              Guías de ayuda
            </Title>
            <Text color={COLORS.white}>
              En esta sección puedes descargar guías de ayuda para realizar citas y trámites
            </Text>
            <Button disabled block style={{ marginTop: 57 }} color={COLORS.white}>
              <Link
                download
                target="_blank"
                to="/files/guia-para-realizar-cita.pdf"
                style={{ cursor: 'not-allowed', pointerEvents: 'none' }}
              >
                Descarga Guía para realizar tu cita
              </Link>
            </Button>
            <Button color={COLORS.white} style={{ marginTop: 10 }} block>
              <Link to="/tramites">
                Información de Trámites
              </Link>
            </Button>
          </Card>
        </Col>
      </>
    ),
  },
};

const cardsRepuve = {
  [ENV_NAMES_ENUM.NAYARIT]: {
    title: 'Registro Público Vehicular de Nayarit',
    subTitle: 'Sistema Estatal de Seguridad Pública',
    welcomeText: 'Bienvenido al nuevo portal digital donde puedes consultar requisitos de trámites, realizar citas en linea, ubicar módulos de atención para poder atenderte con mayor agilidad.',
    colorTextCard: '',
    colorButtonCard: '',
    cards: (
      <>
        <Col className="card-container" xs={24} sm={24} md={12}>
          <Card style={{ height: '100%' }} bg={COLORS.cream2}>
            <Title level={3}>
              Ligas de interés
            </Title>
            <Text>
              Consulta de sitios de interés
            </Text>
            <Button block style={{ marginTop: 40 }}>
              <a
                href="https://regularizaauto.sspc.gob.mx/"
                target="_blank"
                rel="noreferrer"
              >
                Regulariza tu Auto (Sistema de Citas)
              </a>
            </Button>
            <Button block style={{ marginTop: 10 }}>
              <a
                href="https://www2.repuve.gob.mx:8443/ciudadania/"
                target="_blank"
                rel="noreferrer"
              >
                REPUVE Consulta Ciudadana
              </a>
            </Button>
            <Button block style={{ marginTop: 10 }}>
              <a
                href="https://hacienda.minayarit.gob.mx/tramites"
                target="_blank"
                rel="noreferrer"
              >
                Trámites Vehiculares (Secretaría de Administración y Finanzas)
              </a>
            </Button>
          </Card>
        </Col>
        <Col className="card-container" xs={24} sm={24} md={12}>
          <Card style={{ height: '100%' }} bg={COLORS.gray}>
            <Title level={3} color={COLORS.white}>
              Guías de ayuda
            </Title>
            <Text color={COLORS.white}>
              En esta sección puedes descargar guías de ayuda para realizar citas y trámites
            </Text>
            <Button disabled block style={{ marginTop: 57 }} color={COLORS.white}>
              <Link
                download
                target="_blank"
                to="/files/guia-para-realizar-cita.pdf"
                style={{ cursor: 'not-allowed', pointerEvents: 'none' }}
              >
                Descarga Guía para realizar tu cita
              </Link>
            </Button>
            <Button color={COLORS.white} style={{ marginTop: 10 }} block>
              <Link to="/tramites">
                Información de Trámites
              </Link>
            </Button>
          </Card>
        </Col>
      </>
    ),
  },
};

const cardsIprovinay = {
  title: 'VENTANILLA VIRTUAL',
  welcomeText: 'Bienvenido al nuevo Portal Ciudadano del Instituto Promotor para la vivienda de Nayarit, donde puedes consultar, realizar, pagar tus trámites y servicios en línea.',
  welcomeTextSize: '20px',
  colorTextCard: '',
  colorButtonCard: '',
  bodyTextCard: 'Ya puedes realizar pagos de contribuciones, derechos, servicios y pagos de diversos',
  cards: (
    <>
      <Col className="card-container" xs={24} sm={24} md={12}>
        <Card style={{ height: '100%' }} bg={COLORS.cream2}>
          <Title level={3} $centered>
            Transparencia
          </Title>
          <Button block style={{ marginTop: 30 }}>
            <a
              href="https://transparencia.nayarit.gob.mx/index.php?option=com_wrapper&view=wrapper&Itemid=490"
              target="_blank"
              rel="noreferrer"
            >
              Portal Único de Transparencia y Acceso a la Información Pública del Estado de Nayarit
            </a>
          </Button>
          <Button block style={{ marginTop: 10 }}>
            <a
              href="https://iprovinay.gob.mx/srtf.html"
              target="_blank"
              rel="noreferrer"
            >
              Informes Trimetrales
            </a>
          </Button>
        </Card>
      </Col>
      <Col className="card-container" xs={24} sm={24} md={12}>
        <Card
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
          bg={COLORS.gray}
        >
          <Title
            $centered
            level={3}
            color={COLORS.white}
            style={{ flex: 1 }}
          >
            El Instituto
          </Title>
          <Button
            block
            style={{ marginTop: 'auto' }}
            color={COLORS.white}
            onClick={() => window.open('https://iprovinay.gob.mx/nosotros.html')}
          >
            Nosotros
          </Button>
          <Button
            color={COLORS.white}
            style={{ marginTop: 10 }}
            block
            onClick={() => window.open('https://iprovinay.gob.mx/que%20hacemos.html')}
          >
            Que hacemos
          </Button>
        </Card>
      </Col>
    </>
  ),
};

let homeInfo = (REPUVE ? cardsRepuve : cards)[ENV_NAME];

homeInfo = IPROVINAY ? cardsIprovinay : homeInfo;

const Home = () => {
  const screens = Grid.useBreakpoint();
  const navigate = useNavigate();

  const styles = !screens.xl ? {
    height: '60%',
    display: 'flex',
    justifyContent: 'flex-start',
  } : {
    height: '100%',
  };

  return (
    <>
      <div className="home">
        <FlexCol flex="1" style={styles}>
          <Logo />
          <Title
            level={screens.xs ? 2 : 1}
            $accent={homeInfo.titleAccent}
            style={{ marginTop: 15 }}
          >
            {homeInfo.title}
          </Title>
          {!!homeInfo.subTitle && (
            <Title
              level={screens.xs ? 3 : 2}
              color={homeInfo.subTitleColor}
              style={{ marginBottom: 15, marginTop: 0 }}
            >
              {homeInfo.subTitle}
            </Title>
          )}
          {!!homeInfo.welcomeText && (
            <Text
              style={{
                maxWidth: screens.md ? '40vw' : '70vw',
                textAlign: 'center',
                mixBlendMode: 'multiply',
                fontSize: homeInfo?.welcomeTextSize ?? 18,
              }}
            >
              {homeInfo.welcomeText}
            </Text>
          )}
          {!IPROVINAY && ENV_NAME === ENV_NAMES_ENUM.REPUVE && (
            <Space style={{ marginTop: 15 }}>
              <Button block={!screens.md} $accent>
                <Link to="/tramites">
                  {REPUVE ? 'Trámites y citas' : 'Trámites y servicios'}
                </Link>
              </Button>
              <Button block={!screens.md}>
                {REPUVE ? (
                  <a
                    target="_blank"
                    href="https://www2.repuve.gob.mx:8443/ciudadania/"
                    rel="noreferrer"
                  >
                    Consulta Ciudadana
                  </a>
                ) : (
                  <Link
                    target={REPUVE ? '_blank' : '_self'}
                    to={REPUVE ? 'https://www2.repuve.gob.mx:8443/ciudadania/' : '/pagos'}
                  >
                    {REPUVE ? 'Consulta Ciudadana' : 'Pagos'}
                  </Link>
                )}

              </Button>
            </Space>
          )}
          {!IPROVINAY && ENV_NAME === ENV_NAMES_ENUM.JUAREZ && (
            <>
              <Text
                strong
                style={{
                  maxWidth: screens.md ? '40vw' : '70vw',
                  textAlign: 'center',
                  mixBlendMode: 'multiply',
                }}
              >
                Pagos y Trámites frecuentes:
              </Text>
              <Row
                gutter={[10, 10]}
                style={{ width: '90%', marginTop: 15 }}
              >
                <Col xs={24} sm={24} md={8}>
                  <Button block $accent>
                    <Link to="/tramites/?q=traslacion">
                      Traslaciones
                    </Link>
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Button block $accent>
                    <Link to="/tramites/?q=licencia">
                      Licencias
                    </Link>
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Button block $accent>
                    <Link to="/tramites/?q=constancia">
                      Constancias
                    </Link>
                  </Button>
                </Col>
              </Row>
            </>
          )}
          {!IPROVINAY && ENV_NAME === ENV_NAMES_ENUM.NAYARIT && (
          <>
            <br />
            <Text
              strong
              style={{
                maxWidth: screens.md ? '40vw' : '70vw',
                textAlign: 'center',
                mixBlendMode: 'multiply',
              }}
            >
              Pagos Frecuentes:
            </Text>
            <Space style={{ marginTop: 15 }}>
              <Button block={!screens.md} $accent>
                <Link to="/pagos/consulta-de-adeudos/vehiculo-4">
                  Refrendo Vehicular
                </Link>
              </Button>
              <Button block={!screens.md} $accent>
                <Link to="/pagos/consulta-de-adeudos/alcohol-9">
                  Refrendo de Alcoholes
                </Link>
              </Button>
              <Button block={!screens.md} $accent>
                <Link to="/pagos/pago-de-derechos-03">
                  Pago de Derechos
                </Link>
              </Button>
              <Button block={!screens.md} $accent>
                <Link to="pagos/pago-de-impuestos-estatales-01">
                  Pago de impuestos estatales
                </Link>
              </Button>
            </Space>
            <br />
            {/* <Text
              strong
              style={{
                maxWidth: screens.md ? '40vw' : '70vw',
                textAlign: 'center',
                mixBlendMode: 'multiply',
              }}
            >
              Trámites Frecuentes:
            </Text>
            <Space style={{ marginTop: 15 }}>
              <Button block={!screens.md} $accent>
                <Link to="/pagos/consulta-de-adeudos/vehiculo-4">
                  Módulos de Inscripciones al Padrón Estatal
                </Link>
              </Button>
              <Button block={!screens.md} $accent>
                <Link to="/pagos/consulta-de-adeudos/alcohol-9">
                  Módulos de Avisos posteriores al Padrón Estatal
                </Link>
              </Button>
              <Button block={!screens.md} $accent>
                <Link to="/pagos/pago-de-derechos-03">
                  Módulo de Trámites Vehiculares
                </Link>
              </Button>
            </Space> */}
          </>
          )}
          {!IPROVINAY && ENV_NAME === ENV_NAMES_ENUM.ROATAN && (
          <>
            <br />
            <Text
              strong
              style={{
                maxWidth: screens.md ? '40vw' : '70vw',
                textAlign: 'center',
                mixBlendMode: 'multiply',
              }}
            >
              Pagos Frecuentes:
            </Text>
            <Row style={{ marginTop: 15, width: '70%' }} gutter={[10, 10]}>
              <Col xs={24} sm={24} md={8}>
                <Button $accent style={{ width: '100%', padding: 10, borderRadius: 8 }}>
                  <Link to="/tramites/?q=permiso">
                    Permisos
                  </Link>
                </Button>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Button $accent style={{ width: '100%', padding: 10, borderRadius: 8 }}>
                  <Link to="/tramites/?q=constancia">
                    Constancia
                  </Link>
                </Button>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Button $accent style={{ width: '100%', padding: 10, borderRadius: 8 }}>
                  <Link to="/tramites/?q=impuesto">
                    Impuestos
                  </Link>
                </Button>
              </Col>
            </Row>
          </>
          )}
          {IPROVINAY && ENV_NAME === ENV_NAMES_ENUM.NAYARIT && (
          <>
            <br />
            <Text
              strong
              style={{
                maxWidth: screens.md ? '40vw' : '70vw',
                textAlign: 'center',
                mixBlendMode: 'multiply',
              }}
            >
              Tramites y Pagos Frecuentes
            </Text>
            <Row style={{ marginTop: 15, width: '70%' }} gutter={[10, 10]}>
              <Col xs={24} sm={24} md={8}>
                <Button block $accent>
                  <Link to="/tramites/?q=pago de lotes">
                    Pago de Lotes
                  </Link>
                </Button>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Button block $accent>
                  <Link to="/tramites/?q=pago de escrituras">
                    Pago de escrituras
                  </Link>
                </Button>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Button block $accent>
                  <Link to="/tramites/?q=mejora tu vivienda">
                    Mejora tu vivienda
                  </Link>
                </Button>
              </Col>
            </Row>
          </>
          )}
        </FlexCol>
      </div>
      <Row gutter={[20, 20]} style={{ padding: '0 10px' }}>
        {!!homeInfo.titleCard1 && (
          <Col className="card-container" xs={24} sm={24} md={12}>
            <Card style={{ height: '100%' }} bg={COLORS.cream2} className="col">
              <Title level={3} color={homeInfo.colorTextCard}>
                {homeInfo.titleCard1}
              </Title>
              <Text color={homeInfo.colorTextCard}>
                {homeInfo.bodyTextCard}
              </Text>
              <Button style={{ margin: '10px 0' }} color={homeInfo.colorButtonCard} block>
                <Link to="/pagos">
                  Consultar tipos de pagos
                </Link>
              </Button>
              <Button
                block
                color={homeInfo.colorButtonCard}
                onClick={() => {
                  const action = {
                    [ENV_NAMES_ENUM.JUAREZ]: () => window.open('https://www.juarez.gob.mx/facturacion/'),
                    [ENV_NAMES_ENUM.NAYARIT]: () => navigate('/facturacion'),
                    [ENV_NAMES_ENUM.HERMOSILLO]: () => navigate('/facturacion'),
                    [ENV_NAMES_ENUM.ROATAN]: () => navigate('/facturacion'),
                  }[ENV_NAME];
                  if (action) {
                    action();
                  }
                }}
              >
                Facturación electrónica
              </Button>
            </Card>
          </Col>
        )}
        {homeInfo.cards}
      </Row>
    </>
  );
};

const Logo = styled.img.attrs(({
  src: logoHeader,
  alt: 'logo',
}))`
  width: 500px;
  @media only screen and (max-width: 992px) {
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }
`;

export default Home;
