import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Text } from 'components';
import LinkWithTooltip from 'components/LinkWithTooltip';
import { DeleteOutlined, RightOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Col,
  Form,
  Input,
  Row,
} from 'antd';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated, selectUser } from 'store/auth';
import RegisterPadron from 'views/Payments/components/RegisterPadron';
import { ACCOUNT_AVAILABLE, DEV } from 'utils/env';
import { CURP_VALIDATOR, RFC_PATTERN } from 'utils/patterns';
import { gettersListMap } from 'api/padrones';
import { padronesEnum } from 'views/Tramites/GPM/enums';
import Select from 'components/Select';

const solicitanteFields = [
  'ciudadano',
  'contribuyente',

  'CURP',
  'first_name',
  'last_name',
  'second_last_name',
  'email',
  'lada',
  'numero_de_celular',

  'tipo_de_persona',
  'rfc',
  'curp',
  'nombre',
  'apellido_paterno',
  'apellido_materno',
  'correo_electronico',
  'lada_celular',
  'telefono_celular',
  'razon_social',
];

export const solicitanteHasContactInfo = (values, tipoDePadron) => {
  if (tipoDePadron === padronesEnum.CONTRIBUYENTE) {
    return values.correo_electronico && values.lada_celular && values.telefono_celular;
  }
  return values.correo_electronico && values.lada && values.numero_de_celular;
};

const DatosSolicitante = ({
  visible,
  getRedirect,
  allowAnonimo,
  setIsAnonimo,
}) => {
  const [tipoDePadron, setTipoDePadron] = useState(1);
  const [requiredUser, setRequiredUser] = useState(false);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectUser);
  const form = Form.useFormInstance();

  const solicitanteName = tipoDePadron === padronesEnum.CIUDADANO ? 'ciudadano' : 'contribuyente';
  const solicitanteId = Form.useWatch(solicitanteName, form);

  const hasContactInfo = useMemo(() => {
    if (!solicitanteId) return false;
    const values = form.getFieldValue();
    return solicitanteHasContactInfo(values, tipoDePadron);
  }, [solicitanteId]);

  // CURP es requerida solo si el padron es ciudadano o si es contribuyente y es persona fisica
  const requiredCurp = tipoDePadron === padronesEnum.CIUDADANO
    || (tipoDePadron === padronesEnum.CONTRIBUYENTE && form.getFieldValue('tipo_de_persona') === 1);

  const rules = {
    rfc: [
      { required: true },
      {
        validator: async (_, _value) => {
          if (!_value) return;
          if (visible) {
            const value = _value.toString().toUpperCase();
            const lengthsByTipoPersona = {
              1: 13, // fisica
              2: 12, // moral
              3: 13, // extranjero
            };
            const tipoDePersona = form.getFieldValue('tipo_de_persona');
            const invalidLength = value.length !== lengthsByTipoPersona[tipoDePersona];
            const invalid = !RFC_PATTERN.test(value) || invalidLength;
            if (invalid) throw new Error('Ingrese un RFC válido.');
            const id = form.getFieldValue('id');
            const propsNames = { 1: 'RFC', 15: 'rfc' };
            const propName = propsNames[tipoDePadron];
            const params = { [propName]: value };
            const matches = await (gettersListMap[tipoDePadron])(params);
            const [match] = matches.results.filter((e) => e.id !== id);
            if (match) {
              form.setFieldsValue({ ...match, [solicitanteName]: match.id });
            }
          }
        },
      },
    ],
    curp: [
      { required: requiredCurp },
      {
        validator: async (_, _value) => {
          const _tipoDePadron = form.getFieldValue('tipo_de_padron');
          const tipoDePersona = _tipoDePadron === padronesEnum.CIUDADANO
            ? 1 : form.getFieldValue('tipo_de_persona');
          if (!_value) return;
          if (visible) {
            const curpField = _tipoDePadron === padronesEnum.CIUDADANO ? 'CURP' : 'curp';
            await CURP_VALIDATOR(tipoDePersona).validator(_, _value);
            const { results: [exist] } = await gettersListMap[_tipoDePadron]({
              [curpField]: _value,
            });
            if (exist) {
              form.setFieldsValue({
                ...exist,
                es_extranjero: false,
                [solicitanteName]: exist.id,
              });
            }
          }
        },
      },
    ],
  };

  useEffect(() => {
    if (isAuthenticated || user) {
      const tipo_de_padron = 'clave_ciudadana' in user
        ? padronesEnum.CIUDADANO : padronesEnum.CONTRIBUYENTE;

      form.setFieldsValue({
        ...user,
        [solicitanteName]: user.id,
        es_extranjero: user.tipo_de_persona === 3,
        tipo_de_padron,
      });
      setTipoDePadron(tipo_de_padron);
    }
  }, []);

  useEffect(() => {
    if (allowAnonimo && typeof setIsAnonimo === 'function') {
      setIsAnonimo(requiredUser);
    }
  }, [requiredUser]);

  return (
    <Col span={visible ? 24 : 0}>
      {!isAuthenticated && ACCOUNT_AVAILABLE && (
        <>
          <Text>
            Si ya se encuentra registrado puede:
            <Button style={{ marginInline: 15 }} $accent>
              <LinkWithTooltip
                to={`/mi-cuenta${DEV ? `/?redirect=${getRedirect()}` : ''}`}
                text="Iniciar sesión"
                icon={RightOutlined}
              />
            </Button>
            o ingrese su
            {' '}
            <b>{tipoDePadron === padronesEnum ? 'CURP' : 'RFC'}</b>
            {' '}
            para intentar autorellenar sus datos.
          </Text>
          <Col span={24} style={{ height: 30 }} />
          {allowAnonimo && (
            <>
              <Col span={24}>
                <Checkbox
                  checked={requiredUser}
                  onChange={(e) => setRequiredUser(e.target.checked)}
                >
                  <Text>
                    Deseo hacer mi solicitud de manera anónima
                  </Text>
                </Checkbox>
              </Col>
              <Col span={24} style={{ height: 30 }} />
            </>
          )}
        </>
      )}
      {!requiredUser && (
        <>
          <Text>
            De lo contrario capture su información para registrarse:
          </Text>
          <Col span={24} style={{ height: 15 }} />
          <Form.Item name={solicitanteName} hidden>
            <Input />
          </Form.Item>
          <Row gutter={10} style={{ height: '100%', paddingBottom: 20 }}>
            {solicitanteId && (
            <Col span={8} order={0}>
              <Form.Item
                label="Clave"
                name={tipoDePadron === padronesEnum.CIUDADANO ? 'clave_ciudadana' : 'clave'}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            )}
            <Col xs={24} sm={24} md={8}>
              <Form.Item name="tipo_de_padron" label="Tipo de Padron">
                <Select
                  options={[
                    { descripcion: 'Ciudadano', id: 1 },
                    { descripcion: 'Contribuyente', id: 15 },
                  ]}
                  onChange={setTipoDePadron}
                  disabled={!!solicitanteId}
                />
              </Form.Item>
            </Col>
            <RegisterPadron
              tipoDePadron={tipoDePadron}
              callback={() => null}
              onCancel={() => null}
              rulesRFC={rules.rfc}
              rulesCURP={rules.curp}
              onlyItems
              disabledContactInfo={hasContactInfo}
              selfId={solicitanteId}
            />
            {!!solicitanteId && (
              <Col span={8} order={9}>
                <Form.Item label=" ">
                  <Button onClick={() => form.resetFields(solicitanteFields)}>
                    Limpiar
                    <DeleteOutlined />
                  </Button>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Form.Item hidden name={solicitanteName}>
            <Input />
          </Form.Item>
        </>
      )}
    </Col>
  );
};

DatosSolicitante.propTypes = {
  visible: PropTypes.bool,
  getRedirect: PropTypes.func.isRequired,
  allowAnonimo: PropTypes.bool,
  setIsAnonimo: PropTypes.func,
};

DatosSolicitante.defaultProps = {
  visible: false,
  allowAnonimo: false,
  setIsAnonimo: () => null,
};

export default DatosSolicitante;
