import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Spin,
  Col,
  Modal,
  Row,
  DatePicker,
  Input,
  Table as AntTable,
} from 'antd';
import FormSubmitCustomControls from 'components/FormSubmitCustomControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import { DATETIME_FRONTEND_FORMAT, DATE_FRONTEND_FORMAT } from 'utils/formatValues';
import {
  deleteObligacionFiscalContribuyente,
  getObligacionesFiscalesContribuyente,
  patchObligacionFiscalContribuyente,
  postObligacionFiscalContribuyente,
} from 'api/empresas/obligacionesFiscalesContribuyente';
import {
  getEstatusDeObigacionesFiscales,
  getGirososDeAlcoholes,
  getObligacionesFiscales,
  getTiposDeAlcoholes,
  getUnidadesDeRecaudacion,
} from 'api/empresas';
import { useSelector } from 'react-redux';
import gettersMap, {
  getContenTypePorTipoDePadron,
  gettersListMap,
  getTipoDePadronPorContenType,
  postPadronesPrivateMap,
} from 'api/padrones';
import _logger from 'utils/logger';
import { toMoment } from 'utils/normalizers';
import { selectPadrones } from 'store/catalogos';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { getEmpresas } from 'api/empresas/empresas';
import getAllPages from 'utils/getAllPages';
import { toInteger } from 'utils/formatters';
import getParamsPadron from '../paramsPadronesAfectados';
import { padronesEnum } from '../enums';

const ObligacionesFiscalesContribuyente = ({
  contribuyente,
  tipoDePadron,
  padron,
  callback,
  fetchCallback,
  mutable,
}) => {
  const [form] = Form.useForm();
  const estatus_de_obligaciones_fiscal = Form.useWatch('estatus_de_obligaciones_fiscal', form);
  const tipo_de_padron = Form.useWatch('tipo_de_padron', form);
  const object_id_padron = Form.useWatch('object_id_padron', form);
  const padrones = useSelector(selectPadrones)
    .filter((e) => ![1, 2, 3, 4, 5, 15, 16, 17, 19, 29, 30].includes(e.id));
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [obligaciones, setObligaciones] = useState([]);
  const [estatusDeObligaciones, setEstatusObligaciones] = useState([]);
  const [subPadrones, setSubPadrones] = useState({});
  const [empresas, setEmpresas] = useState([]);
  const [unidadesRecaudadoras, setUnidadesRecaudadoras] = useState([]);
  const [tiposDeAlcoholes, setTiposDeAlcoholes] = useState([]);
  const [girosDeAlcoholes, setGirosDeAlcoholes] = useState([]);

  const descripcionPadron = padrones.find((e) => e.id === tipo_de_padron)?.descripcion || '';
  const optionsPadron = (subPadrones[tipo_de_padron] || []).concat([{ label: `Agregar ${descripcionPadron}`, id: 0 }]);
  const padronFound = padron || optionsPadron.find((e) => e.id === object_id_padron);

  const fetchData = async () => {
    setLoading(true);
    const params = { contribuyente };
    if (padron) {
      params.content_type_padron = getContenTypePorTipoDePadron(tipoDePadron);
      params.object_id_padron = padron.id;
    }
    const _data = await getObligacionesFiscalesContribuyente(params);
    setData(_data);
    if (fetchCallback) fetchCallback(_data);
    setLoading(false);
  };

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      const promises = [
        getObligacionesFiscales(),
        getEstatusDeObigacionesFiscales(),
      ];
      if (!padron) {
        promises.push(getUnidadesDeRecaudacion());
        promises.push(getEmpresas({ contribuyente }, true));
        promises.push(getTiposDeAlcoholes());
        promises.push(getGirososDeAlcoholes());
      }
      const [
        _obligaciones,
        _estatus,
        _unidadesRecaudacion,
        _empresas,
        _tiposDeAlcoholes,
        _girosDeAlcoholes,
      ] = await Promise.all(promises);
      await fetchData();
      if (mounted) {
        const filtered = padron
          ? _obligaciones.filter((o) => o.tipo_padron === tipoDePadron)
          : _obligaciones;
        setObligaciones(filtered);
        setEstatusObligaciones(_estatus);
        setEmpresas(_empresas);
        setUnidadesRecaudadoras(_unidadesRecaudacion);
        setTiposDeAlcoholes(_tiposDeAlcoholes);
        setGirosDeAlcoholes(_girosDeAlcoholes);
        if (padron) setSubPadrones({ ...subPadrones, [tipoDePadron]: [padron] });
      }
    };
    fetch();
    return () => { mounted = false; };
  }, []);

  const fillValuesPadron = (_padron = padronFound) => {
    if (_padron) {
      form.setFieldsValue({
        object_id_padron: _padron.id,
        clave_cuenta_de_padron_obligado: _padron?.clave,
        contribuyente_rfc: _padron?.empresa?.contribuyente?.rfc,
      });
    }
  };

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const {
        empresa,
        unidad_recaudadora,
        object_id_padron: _object_id_padron,
        registro_patronal_imss,
        actividad_empresarial_especifica,
        es_arrendamiento,
        tipo_de_alcohol,
        giro_de_alcohol,
        numero_de_permiso,
        ...values
      } = form.getFieldsValue();
      const [key] = selectedRowKeys;
      values.contribuyente = contribuyente;
      values.content_type_padron = getContenTypePorTipoDePadron(values.tipo_de_padron);
      if (padron && !key) {
        values.object_id_padron = padron.id;
      }
      let _padron;
      if (object_id_padron === 0) {
        _padron = await postPadronesPrivateMap[tipo_de_padron]({
          descripcion: `IMPUESTO ${descripcionPadron.toUpperCase()}`,
          empresa,
          unidad_recaudadora,
          registro_patronal_imss,
          actividad_empresarial_especifica,
          es_arrendamiento,
          tipo_de_alcohol,
          giro_de_alcohol,
          numero_de_permiso,
        });
        if (!_padron) {
          setLoading(false);
          return;
        }
        values.object_id_padron = _padron.id;
        values.clave_cuenta_de_padron_obligado = _padron.clave;
      } else {
        values.object_id_padron = object_id_padron;
      }
      const saved = await (key
        ? patchObligacionFiscalContribuyente(key, values)
        : postObligacionFiscalContribuyente(values));
      if (saved) {
        const descripcionObligacion = obligaciones
          .find((o) => o.id === values.obligacion_fiscal)?.descripcion;
        const movementType = key ? 'editada' : 'registrada';
        const __padron = _padron || padronFound;
        const padronToStr = ` ${saved.id} - ${__padron.clave} - ${__padron.empresa?.nombre_comercial
          || __padron.empresa?.contribuyente?.nombre_completo}`;
        const detailItems = [
          `Fecha inicio: ${values.fecha_inicio.format()}`,
          values.fecha_fin ? `Fecha fin: ${values.fecha_fin.format() || 'N/A'}` : null,
          `Estatus: ${estatusDeObligaciones.find((e) => e.id === values.estatus_de_obligaciones_fiscal)?.descripcion}`,
          values.observaciones ? `Observaciones: ${values.observaciones}` : null,
        ].filter((e) => e);
        const detail = detailItems.join(', ');
        saved.message = `Obligación ${descripcionObligacion} ${padron ? '' : `para ${descripcionPadron} ${padronToStr}`} [${movementType}]: ${detail}`;
        onCancel();
        await fetchData();
        if (callback) callback(saved);
      } else if (object_id_padron === 0) {
        fillValuesPadron(_padron);
        setSubPadrones({
          ...subPadrones,
          [tipo_de_padron]: [...subPadrones[tipo_de_padron], _padron],
        });
        form.setFieldsValue({ object_id_padron: _padron.id });
      }
      setLoading(false);
    } catch (err) {
      _logger(err, setLoading);
    }
  };

  const retrievePadron = async (id, tipoPadron) => {
    const _padron = await gettersMap[tipoPadron]({ id });
    if (_padron) {
      setSubPadrones({ ...subPadrones, [tipoPadron]: [_padron] });
      form.setFieldsValue({ object_id_padron: _padron.id });
      fillValuesPadron(_padron);
    }
  };

  const onClickEdit = async () => {
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    if (padron) {
      fillValuesPadron();
    } else {
      await retrievePadron(
        match.object_id_padron,
        getTipoDePadronPorContenType(match.content_type_padron),
      );
    }
    setVisible(true);
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
    form.setFieldsValue({ tipo_padron: tipoDePadron });
  };

  const onClickDelete = () => setVisibleAlert(true);

  const deleteItem = async () => {
    setLoading(true);
    const [key] = selectedRowKeys;
    const deleted = await deleteObligacionFiscalContribuyente(key);
    if (deleted) {
      const match = data.find((e) => e.id === key);
      const obligacion = obligaciones.find((o) => o.id === match.obligacion_fiscal)?.descripcion;
      if (callback) callback({ message: `Obligación ${obligacion} eliminada` });
      setVisibleAlert(false);
      onCancel();
      await fetchData();
    }
    setLoading(false);
  };

  const rowSelection = mutable ? {
    selectedRowKeys,
    type: 'radio',
  } : null;

  const columns = [
    {
      title: 'Clave',
      key: 'clave_cuenta_de_padron_obligado',
      dataIndex: 'clave_cuenta_de_padron_obligado',
      width: 100,
    },
    {
      title: 'Obligación Fiscal',
      key: 'obligacion_fiscal',
      dataIndex: 'obligacion_fiscal',
      width: 200,
      render: (val) => obligaciones.find((e) => e.id === val)?.descripcion,
    },
    {
      title: 'Fecha Inicio',
      key: 'fecha_inicio',
      dataIndex: 'fecha_inicio',
      width: 120,
      render: (val) => val?.format(),
      responsive: ['md'],
    },
    {
      title: 'Estado',
      key: 'estatus_de_obligaciones_fiscal',
      dataIndex: 'estatus_de_obligaciones_fiscal',
      width: 100,
      render: (val) => estatusDeObligaciones.find((e) => e.id === val)?.descripcion,
      responsive: ['md'],
    },
  ].filter((e) => e);

  const rules = { required: [{ required: true }] };

  const onChangeObligacion = (value) => {
    if (value && !padron) {
      const match = obligaciones.find((e) => e.id === value);
      form.setFieldsValue({ tipo_de_padron: match.tipo_padron });
    }
  };

  const onRow = (record) => ({
    onClick: () => {
      setSelectedRowKeys([record.id]);
      form.setFieldsValue({
        ...record,
        tipo_de_padron: getTipoDePadronPorContenType(record.content_type_padron),
      });
    },
    onDoubleClick: () => onClickEdit(),
  });

  const onChangeTipoPadron = async (_tipo_de_padron) => {
    form.resetFields(['obligacion_fiscal']);
    if (!_tipo_de_padron) return;
    setLoading(true);
    const params = getParamsPadron(
      { tipo_de_padron: _tipo_de_padron },
      padronesEnum.CONTRIBUYENTE,
      { id: contribuyente },
    );
    const _padrones = await getAllPages(gettersListMap[_tipo_de_padron], params);
    setSubPadrones({ ...subPadrones, [_tipo_de_padron]: _padrones });
    setLoading(false);
  };

  const onSelect = (s) => fillValuesPadron(optionsPadron.find((e) => e.id === s));

  return (
    <>
      <Spin tip="Cargando..." spinning={loading}>
        {mutable && (
          <FormSubmitCustomControls
            controls={[
              // (padron || !callback) &&
              {
                text: 'Agregar',
                icon: <PlusOutlined />,
                onClick: onClickAdd,
              },
              {
                text: 'Editar',
                icon: <EditOutlined />,
                onClick: onClickEdit,
                disabled: !selectedRowKeys.length,
              },
              (padron || !callback) && {
                text: 'Eliminar',
                icon: <DeleteOutlined />,
                onClick: onClickDelete,
                disabled: !selectedRowKeys.length,
              },
            ].filter((e) => e)}
            style={{ width: '100%' }}
            justify="end"
          />
        )}
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            rowSelection={rowSelection}
            onRow={onRow}
            rowKey="id"
            scroll={{
              y: 600,
              x: columns.reduce((acum, curr) => acum + curr.width, 0),
              scrollToFirstRowOnChange: true,
            }}
          />
        </Col>
      </Spin>
      <Modal
        visible={visible}
        closable={false}
        footer={null}
        title={(
          <FormSubmitCustomControls
            label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Obligación Fiscal`}
            onFinish={form.submit}
            onCancel={onCancel}
            loading={loading}
          />
        )}
        keyboard={!loading}
        maskClosable={!loading}
        onCancel={onCancel}
        forceRender
      >
        <Spin tip="Cargando..." spinning={loading}>
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              onFinish={() => onFinish()}
              initialValues={{
                estatus_de_obligaciones_fiscal: 1,
                fecha_inicio: toMoment(new Date()),
              }}
            >
              <Row gutter={10}>
                <Col span={padron ? 0 : 24}>
                  <Form.Item
                    name="tipo_de_padron"
                    label="Padrón"
                    rules={!padron ? rules.required : []}
                  >
                    <Select
                      options={padrones}
                      onChange={onChangeTipoPadron}
                      allowClear
                      disabled={!!selectedRowKeys.length}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="obligacion_fiscal"
                    label="Obligación Fiscal"
                    rules={rules.required}
                  >
                    <Select
                      options={obligaciones.filter((o) => o.tipo_padron === tipo_de_padron)}
                      onChange={onChangeObligacion}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={padron || !descripcionPadron ? 0 : 24}>
                  <Form.Item
                    name="object_id_padron"
                    label={descripcionPadron}
                    rules={!padron ? rules.required : []}
                  >
                    <Select
                      options={optionsPadron}
                      allowClear
                      getLabel={(_padron) => _padron.label
                        || `${_padron?.clave} - ${_padron?.empresa?.nombre_comercial || _padron?.empresa?.contribuyente?.nombre_completo}`}
                      disabled={!!selectedRowKeys.length}
                      onSelect={onSelect}
                    />
                  </Form.Item>
                </Col>
                {object_id_padron === 0 && (
                  <>
                    <Col span={24}>
                      <Form.Item
                        name="empresa"
                        label="Establecimiento"
                        rules={rules.required}
                      >
                        <Select
                          options={empresas}
                          getLabel={(em) => `${em?.clave} - ${em?.nombre_comercial || em?.contribuyente?.nombre_completo}`}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="unidad_recaudadora"
                        label="Unidad Recaudadora"
                        rules={rules.required}
                      >
                        <Select
                          options={unidadesRecaudadoras}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    {tipo_de_padron === padronesEnum.NOMINA && (
                      <Col span={24}>
                        <Form.Item
                          name="registro_patronal_imss"
                          label="Número de registro IMSS"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    )}
                    {tipo_de_padron === padronesEnum.CEDULAR && (
                      <Col span={24}>
                        <Form.Item
                          name="actividad_empresarial_especifica"
                          label="Actividad Empresarial Específica"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    )}
                    {tipo_de_padron === padronesEnum.CEDULAR && (
                      <Col span={24}>
                        <Form.Item
                          name="es_arrendamiento"
                          label="¿Es Arrendamiento?"
                        >
                          <Select trueFalse />
                        </Form.Item>
                      </Col>
                    )}
                    {tipo_de_padron === padronesEnum.ALCOHOL && (
                      <>
                        <Col span={24}>
                          <Form.Item
                            name="tipo_de_alcohol"
                            label="Tipo de alcohol"
                            rules={rules.required}
                          >
                            <Select options={tiposDeAlcoholes} />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="giro_de_alcohol"
                            label="Giro de alcohol"
                            rules={rules.required}
                          >
                            <Select options={girosDeAlcoholes} />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="numero_de_permiso"
                            label="Número de permiso"
                            rules={rules.required}
                            normalize={toInteger}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </>
                )}
                {!!object_id_padron && (
                  <>
                    <Col span={12}>
                      <Form.Item
                        name="clave_cuenta_de_padron_obligado"
                        label="Clave"
                      >
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="contribuyente_rfc"
                        label="RFC"
                      >
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col span={24}>
                  <Form.Item
                    label="Fecha Inicio"
                    name="fecha_inicio"
                    rules={rules.required}
                  >
                    <DatePicker
                      placeholder=""
                      format={DATE_FRONTEND_FORMAT}
                      showTime
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="estatus_de_obligaciones_fiscal"
                    label="Estado"
                    rules={rules.required}
                  >
                    <Select
                      options={estatusDeObligaciones}
                      allowClear
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="observaciones"
                    label="Observaciones"
                    rules={[2, 3].includes(estatus_de_obligaciones_fiscal) ? rules.required : []}
                  >
                    <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
                  </Form.Item>
                </Col>
                {!!selectedRowKeys.length && !!padron && (
                  <Col span={24}>
                    <Form.Item
                      name="fecha_ultimo_estado"
                      label="Fecha Último Estado"
                    >
                      <DatePicker
                        placeholder=""
                        format={DATETIME_FRONTEND_FORMAT}
                        showTime
                        disabled={!!fetchCallback}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
          </Col>
        </Spin>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => {
          setVisibleAlert(false);
          setSelectedRowKeys([]);
        }}
        visible={visibleAlert}
        content=" el registro seleccionado?"
        loading={loading}
      />
    </>
  );
};

const Table = styled(AntTable)`
  .ant-table-thead>tr>th{
    background: transparent;
    border-bottom: 2px solid;
  }
`;

ObligacionesFiscalesContribuyente.propTypes = {
  contribuyente: PropTypes.number,
  permission: PropTypes.shape({
    add: PropTypes.bool.isRequired,
    change: PropTypes.bool.isRequired,
    delete: PropTypes.bool.isRequired,
    export: PropTypes.bool.isRequired,
    import: PropTypes.bool.isRequired,
    view: PropTypes.bool.isRequired,
  }).isRequired,
  tipoDePadron: PropTypes.number,
  callback: PropTypes.func,
  fetchCallback: PropTypes.func,
  padron: PropTypes.shape({ id: PropTypes.number, clave: PropTypes.string }),
  mutable: PropTypes.bool,
};

ObligacionesFiscalesContribuyente.defaultProps = {
  contribuyente: null,
  tipoDePadron: null,
  callback: null,
  fetchCallback: null,
  padron: null,
  mutable: true,
};

export default ObligacionesFiscalesContribuyente;
