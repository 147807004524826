/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Card as AntCard,
  Row,
  Col,
  Form,
  Spin,
  Grid,
  message,
  Input,
  Alert,
} from 'antd';
import {
  LockOutlined,
  RightOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  COLORS,
  Title,
  Text,
} from 'components';
import { RFC_PATTERN } from 'utils/patterns';
import _logger from 'utils/logger';
import LadaNumero from 'components/LadaNumero';
import { postSolicitarCodigoDeAcceso } from 'api/auth';
import ModalUpdateInfo from 'views/Payments/components/ModalUpdateInfo';
import useQuery from 'hooks/useQuery';
import { noSpaces } from 'utils/normalizers';
import { AUTH_CONTRIBUYENTE } from 'utils/env';
import gettersMap, { patchContribuyente } from 'api/padrones';

const { useBreakpoint } = Grid;

const SolicitarCodigo = () => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [initialValues, setInitialValues] = useState();
  const [redirect] = useQuery(['redirect']);
  const redirectParam = redirect ? `?redirect=${redirect}` : '';
  const toLogin = '/mi-cuenta/login'.concat(redirectParam);
  const toRegister = '/mi-cuenta/sign-in'.concat(redirectParam);
  const tipoDePadronId = AUTH_CONTRIBUYENTE ? 15 : 1;

  const getToken = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const success = await postSolicitarCodigoDeAcceso(values);
      setLoading(false);
      if (success.ciudadano) {
        const _padron = await gettersMap[tipoDePadronId]({ id: success.ciudadano });
        if (!!_padron?.telefono_celular && !_padron?.lada_celular) {
          await patchContribuyente(_padron.id, { lada_celular: 34 });
          await getToken();
        } else {
          setInitialValues(_padron);
          setVisibleUpdate(true);
          return;
        }
      }
      navigate(toLogin);

      message.success(success?.detail);
    } catch (err) {
      _logger(err);
      setLoading(false);
    }
  };

  const requiredRule = { required: true };

  const rules = {
    require: [requiredRule],
    rfc: [requiredRule, { pattern: RFC_PATTERN, message: ' Ingrese un RFC valido.' }],
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <Wrapper
            title={(
              <Row justify="center">
                <Title level={2}>
                  Mi Cuenta Única
                </Title>
              </Row>
            )}
            style={{ margin: `15px 0 ${screens.md ? 100 : 140}px 15px` }}
          >
            <Row justify="center">
              <Col span={24}>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={getToken}
                >
                  <Col span={24}>
                    <div>
                      <Text style={{ position: 'relative' }}>
                        <OrderedList>
                          <li>
                            Ingresa tu
                            {' '}
                            {AUTH_CONTRIBUYENTE ? 'RFC' : 'número de celular'}
                            {' '}
                            para recibir un código de acceso.
                          </li>
                          <li>
                            Si ya estas registrado, te llegará un código a tu correo con el que
                            podrás iniciar sesión.
                          </li>
                          <li>
                            Si no tienes tus datos de contacto actualizados, deberás actualizarlos
                            para poder notificarte el código de acceso.
                          </li>
                        </OrderedList>
                      </Text>
                    </div>
                  </Col>
                  <Row gutter={[15, 15]}>
                    {AUTH_CONTRIBUYENTE ? (
                      <Col span={24}>
                        <Form.Item
                          name="rfc"
                          label="RFC"
                          rules={rules.rfc}
                          normalize={(value) => noSpaces(value.toUpperCase())}
                        >
                          <Input disabled={loading} />
                        </Form.Item>
                      </Col>
                    ) : (
                      <LadaNumero
                        form={form}
                        rules={rules}
                        names={{
                          lada: 'lada',
                          numero_de_telefono: 'numero_de_celular',
                        }}
                        label="Teléfono"
                        fullSize
                      />
                    )}
                  </Row>
                  <Text style={{
                    color: 'rgb(204, 204, 0)',
                    margin: '-10px 0 0 0',
                    position: 'relative',
                  }}
                  >
                    Si no te has registrado puedes hacerlo a continuación en el botón de registrarse
                  </Text>
                  <br />
                  <Row justify="space-between">
                    <Col>
                      <Form.Item>
                        <Link to={toRegister}>
                          <Button
                            block={!screens.md}
                            loading={loading}
                            color={COLORS.accent}
                          >
                            Registrarse
                            <UserAddOutlined />
                          </Button>
                        </Link>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button
                          $accent
                          block={!screens.md}
                          loading={loading}
                          color={COLORS.accent}
                          onClick={getToken}
                        >
                          Iniciar Sesión
                          <RightOutlined />
                        </Button>
                      </Form.Item>
                    </Col>
                    {AUTH_CONTRIBUYENTE && (
                      <Col span={24}>
                        <Alert
                          type="error"
                          icon={(
                            <>
                              <LockOutlined
                                style={{
                                  fontSize: 32,
                                }}
                              />
                              <UserAddOutlined
                                style={{
                                  fontSize: 32,
                                }}
                              />
                            </>
                          )}
                          message={(
                            <ul>
                              <li>
                                <Text style={{ position: 'relative' }}>
                                  Al registrarte podrás acceder a tu cuenta express que te servirá
                                  para generar trámites generales.
                                </Text>
                              </li>
                              <li>
                                Si eres contribuyente con actividad empresarial, deberás registrarte
                                con este tipo de cuenta para poder consultar: mis obligaciones, mis
                                declaraciones, mi buzón, mis pagos y poder realizar trámites de las
                                obligaciones relacionadas.Si eres contribuyente con actividad
                                empresarial, deberás registrarte con este tipo de cuenta para
                                poder consultar: mis obligaciones, mis declaraciones, mi buzón,
                                mis pagos y poder realizar trámites de las obligaciones
                                relacionadas.
                              </li>
                            </ul>
                          )}
                          showIcon
                          style={{ textAlign: 'left' }}
                        />
                      </Col>
                    )}
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Wrapper>
        </Col>
      </Row>
      <ModalUpdateInfo
        initialValues={initialValues}
        visible={visibleUpdate}
        onCancel={() => setVisibleUpdate(false)}
        setVisible={setVisibleUpdate}
        idPadron={initialValues?.id}
        tipoDePadronId={tipoDePadronId}
        callback={() => form.submit()}
      />
    </Spin>
  );
};

const OrderedList = styled.ol`
  list-style-type: decimal;
  margin: 0;
  padding: 0;
  counter-reset: item;

  li {
    counter-increment: item;
    margin-bottom: 0.6em;

    &::marker {
      color: ${COLORS.accent} !important;
      border-radius: 50%;
      font-weight: bold;
    }
  }
`;

const Wrapper = styled(AntCard)`
  border-radius: 0.5rem;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.15);
  width: auto;
  height: auto;

  .ant-card-head {
    line-height: 1.2;
    margin-bottom: 10px;
    overflow: hidden;
    height: auto;
    .ant-typography {
      white-space: break-spaces;
    }
  }
  .ant-card-body {
    padding: 1rem 1rem;
    .ant-typography {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      position:absolute;
    }

  }
`;

export default SolicitarCodigo;
