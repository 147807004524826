import { message } from 'antd';
import _logger from 'utils/logger';
import API from 'api/index';
import { formatReceived, formatSent, hasFiles } from 'utils/formatters';
import { updateUserInfo, selectEntidad, selectPublicoGeneral } from 'store/auth';
import store from 'store/store';
import { selectContentTypesPadrones, selectPadrones, setPadrones } from 'store/catalogos';
import {
  ENV_NAME,
  ENV_NAMES_ENUM,
  ID_ENTIDAD,
  PROD,
  REPUVE,
} from 'utils/env';
import { padronesEnum } from 'views/Tramites/GPM/enums';

const descriptionsMap = {
  4: 'Vehículo',
  11: 'Juegos de Azar',
  13: 'Casa de empeño',
};

const files = ['foto'];

const isAvailable = (padron) => {
  if (ENV_NAME === ENV_NAMES_ENUM.JUAREZ) {
    if (PROD) {
      return ![
        padronesEnum.PREDIO,
        padronesEnum.INFRACCIONES,
        padronesEnum.MULTAS,
      ].includes(padron);
    }
    return true;
  }
  if (ENV_NAME === ENV_NAMES_ENUM.NAYARIT) {
    return [
      padronesEnum.CIUDADANO,
      padronesEnum.PREDIO,
      padronesEnum.VEHICULO,
      padronesEnum.ALCOHOL,
      padronesEnum.CONTRIBUYENTE,
      padronesEnum.MOTOCICLETA,
      padronesEnum.INFRACCIONES,
    ].includes(padron);
  }
  return false;
};

/**
 * GET Tipos de Padrones
 * @param {func} dispatch
 * @returns {Promise<object[]>}
 */
export const getPadrones = async (dispatch) => {
  try {
    const response = await API.get('catalogos/padrones');
    const mapped = response.data.filter((e) => e.id !== 5).map((e) => ({
      ...e,
      descripcion: descriptionsMap[e.id] || e.descripcion,
      available: isAvailable(e.id),
    }));
    if (dispatch) {
      dispatch(setPadrones(mapped));
    }
    return mapped;
  } catch (error) {
    _logger(error);
  }
  return [];
};

/**
 * @param {object} obj Object to evaluate
 * @returns object[]
 */
const createFilterWithObject = (obj = {}) => (prop) => !obj?.[prop];

const propsToEvaluateByObject = {
  ciudadano: ['email', 'numero_de_celular'],
  empresa: ['correo_electronico', 'numero_de_celular'],
  contribuyente: ['correo_electronico', 'telefono_celular'],
};

export const propsToRequire = {
  ciudadano: [...propsToEvaluateByObject.ciudadano, 'lada'],
  empresa: [...propsToEvaluateByObject.empresa, 'lada_celular'],
  contribuyente: [...propsToEvaluateByObject.contribuyente, 'lada_celular'],
};

const ciudadanoPadronDerivadoDeEmpresa = (padron) => padron.empresa?.ciudadano?.id;

const findCiudadanoIntoPadronFuncMap = {
  1: (padron) => padron.id,
  2: (padron) => padron.ciudadano?.id,
  3: (padron) => padron.ciudadano?.id,
  4: (padron) => padron.propietario?.id,
  15: (padron) => padron.ciudadano?.id,
  16: (padron) => padron.ciudadano_propietario?.id,
  17: (padron) => padron?.ciudadano_propietario?.id,
};

export const findCiudadano = (padron, tipoDePadron) => {
  const findFunc = findCiudadanoIntoPadronFuncMap[tipoDePadron] || ciudadanoPadronDerivadoDeEmpresa;
  const ciudadano = findFunc(padron);
  const publicoGeneral = selectPublicoGeneral(store.getState());
  return ciudadano || publicoGeneral?.id || publicoGeneral || null;
};

const neededInfoIn = (
  padron,
  propNameContribuyente,
  propNameCiudadano,
  validateSelf = false,
) => {
  if (!padron) {
    return [];
  }
  const contribuyenteHasAllContactInfo = !propsToEvaluateByObject.contribuyente
    .filter(createFilterWithObject(padron[propNameContribuyente])).length;
  if (propNameContribuyente && padron[propNameContribuyente]) {
    // si contribuyente tiene toda la info de contacto se usa esa
    if (contribuyenteHasAllContactInfo) {
      return [false, null, null, propNameContribuyente];
    }
    // si no hay ciudadano y no se buscara en el mismo padron
    // se necesita actualizar informacion de contribuyente
    if (propNameCiudadano && !padron.ciudadano && !validateSelf) {
      return [true, padron[propNameContribuyente].id, 15];
    }
  }
  if (propNameCiudadano && padron[propNameCiudadano]) {
    const ciudadanoHasAllContactInfo = !propsToEvaluateByObject.ciudadano
      .filter(createFilterWithObject(padron[propNameCiudadano])).length;
    // si ciudadano tiene toda la info de contacto se usa esa
    if (ciudadanoHasAllContactInfo) {
      return [false, null, null, propNameCiudadano];
    }
    // si hay contribuyente y no se buscara en el mismo padron
    // se prioriza actualizar informacion de contribuyente
    if (propNameContribuyente && padron[propNameContribuyente] && !validateSelf) {
      return [true, padron[propNameContribuyente].id, 15];
    }
    // si no se buscara en el mismo padron se necesita actualizar informacion de ciudadano
    if (!validateSelf) {
      return [true, padron[propNameCiudadano].id, 1];
    }
  }
  // si se puede buscar info en el mismo padron (solo aplica empresa)
  if (validateSelf) {
    const hasAllContactInfo = !propsToEvaluateByObject.empresa
      .filter(createFilterWithObject(padron)).length;
    // si se tiene toda la info directamente se usa esa
    if (hasAllContactInfo) {
      return [false, null, null, validateSelf];
    }
    // sino volvemos a validar pero excluyendo info directa del padron
    return neededInfoIn(padron, propNameContribuyente, propNameCiudadano);
  }
  // no hay relacion a propietario ni info directamente en el padron
  return [];
};

const infoNeededByPadronTypeMap = {
  1: (padron) => {
    const hasAllContactInfo = !propsToEvaluateByObject.ciudadano
      .filter(createFilterWithObject(padron)).length;
    if (hasAllContactInfo) {
      return [false, null, null, 'self'];
    }
    return [true, padron.id, 1];
  },
  2: (padron) => neededInfoIn(padron, null, 'ciudadano', false),
  3: (padron) => neededInfoIn(padron, 'contribuyente', 'ciudadano', false),
  4: (padron) => neededInfoIn(padron, 'contribuyente_propietario', 'propietario', false),
  6: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
  7: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
  8: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
  9: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
  10: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
  11: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
  12: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
  13: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
  14: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
  15: (padron) => {
    const contribuyenteHasAllContactInfo = !propsToEvaluateByObject.contribuyente
      .filter(createFilterWithObject(padron)).length;
    if (contribuyenteHasAllContactInfo) {
      return [false, null, null, 'self'];
    }
    // si no hay ciudadano y no se buscara en el mismo padron
    // se necesita actualizar informacion de contribuyente
    if (padron.ciudadano && padron.tipo_de_persona === 1) {
      const ciudadanoHasAllContactInfo = !propsToEvaluateByObject.ciudadano
        .filter(createFilterWithObject(padron.ciudadano)).length;
      if (ciudadanoHasAllContactInfo) {
        return [false, null, null, 'ciudadano'];
      }
    }
    return [true, padron.id, 15];
  },
  16: (padron) => neededInfoIn(padron, 'contribuyente_propietario', 'propietario', false),
  17: (padron) => neededInfoIn(padron, 'contribuyente_propietario', 'propietario', false),
  18: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
  19: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
  20: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
  21: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
  22: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
  23: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
  24: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
  25: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
  26: (padron) => neededInfoIn(padron.empresa, 'contribuyente', 'ciudadano', 'empresa'),
};

const validateResult = (
  _data,
  tipoDePadron,
  useRegisterMessage = false,
) => {
  const data = _data?.results || _data || [];
  if (data.length) {
    if (data.length === 1) {
      const [
        infoNeeded = false,
        idPadronUpdate,
        tipoDePadronNeedsInfo,
        propNameContactInfo,
      ] = infoNeededByPadronTypeMap[tipoDePadron](data[0]);
      return {
        ...data[0],
        infoNeeded,
        idPadronUpdate,
        tipoDePadronNeedsInfo,
        propNameContactInfo,
      };
    }
    message.info('Intente con una búsqueda más específica');
  } else {
    const padronString = selectPadrones(store.getState())
      .find((e) => e.id === tipoDePadron)?.descripcion || '';
    const defaultMessage = `No se ha encontrado ${padronString}, verifique la información`;
    const registerMessage = ' o registrese';
    message.info(`${defaultMessage}${useRegisterMessage ? registerMessage : ''}`);
  }
  return null;
};

/**
 * GET Búsqueda en Padrón Ciudadanos
 * @param {object} params
 * @param {string} params.q
 * @param {boolean} useRegisterMessage
 * @returns {Promise<object[]>}
 */
export const getCiudadano = async (validate, params, useRegisterMessage = false) => {
  try {
    const response = await API.get('cuentaunicasir/ciudadano-caja-public', { params });
    return validate
      ? validateResult(response.data, 1, useRegisterMessage) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Padrón Empresa
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getEmpresa = async (validate, params) => {
  try {
    const response = await API.get('cuentaunicasir/empresas-caja-atencion-ciudadana/', { params });
    return validate ? validateResult(response.data, 2) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Padrón Predio
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getPredio = async (validate, params) => {
  try {
    const _publicoGeneral = selectEntidad(store.getState());
    const response = await API.get('cuentaunicasir/predio-caja-atencion-ciudadana/', { params });
    if (response.status === 204) {
      return validate ? null : [];
    }
    return validate
      ? validateResult(response.data, 3, false, _publicoGeneral) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Padrón Vehiculo
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getVehiculo = async (validate, params) => {
  try {
    const response = await API.get(REPUVE ? 'cuentaunicasir/vehiculos-caja-atencion-ciudadana' : 'cuentaunicasir/vehiculos-portal', { params });
    return validate ? validateResult(response.data, 4) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Padrón Hospedaje
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getHospedaje = async (validate, params) => {
  try {
    const response = await API.get('empresas/hospedaje-caja-public/', { params });
    return validate ? validateResult(response.data, 6) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Padrón Arrendamiento
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getArrendamiento = async (validate, params) => {
  try {
    const response = await API.get('empresas/arrendamiento-caja-public/', { params });
    return validate ? validateResult(response.data, 7) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Padrón Nomina
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getNomina = async (validate, params) => {
  try {
    const response = await API.get('empresas/nomina-caja-public/', { params });
    return validate ? validateResult(response.data, 8) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Padrón Nomina Retrive
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getNominaContribuyente = async (id) => {
  try {
    const response = await API.get(`empresas/nomina-caja-public/${id}`);
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return [];
};

export const getRetrive = async (id, name) => {
  try {
    const response = await API.get(`empresas/${name}-caja-public/${id}`);
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return [];
};

/**
 * GET Búsqueda en Padrón Nomina Retencion
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getNominaRetencion = async (validate, params) => {
  try {
    const response = await API.get('empresas/retenedor-de-nomina-caja-public/', { params });
    return validate ? validateResult(response.data, 8) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Padrón Alcohol
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getAlcohol = async (validate, params) => {
  try {
    const response = await API.get('empresas/alcohol-caja-public/', { params });
    return validate ? validateResult(response.data, 9) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Padrón Alcohol
 * @param {object} id
 * @returns {Promise<object[]>}
 */
export const getAlcoholById = async (id) => {
  try {
    const response = await API.get(`empresas/alcohol-caja-public/${id}`);
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return null;
};

/**
 * GET Búsqueda en Padrón Venta de Alcohol
 * @param {object} id
 * @returns {Promise<object[]>}
 */
export const getVentaAlcoholById = async (id) => {
  try {
    const response = await API.get(`empresas/venta-de-alcohol-caja-public/${id}`);
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return null;
};

/**
 * GET Búsqueda en Padrón Hospedaje
 * @param {object} id
 * @returns {Promise<object[]>}
 */
export const getHospedajeById = async (validate, params) => {
  try {
    const response = await API.get('empresas/hospedaje-caja-public/', { params });
    return validate ? validateResult(response.data, 6) : response.data;
  } catch (error) {
    _logger(error);
  }
  return null;
};

/**
 * GET Búsqueda en Padrón Notarios
 * @param {object} id
 * @returns {Promise<object[]>}
 */
export const getNotarioseById = async (id) => {
  try {
    const response = await API.get(`empresas/notarios-caja-public/${id}`);
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return null;
};

/**
 * GET Búsqueda en Padrón Cedular
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getCedular = async (validate, params) => {
  try {
    const response = await API.get('empresas/cedular-caja-public/', { params });
    return validate ? validateResult(response.data, 10) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Padrón Cedular Retencion
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getCedularRetencion = async (validate, params) => {
  try {
    const response = await API.get('empresas/retenedor-cedular-caja-public/', { params });
    return validate ? validateResult(response.data, 10) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en venta de alcohol
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getVentaAlcohol = async (validate, params) => {
  try {
    const response = await API.get('empresas/venta-de-alcohol-caja-public/', { params });
    return validate ? validateResult(response.data, 26) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Padrón Juegos de Azar
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getJuegoDeAzar = async (validate, params) => {
  try {
    const response = await API.get('empresas/juego-de-azar-caja-public/', { params });
    return validate ? validateResult(response.data, 11) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Padrón Notario
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getNotario = async (validate, params) => {
  try {
    const response = await API.get('empresas/notario-caja-public/', { params });
    return validate ? validateResult(response.data, 12) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Padrón Caja de Empeño
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getCasaDeEmpenio = async (validate, params) => {
  try {
    const response = await API.get('/empresas/casa-de-impenio-caja-public/', { params });
    return validate ? validateResult(response.data, 13) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Padrón Agencia
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getAgencia = async (validate, params) => {
  try {
    const response = await API.get('empresas/agencia-caja-public/', { params });
    return validate ? validateResult(response.data, 14) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Padrón Contribuyente
 * @param {object} params
 * @param {string} params.q
 * @param {boolean} useRegisterMessage
 * @returns {Promise<object[]>}
 */
export const getContribuyente = async (
  validate,
  params,
  useRegisterMessage = false,
) => {
  try {
    const response = await API.get('empresas/contribuyentes-caja-public/', { params });
    return validate
      ? validateResult(response.data, 15, useRegisterMessage) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Padrón Motocicleta
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getMotocicleta = async (validate, params) => {
  try {
    const response = await API.get('recaudacion/motocicletas-caja-public/', { params });
    return validate ? validateResult(response.data, 16) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Remolque
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getRemolque = async (validate, params) => {
  try {
    const response = await API.get('recaudacion/remolques-caja-public/', { params });
    return validate ? validateResult(response.data, 17) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Policias Especiales
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getPoliciaEspecial = async (validate, params) => {
  try {
    const response = await API.get('empresas/contratos-de-policias-caja-public/', { params });
    return validate ? validateResult(response.data, 18) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Infracciones
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getInfracciones = async (validate, params) => {
  try {
    const response = await API.get('recaudacion/infracciones-caja-public/', { params });
    return validate ? validateResult(response.data, 19) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Expediente de licencia de comercio
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getExpedienteDeComercio = async (validate, params) => {
  try {
    const response = await API.get('empresas/expedientes-de-comercio-informal-caja-public/', { params });
    return validate ? validateResult(response.data, 20) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Expediente de mercado
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getExpedienteDeMercado = async (validate, params) => {
  try {
    const response = await API.get('empresas/expedientes-de-mercados-caja-public/', { params });
    return validate ? validateResult(response.data, 21) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Expediente de licencia de funcionamiento
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getExpedienteDeLicencia = async (validate, params) => {
  try {
    const response = await API.get('empresas/expedientes-de-licencias-caja-public/', { params });
    return validate ? validateResult(response.data, 22) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Zonas exclusivas
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getZonaExclusiva = async (validate, params) => {
  try {
    const response = await API.get('empresas/zonas-exclusivas-caja-public/', { params });
    return validate ? validateResult(response.data, 23) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * GET Búsqueda en Expediente de Anuncios
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getExpedienteDeAnuncio = async (validate, params) => {
  try {
    const response = await API.get('empresas/expedientes-de-anuncios-caja-public/', { params });
    return validate ? validateResult(response.data, 24) : response.data;
  } catch (error) {
    _logger(error);
  }
  return validate ? null : [];
};

/**
 * POST Padrón Ciudadano
 * @param {object} values
 * @returns {Promise<object | null>}
 */
export const postCiudadano = async (values) => {
  try {
    const response = await API.post('cuentaunicasir/ciudadano-public', { ...values, entidad: [ID_ENTIDAD] });
    message.info('Ciudadano registrado correctamente', 3);
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return null;
};

/**
 * Patch Padrón Ciudadano
 * @param {number} key
 * @param {object} _values
 * @param {function} dispatch
 * @returns {Promise<object | null>}
 */
export const patchCiudadanoPublic = async (key, _values, dispatch) => {
  try {
    const config = hasFiles(_values, files) ? {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    } : {};
    const values = {
      ...formatSent(_values, {
        files,
        formData: config.headers,
      }),
    };
    const response = await API.patch(`cuentaunicasir/ciudadano-public/${key}/`, values);
    message.info('Ciudadano actualizado correctamente', 3);
    if (typeof dispatch === 'function') {
      dispatch(updateUserInfo(response.data));
    }

    return formatReceived(response.data);
  } catch (error) {
    _logger(error);
  }
  return null;
};

/**
 * POST Padrón Contribuyente
 * @param {object} values
 * @returns {Promise<object | null>}
 */
export const postContribuyente = async (values) => {
  try {
    const response = await API.post('empresas/contribuyente-public', { ...values, entidad: ID_ENTIDAD });
    message.info('Contribuyente registrado correctamente', 3);
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return null;
};

/**
 * PATCH Padrón Contribuyente
 * @param {number} key
 * @param {object} values
 * @param {function} dispatch
 * @returns {Promise<object | null>}
 */
export const patchContribuyentePublic = async (key, values, dispatch) => {
  try {
    const response = await API.patch(`empresas/contribuyente-public/${key}/`, values);
    message.info('Contribuyente actualizado correctamente', 3);
    if (typeof dispatch === 'function') {
      dispatch(updateUserInfo(response.data));
    }
    return formatReceived(response.data);
  } catch (error) {
    _logger(error);
  }
  return null;
};

export const getRegimenesFiscales = async () => {
  try {
    const response = await API.get('/empresas/regimen-fiscal/');
    return response.data.map((e) => ({ value: e.id, label: e.descripcion }));
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getTipoDePadronPorContenType = (contentTypeId) => {
  const contentType = selectContentTypesPadrones(store
    .getState()).find((p) => p.id === contentTypeId);
  return selectPadrones(store.getState()).find((p) => p.id === contentType?.nivel)?.id;
};

export const getContenTypePorTipoDePadron = (tipoDePadronId) => selectContentTypesPadrones(store
  .getState()).find((p) => p.nivel === tipoDePadronId)?.id;

const gettersMap = {
  1: (...args) => getCiudadano(true, ...args),
  2: (...args) => getEmpresa(true, ...args),
  3: (...args) => getPredio(true, ...args),
  4: (...args) => getVehiculo(true, ...args),
  6: (...args) => getHospedaje(true, ...args),
  7: (...args) => getArrendamiento(true, ...args),
  8: (...args) => getNomina(true, ...args),
  9: (...args) => getAlcohol(true, ...args),
  10: (...args) => getCedular(true, ...args),
  11: (...args) => getJuegoDeAzar(true, ...args),
  12: (...args) => getNotario(true, ...args),
  13: (...args) => getCasaDeEmpenio(true, ...args),
  14: (...args) => getAgencia(true, ...args),
  15: (...args) => getContribuyente(true, ...args),
  16: (...args) => getMotocicleta(true, ...args),
  17: (...args) => getRemolque(true, ...args),
  18: (...args) => getPoliciaEspecial(true, ...args),
  19: (...args) => getInfracciones(true, ...args),
  20: (...args) => getExpedienteDeComercio(true, ...args),
  21: (...args) => getExpedienteDeMercado(true, ...args),
  22: (...args) => getExpedienteDeLicencia(true, ...args),
  23: (...args) => getZonaExclusiva(true, ...args),
  24: (...args) => getExpedienteDeAnuncio(true, ...args),
  26: (...args) => getVentaAlcohol(true, ...args), // VENTA DE ALCOHOL
  27: (...args) => getCedularRetencion(true, ...args), // RETENEDOR CEDULAR
  28: (...args) => getNominaRetencion(true, ...args), // RETENEDOR NOMINA
};

const injectTipoDePadron = async (func, args, tipo_de_padron) => {
  const response = await func(...args);
  if (response.results) {
    return {
      ...response,
      results: response.results.map((e) => ({ ...e, tipo_de_padron })),
    };
  }
  return (response || []).map((e) => ({ ...e, tipo_de_padron }));
};

export const gettersListMap = {
  1: (...args) => injectTipoDePadron(getCiudadano, [false, ...args], 1),
  2: (...args) => injectTipoDePadron(getEmpresa, [false, ...args], 2),
  3: (...args) => injectTipoDePadron(getPredio, [false, ...args], 3),
  4: (...args) => injectTipoDePadron(getVehiculo, [false, ...args], 4),
  6: (...args) => injectTipoDePadron(getHospedaje, [false, ...args], 6),
  7: (...args) => injectTipoDePadron(getArrendamiento, [false, ...args], 7),
  8: (...args) => injectTipoDePadron(getNomina, [false, ...args], 8),
  9: (...args) => injectTipoDePadron(getAlcohol, [false, ...args], 9),
  10: (...args) => injectTipoDePadron(getCedular, [false, ...args], 10),
  11: (...args) => injectTipoDePadron(getJuegoDeAzar, [false, ...args], 11),
  12: (...args) => injectTipoDePadron(getNotario, [false, ...args], 12),
  13: (...args) => injectTipoDePadron(getCasaDeEmpenio, [false, ...args], 13),
  14: (...args) => injectTipoDePadron(getAgencia, [false, ...args], 14),
  15: (...args) => injectTipoDePadron(getContribuyente, [false, ...args], 15),
  16: (...args) => injectTipoDePadron(getMotocicleta, [false, ...args], 16),
  17: (...args) => injectTipoDePadron(getRemolque, [false, ...args], 17),
  18: (...args) => injectTipoDePadron(getPoliciaEspecial, [false, ...args], 18),
  19: (...args) => injectTipoDePadron(getInfracciones, [false, ...args], 19),
  20: (...args) => injectTipoDePadron(getExpedienteDeComercio, [false, ...args], 20),
  21: (...args) => injectTipoDePadron(getExpedienteDeMercado, [false, ...args], 21),
  22: (...args) => injectTipoDePadron(getExpedienteDeLicencia, [false, ...args], 22),
  23: (...args) => injectTipoDePadron(getZonaExclusiva, [false, ...args], 23),
  24: (...args) => injectTipoDePadron(getExpedienteDeAnuncio, [false, ...args], 24),
  26: (...args) => injectTipoDePadron(getVentaAlcohol, [false, ...args], 26), // VENTA DE ALCOHOL
  27: (...args) => injectTipoDePadron(getCedularRetencion, [false, ...args], 27), // RETENE CEDULAR
  28: (...args) => injectTipoDePadron(getNominaRetencion, [false, ...args], 28), // RETENEDOR NOMINA
};

/**
 * PATCH Contribuyente
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object | null>}
 */
export const patchContribuyente = async (id, values) => {
  try {
    const response = await API.patch(`empresas/contribuyente-public/${id}/`, values);
    message.info('Contribuyente actualizado correctamente', 3);
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return null;
};

/**
 * PATCH Contribuyente
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object | null>}
 */
export const patchExpedienteDeLicencia = async (id, values) => {
  try {
    const response = await API.patch(`empresas/contribuyente-public/${id}/`, values);
    message.info('Expediente de licencia actualizado correctamente', 3);
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return null;
};

export const patchPadronesPublicMap = {
  15: patchContribuyente,
  22: patchExpedienteDeLicencia,
};

/**
 * POST
 * @param {object} values
 * @returns {Promise<object | null>}
 */
export const generatePost = (uri) => async (values) => {
  try {
    const response = await API.post(uri, formatSent(values));
    message.info('Actualizado correctamente', 3);
    return formatReceived(response.data);
  } catch (error) {
    _logger(error);
  }
  return null;
};

/**
 * PATCH
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object | null>}
 */
export const generatePatch = (uri) => async (id, values) => {
  try {
    const response = await API.patch(`${uri}/${id}/`, formatSent(values));
    message.info('Actualizado correctamente', 3);
    return formatReceived(response.data);
  } catch (error) {
    _logger(error);
  }
  return null;
};

/**
 * RETRIEVE
 * @param {number} id
 * @returns {Promise<object | null>}
 */
export const generateRetrieve = (uri) => async (id) => {
  try {
    const response = await API.get(`${uri}/${id}/`);
    return formatReceived(response.data);
  } catch (error) {
    _logger(error);
  }
  return null;
};

export const postPadronesPrivateMap = {
  [padronesEnum.VENTA_DE_ALCOHOL]: generatePost('empresas/ventas-de-alcoholes'),
  [padronesEnum.RETENEDOR_DE_CEDULAR]: generatePost('empresas/retenedores-cedulares'),
  [padronesEnum.RETENEDOR_DE_NOMINA]: generatePost('empresas/retenedor-de-nominas'),
  [padronesEnum.AGENCIA]: generatePost('empresas/agencias'),
  [padronesEnum.CASA_DE_EMPENIO]: generatePost('empresas/casas-de-empenio'),
  [padronesEnum.NOTARIO]: generatePost('empresas/notarios'),
  [padronesEnum.JUEGO_DE_AZAR]: generatePost('empresas/juegos-de-azar'),
  [padronesEnum.ALCOHOL]: generatePost('empresas/alcoholes'),
  [padronesEnum.CEDULAR]: generatePost('empresas/cedulares'),
  [padronesEnum.ARRENDAMIENTO]: generatePost('empresas/arrendamientos'),
  [padronesEnum.HOSPEDAJE]: generatePost('empresas/hospedajes'),
  [padronesEnum.AGENCIA]: generatePost('empresas/agencias'),
  [padronesEnum.NOMINA]: generatePost('empresas/nominas'),
  [padronesEnum.CONTRIBUYENTE]: generatePost('empresas/contribuyentes'),
};

export const patchPadronesPrivateMap = {
  [padronesEnum.VENTA_DE_ALCOHOL]: generatePatch('empresas/ventas-de-alcoholes'),
  [padronesEnum.RETENEDOR_DE_CEDULAR]: generatePatch('empresas/retenedores-cedulares'),
  [padronesEnum.RETENEDOR_DE_NOMINA]: generatePatch('empresas/retenedor-de-nominas'),
  [padronesEnum.AGENCIA]: generatePatch('empresas/agencias'),
  [padronesEnum.CASA_DE_EMPENIO]: generatePatch('empresas/casas-de-empenio'),
  [padronesEnum.NOTARIO]: generatePatch('empresas/notarios'),
  [padronesEnum.JUEGO_DE_AZAR]: generatePatch('empresas/juegos-de-azar'),
  [padronesEnum.ALCOHOL]: generatePatch('empresas/alcoholes'),
  [padronesEnum.CEDULAR]: generatePatch('empresas/cedulares'),
  [padronesEnum.ARRENDAMIENTO]: generatePatch('empresas/arrendamientos'),
  [padronesEnum.HOSPEDAJE]: generatePatch('empresas/hospedajes'),
  [padronesEnum.AGENCIA]: generatePatch('empresas/agencias'),
  [padronesEnum.NOMINA]: generatePatch('empresas/nominas'),
  [padronesEnum.CONTRIBUYENTE]: generatePatch('empresas/contribuyentes'),
};

export const retrievePadronesPrivateMap = {
  [padronesEnum.VENTA_DE_ALCOHOL]: generateRetrieve('empresas/ventas-de-alcoholes'),
  [padronesEnum.RETENEDOR_DE_CEDULAR]: generateRetrieve('empresas/retenedores-cedulares'),
  [padronesEnum.RETENEDOR_DE_NOMINA]: generateRetrieve('empresas/retenedor-de-nominas'),
  [padronesEnum.AGENCIA]: generateRetrieve('empresas/agencias'),
  [padronesEnum.CASA_DE_EMPENIO]: generateRetrieve('empresas/casas-de-empenio'),
  [padronesEnum.NOTARIO]: generateRetrieve('empresas/notarios'),
  [padronesEnum.JUEGO_DE_AZAR]: generateRetrieve('empresas/juegos-de-azar'),
  [padronesEnum.ALCOHOL]: generateRetrieve('empresas/alcoholes'),
  [padronesEnum.CEDULAR]: generateRetrieve('empresas/cedulares'),
  [padronesEnum.ARRENDAMIENTO]: generateRetrieve('empresas/arrendamientos'),
  [padronesEnum.HOSPEDAJE]: generateRetrieve('empresas/hospedajes'),
  [padronesEnum.AGENCIA]: generateRetrieve('empresas/agencias'),
  [padronesEnum.NOMINA]: generateRetrieve('empresas/nominas'),
  [padronesEnum.CONTRIBUYENTE]: generateRetrieve('empresas/contribuyentes'),
};

export const postPadronesMap = {
  [padronesEnum.CONTRIBUYENTE]: postContribuyente,
  // 4: postVehiculo,
};

export default gettersMap;
